import { Field, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import Select from "react-select";
import { CountriesHelper, toAbsoluteUrl } from "../../helpers/AtHelper";
import SVG from "react-inlinesvg";
import "./email.css";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
const adapter = new LocalStorage("db");
const db = low(adapter);

const SignupSchema = Yup.object().shape({
  CardExpiryMonth: Yup.string()
    .min(2, "Card Expiry Month is not valid!")
    .max(2, "Card Expiry Month is not valid!")
    .required("Card Expiry Month is required"),
  CardExpiryYear: Yup.string()
    .min(4, "Card Expiry Month is not valid!")
    .max(4, "Card Expiry Month is not valid!")
    .required("Card Expiry Year is required"),
  CardNumber: Yup.string()
    .min(16, "The credit card number is not valid!")
    .required("Card Number is required"),
  CardCvc: Yup.string().min(3, "CVV is not valid!").required("CVV is required"),
  //EmailPassword: Yup.string().required("Email Password is required"),
  ConfirmPassword: Yup.string().when("EmailPassword", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("EmailPassword")],
      "Both password need to be the same"
    ),
  }),
});
function AddEmails() {
  const [ShowFormAddEmail, setShowFormAddEmail] = useState(0);
  const history = useHistory();

  const [EmailName, setEmailName] = useState([""]);
  const [LoadingPostEmail, setLoadingPostEmail] = useState(false);

  function PostEmail(params) {
    if (params.CardExpiryMonth >= 13) {
      Swal.fire("Ooops?", "invalid Card Expiry Month", "error");
      return;
    }
    if (params.CardExpiryYear < new Date().getFullYear()) {
      Swal.fire("Ooops?", "invalid Card Expiry Year", "error");
      return;
    }
    setLoadingPostEmail(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);
    myHeaders.append("Content-Type", "application/json");
    var raw = "";

    raw = JSON.stringify({
      EmailAddresses: EmailName,
      DailyInteractions: params.DailyInteractions,
      ReplyRate: params.ReplyRate,
      EmailPassword: params.EmailPassword,
      ConfirmPassword: params.ConfirmPassword,
      Service:
        params.Service === "GoogleOAuth"
          ? "G-Suite"
          : params.Service === "MicrosoftOAuth"
          ? "Outlook"
          : params.Service,
      Address1: params.Address1,
      Address2: params.Address2,
      Zipcode: params.Zipcode,
      City: params.City,
      State: params.State,
      Country: params.Country,
      CardName: params.CardName,
      CardNumber: params.CardNumber,
      CardExpiryMonth: params.CardExpiryMonth,
      CardExpiryYear: params.CardExpiryYear,
      CardCvc: params.CardCvc,
      Coupon: params.Coupon,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    ///Api/POST-api-Emails-PostOAuth
    fetch(`${window.$apiurl}/api/Emails`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          Swal.fire("Great?", "Email has been created successfuly", "success");
          setLoadingPostEmail(false);
          history.push("/my-emails");
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function (k) {
                modelState[k].forEach((element) => {
                  Swal.fire("Ooops?", element, "error");
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Swal.fire("Ooops?", result[1].Message, "error");
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            Swal.fire("Ooops?", result[1].error_description, "error");
          } else {
            Swal.fire("Ooops?", "Bad Request", "error");
          }
        }
        setLoadingPostEmail(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingPostEmail(false);
      });
  }

  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      {/*begin::Modal*/}
      <div
        className="modal fade"
        id="subheader7Modal"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Location</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i aria-hidden="true" className="ki ki-close" />
              </button>
            </div>
            <div className="modal-body">
              <div
                id="kt_subheader_leaflet"
                style={{ height: "450px", width: "100%" }}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                id="submit"
                type="button"
                className="btn btn-primary font-weight-bold"
                data-dismiss="modal"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column-fluid pt-10">
        {/*begin::Container*/}
        <div className="container">
          <div className="card card-custom">
            <div className="card-body p-0">
              {/*begin::Wizard*/}
              <div
                className="wizard wizard-1"
                id="kt_wizard"
                data-wizard-state="first"
                data-wizard-clickable="false"
              >
                {/*begin::Wizard Nav*/}
                <div className="wizard-nav border-bottom d-none">
                  <div className="wizard-steps p-8 p-lg-10">
                    {/*begin::Wizard Step 1 Nav*/}
                    <div
                      className="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="current"
                    >
                      <div className="wizard-label">
                        <i className="wizard-icon flaticon-bus-stop" />
                        <h3 className="wizard-title">1. Setup Location</h3>
                      </div>
                      <span className="svg-icon svg-icon-xl wizard-arrow">
                        {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                              x={11}
                              y={5}
                              width={2}
                              height={14}
                              rx={1}
                            />
                            <path
                              d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                            ></path>
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                    </div>
                    {/*end::Wizard Step 1 Nav*/}
                    {/*begin::Wizard Step 2 Nav*/}
                    <div
                      className="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="pending"
                    >
                      <div className="wizard-label">
                        <i className="wizard-icon flaticon-list" />
                        <h3 className="wizard-title">2. Enter Details</h3>
                      </div>
                      <span className="svg-icon svg-icon-xl wizard-arrow">
                        {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                              x={11}
                              y={5}
                              width={2}
                              height={14}
                              rx={1}
                            />
                            <path
                              d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                            ></path>
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                    </div>
                    {/*end::Wizard Step 2 Nav*/}
                    {/*begin::Wizard Step 3 Nav*/}
                    <div
                      className="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="pending"
                    >
                      <div className="wizard-label">
                        <i className="wizard-icon flaticon-responsive" />
                        <h3 className="wizard-title">3. Select Services</h3>
                      </div>
                      <span className="svg-icon svg-icon-xl wizard-arrow">
                        {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                              x={11}
                              y={5}
                              width={2}
                              height={14}
                              rx={1}
                            />
                            <path
                              d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                            ></path>
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                    </div>
                    {/*end::Wizard Step 3 Nav*/}
                    {/*begin::Wizard Step 4 Nav*/}
                    <div
                      className="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="pending"
                    >
                      <div className="wizard-label">
                        <i className="wizard-icon flaticon-truck" />
                        <h3 className="wizard-title">4. Delivery Address</h3>
                      </div>
                      <span className="svg-icon svg-icon-xl wizard-arrow">
                        {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                              x={11}
                              y={5}
                              width={2}
                              height={14}
                              rx={1}
                            />
                            <path
                              d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                            ></path>
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                    </div>
                    {/*end::Wizard Step 4 Nav*/}
                    {/*begin::Wizard Step 5 Nav*/}
                    <div
                      className="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="pending"
                    >
                      <div className="wizard-label">
                        <i className="wizard-icon flaticon-globe" />
                        <h3 className="wizard-title">5. Review and Submit</h3>
                      </div>
                      <span className="svg-icon svg-icon-xl wizard-arrow last">
                        {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                              x={11}
                              y={5}
                              width={2}
                              height={14}
                              rx={1}
                            />
                            <path
                              d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                            ></path>
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                    </div>
                    {/*end::Wizard Step 5 Nav*/}
                  </div>
                </div>
                {/*end::Wizard Nav*/}
                {/*begin::Wizard Body*/}
                <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                  <div className="col-xl-12 col-xxl-8">
                    {/*begin::Wizard Form*/}
                    <Formik
                      validationSchema={SignupSchema}
                      initialValues={{
                        EmailAddresses: [""],
                        DailyInteractions: null,
                        ReplyRate: null,
                        EmailPassword: "",
                        ConfirmPassword: "",
                        Service: "",
                        Address1: "",
                        Address2: "",
                        Zipcode: "",
                        City: "",
                        State: "",
                        Country: "US",
                        CardName: "",
                        CardNumber: "",
                        CardExpiryMonth: "",
                        CardExpiryYear: "",
                        CardCvc: "",
                        Coupon: "",
                        Price: "",
                      }}
                      onSubmit={(values) => {
                        PostEmail(values);
                      }}
                    >
                      {({
                        values,
                        status,
                        errors,
                        touched,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => {
                        return (
                          <form
                            className="form fv-plugins-bootstrap fv-plugins-framework"
                            id="kt_form"
                            autocomplete="off"
                          >
                            {/*begin::Wizard Step 1*/}
                            <h2
                              className="mb-10 font-weight-bold text-dark"
                              onClick={() => {
                                setShowFormAddEmail(0);
                              }}
                            >
                              <Link
                                to="/my-emails"
                                className="btn btn-icon btn-light btn-sm"
                              >
                                <span className="svg-icon svg-icon-success">
                                  <span className="svg-icon svg-icon-md">
                                    {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                    <i className="fas fa-angle-left font-size-h6 " />
                                    {/*end::Svg Icon*/}
                                  </span>
                                </span>
                              </Link>
                              Add Email Address
                            </h2>
                            {errors.ConfirmPassword &&
                            touched.ConfirmPassword ? (
                              <div className="fv-plugins-message-container">
                                <div
                                  data-field="width"
                                  data-validator="digits"
                                  class="fv-help-block"
                                >
                                  {errors.ConfirmPassword}
                                </div>
                              </div>
                            ) : null}
                            {errors.CardNumber && touched.CardNumber ? (
                              <div className="fv-plugins-message-container">
                                <div
                                  data-field="width"
                                  data-validator="digits"
                                  class="fv-help-block"
                                >
                                  {errors.CardNumber}
                                </div>
                              </div>
                            ) : null}
                            {errors.CardExpiryMonth &&
                            touched.CardExpiryMonth ? (
                              <div className="fv-plugins-message-container">
                                <div
                                  data-field="width"
                                  data-validator="digits"
                                  class="fv-help-block"
                                >
                                  {errors.CardExpiryMonth}
                                </div>
                              </div>
                            ) : null}
                            {errors.CardExpiryYear && touched.CardExpiryYear ? (
                              <div className="fv-plugins-message-container">
                                <div
                                  data-field="width"
                                  data-validator="digits"
                                  class="fv-help-block"
                                >
                                  {errors.CardExpiryYear}
                                </div>
                              </div>
                            ) : null}
                            {errors.CardCvc && touched.CardCvc ? (
                              <div className="fv-plugins-message-container">
                                <div
                                  data-field="width"
                                  data-validator="digits"
                                  class="fv-help-block"
                                >
                                  {errors.CardCvc}
                                </div>
                              </div>
                            ) : null}
                            {ShowFormAddEmail === 0 && (
                              <div
                                className="pb-5"
                                data-wizard-type="step-content"
                                data-wizard-state="current"
                              >
                                {/*begin::Input*/}
                                <div className="Hide-steps">
                                  <div className="row pb-7 px-4">
                                    <h4>
                                      <i className="fas fa-lock mr-4" /> Select
                                      how your would like SpamDoctor to access
                                      your email account
                                    </h4>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div
                                        className="border rounded px-10 py-5 text-center"
                                        id="turned_on"
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-link"
                                          id="showemail"
                                          onClick={() => {
                                            setShowFormAddEmail(1);
                                          }}
                                        >
                                          <img
                                            src="/assets/media/svg/service/mail.svg"
                                            className="align-self-end"
                                            width={61}
                                            alt=""
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div
                                        className="border rounded px-10 py-10 text-center"
                                        id="turned_off"
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-link"
                                          id="showgmail"
                                          onClick={() => {
                                            setFieldValue(
                                              "Service",
                                              "GoogleOAuth"
                                            );
                                            setShowFormAddEmail(1);
                                          }}
                                        >
                                          <img
                                            src="/assets/media/svg/service/Gsuite_logo.svg"
                                            className="align-self-end"
                                            width={120}
                                            alt=""
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div
                                        className="border rounded px-10 py-10 text-center"
                                        id="turned_off"
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-link"
                                          id="showoutlook"
                                          onClick={() => {
                                            setFieldValue(
                                              "Service",
                                              "MicrosoftOAuth"
                                            );
                                            setShowFormAddEmail(1);
                                          }}
                                        >
                                          <img
                                            src="/assets/media/svg/service/outloock-11.svg"
                                            className="align-self-end"
                                            width={150}
                                            alt=""
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {ShowFormAddEmail === 1 && (
                              <div className="Email-Acount">
                                <div className="pb-5">
                                  <h5 className="mb-10 font-weight-bold text-dark">
                                    Email's Account to warm up:
                                  </h5>
                                  {/*begin::Input*/}
                                  <div className="row">
                                    <div className="customer_records_dynamic col-xl-10">
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>
                                          Email Address{" "}
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip
                                                id="customers-delete-tooltip"
                                                className="mytooltip"
                                              >
                                                If you are adding multiple email
                                                accounts please use the same
                                                password for all of them.
                                              </Tooltip>
                                            }
                                          >
                                            <span
                                              className={`svg-icon svg-icon-md svg-icon-info`}
                                            >
                                              <SVG
                                                src={toAbsoluteUrl(
                                                  `/assets/media/svg/service/info-circle.svg`
                                                )}
                                              />
                                            </span>
                                          </OverlayTrigger>
                                        </label>
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              <i className="flaticon-email icon-lg" />
                                            </span>
                                          </div>
                                          <input
                                            type="email"
                                            className="form-control form-control-solid form-control-lg"
                                            placeholder="Email"
                                            // disabled={
                                            //   AccessTockenObject !== null
                                            // }
                                            value={EmailName[0]}
                                            onChange={(e) => {
                                              let values = [...EmailName];
                                              values[0] =
                                                e.target?.value === undefined
                                                  ? ""
                                                  : e.target?.value;
                                              setEmailName(values);
                                            }}
                                          />
                                        </div>
                                        <span className="form-text text-muted">
                                          Please enter your Email to warm up.
                                        </span>
                                        <div className="fv-plugins-message-container" />
                                      </div>
                                    </div>
                                    {/* {AccessTockenObject === null && ( */}
                                    <div className="col-xl-2 px-0 mt-5 pt-5">
                                      <h6
                                        className="font-weight-bolder text-primary pl-5 mt-0 pt-0 extra-fields-customer"
                                        onClick={() => {
                                          setEmailName([...EmailName, ""]);
                                        }}
                                      >
                                        Add Email
                                      </h6>
                                    </div>
                                    {/* )} */}
                                  </div>

                                  {EmailName.map((a, i) => {
                                    if (i === 0) {
                                      return "";
                                    }
                                    return (
                                      <>
                                        <div className="row">
                                          <div className="customer_records_dynamic col-xl-11">
                                            <div className="form-group fv-plugins-icon-container">
                                              <label>Email Address</label>
                                              <div className="input-group">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text">
                                                    <i className="flaticon-email icon-lg" />
                                                  </span>
                                                </div>
                                                <input
                                                  type="email"
                                                  className="form-control form-control-solid form-control-lg"
                                                  placeholder="Email"
                                                  value={a}
                                                  onChange={(e) => {
                                                    let values = [...EmailName];
                                                    values[i] =
                                                      e.target?.value ===
                                                      undefined
                                                        ? ""
                                                        : e.target?.value;
                                                    setEmailName(values);
                                                  }}
                                                />
                                              </div>
                                              <span className="form-text text-muted">
                                                Please enter your Email to warm
                                                up.
                                              </span>
                                              <div className="fv-plugins-message-container" />
                                            </div>
                                          </div>
                                          <div className="customer_records_dynamic col-xl-1 mt-5 pt-5">
                                            <a
                                              href={() => false}
                                              class="remove-field btn-remove-customer align-self-center"
                                              onClick={() => {
                                                const list = [...EmailName];
                                                list.splice(i, 1);
                                                setEmailName(list);
                                              }}
                                            >
                                              <i class="flaticon-close ml-2"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                  {values.Service !== "MicrosoftOAuth" &&
                                  values.Service !== "GoogleOAuth" ? (
                                    <div className="form-group fv-plugins-icon-container">
                                      <label>Email Type</label>
                                      <Select
                                        isSearchable={true}
                                        isClearable
                                        placeholder={"Select Email Type"}
                                        options={[
                                          { value: "Other", label: "Other" },
                                          {
                                            value: "G-Suite",
                                            label: "G-Suite",
                                          },
                                          {
                                            value: "Outlook",
                                            label: "Outlook",
                                          },
                                        ]}
                                        value={[
                                          { value: "Other", label: "Other" },
                                          {
                                            value: "G-Suite",
                                            label: "G-Suite",
                                          },
                                          {
                                            value: "Outlook",
                                            label: "Outlook",
                                          },
                                        ].filter(
                                          (a) => a.value === values.Service
                                        )}
                                        onChange={(e) => {
                                          setFieldValue("Service", e?.value);
                                        }}
                                      />
                                      <span className="form-text text-muted">
                                        Please enter your Email type.
                                      </span>
                                      <div className="fv-plugins-message-container" />
                                    </div>
                                  ) : (
                                    <div className="form-group fv-plugins-icon-container">
                                      <label>Email Type</label>
                                      <Select
                                        isSearchable={true}
                                        isClearable
                                        isDisabled={true}
                                        placeholder={"Select Country"}
                                        options={[
                                          {
                                            value: "G-Suite",
                                            label: "G-Suite",
                                          },
                                          {
                                            value: "Outlook",
                                            label: "Outlook",
                                          },
                                        ]}
                                        value={[
                                          {
                                            value: "G-Suite",
                                            label: "G-Suite",
                                          },
                                          {
                                            value: "Outlook",
                                            label: "Outlook",
                                          },
                                        ].filter((a) => {
                                          var tt = values.Service;
                                          if (
                                            values.Service === "GoogleOAuth"
                                          ) {
                                            tt = "G-Suite";
                                          } else if (
                                            values.Service === "MicrosoftOAuth"
                                          ) {
                                            tt = "Outlook";
                                          }
                                          return a.value === tt;
                                        })}
                                        onChange={(e) => {
                                          setFieldValue("Service", e?.value);
                                        }}
                                      />
                                      <span className="form-text text-muted">
                                        Please enter your Email type.
                                      </span>
                                      <div className="fv-plugins-message-container" />
                                    </div>
                                  )}

                                  {/*end::Input*/}
                                  {/*begin::Input*/}
                                  {/*end::Input*/}
                                  {/*begin::Input*/}
                                  <div className="form-group fv-plugins-icon-container">
                                    <label>Daily Interactions</label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid form-control-lg"
                                      name="DailyInteractions"
                                      placeholder="Interactions"
                                      value={values.DailyInteractions}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "DailyInteractions",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <span className="form-text text-muted">
                                      Please enter Daily Interactions.
                                    </span>
                                    <div className="fv-plugins-message-container" />
                                  </div>
                                  {/*end::Input*/}
                                  {/*begin::Input*/}
                                  <div className="form-group fv-plugins-icon-container">
                                    <label>Reply Rate</label>
                                    <input
                                      autocomplete="off"
                                      type="text"
                                      className="form-control form-control-solid form-control-lg"
                                      name="ReplyRate"
                                      placeholder="Rate"
                                      value={values.ReplyRate}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "ReplyRate",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <span className="form-text text-muted">
                                      Please enter Reply Rate (30% recommended).
                                    </span>
                                    <div className="fv-plugins-message-container" />
                                  </div>
                                  {/* {AccessTockenObject === null && ( */}
                                  <>
                                    <div className="form-group fv-plugins-icon-container">
                                      <label>
                                        Password
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip
                                              id="customers-delete-tooltip"
                                              className="mytooltip"
                                            >
                                              Your password is secure and
                                              encrypted in our database.
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            className={`svg-icon svg-icon-md svg-icon-info`}
                                          >
                                            <SVG
                                              src={toAbsoluteUrl(
                                                `/assets/media/svg/service/info-circle.svg`
                                              )}
                                            />
                                          </span>
                                        </OverlayTrigger>
                                      </label>
                                      <Field
                                        type="password"
                                        autoComplete="new-password"
                                        className="form-control form-control-solid form-control-lg"
                                        name="EmailPassword"
                                        placeholder="Password"
                                        value={values.EmailPassword}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "EmailPassword",
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <span className="form-text text-muted">
                                        Please enter email Password.
                                      </span>
                                      <div className="fv-plugins-message-container" />
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                      <label>Verify password</label>
                                      <Field
                                        type="password"
                                        className="form-control form-control-solid form-control-lg"
                                        name="ConfirmPassword"
                                        placeholder="Password"
                                        value={values.ConfirmPassword}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "ConfirmPassword",
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <span className="form-text text-muted">
                                        Please confirm email password.
                                      </span>
                                      {errors.ConfirmPassword &&
                                      touched.ConfirmPassword ? (
                                        <div className="fv-plugins-message-container">
                                          <div
                                            data-field="width"
                                            data-validator="digits"
                                            class="fv-help-block"
                                          >
                                            {errors.ConfirmPassword}
                                          </div>
                                        </div>
                                      ) : null}
                                      <div className="fv-plugins-message-container" />
                                    </div>
                                  </>
                                  {/* )} */}
                                </div>
                                <div className="d-flex justify-content-end border-top mt-5 pt-10 ">
                                  <div>
                                    <button
                                      type="button"
                                      onClick={() => setShowFormAddEmail(2)}
                                      id="hideAccount"
                                      className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {ShowFormAddEmail === 2 && (
                              <div className="billing-step">
                                <div className="pb-5">
                                  <h3 className="mb-10 font-weight-bold text-dark">
                                    Setup Your Billing Address
                                  </h3>
                                  {/*begin::Input*/}
                                  <div className="form-group fv-plugins-icon-container">
                                    <label>Address Line 1</label>
                                    <Field
                                      type="text"
                                      className="form-control form-control-solid form-control-lg"
                                      name="Address1"
                                      placeholder="Address Line 1"
                                      value={values.Address1}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "Address1",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <span className="form-text text-muted">
                                      Please enter your Address.
                                    </span>
                                    <div className="fv-plugins-message-container"></div>
                                  </div>
                                  {/*end::Input*/}
                                  {/*begin::Input*/}
                                  <div className="form-group">
                                    <label>Address Line 2</label>
                                    <Field
                                      type="text"
                                      className="form-control form-control-solid form-control-lg"
                                      name="address2"
                                      placeholder="Address Line 2"
                                      value={values.Address2}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "Address2",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <span className="form-text text-muted">
                                      Please enter your Address.
                                    </span>
                                  </div>
                                  {/*end::Input*/}
                                  <div className="row">
                                    <div className="col-xl-6">
                                      {/*begin::Input*/}
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>Zip Code</label>
                                        <Field
                                          type="text"
                                          className="form-control form-control-solid form-control-lg"
                                          name="postcode"
                                          placeholder="Zipcode"
                                          value={values.Zipcode}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "Zipcode",
                                              e.target.value
                                            );
                                          }}
                                        />
                                        <span className="form-text text-muted">
                                          Please enter your Zipcode.
                                        </span>
                                        <div className="fv-plugins-message-container"></div>
                                      </div>
                                      {/*end::Input*/}
                                    </div>
                                    <div className="col-xl-6">
                                      {/*begin::Input*/}
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>City</label>
                                        <Field
                                          type="text"
                                          className="form-control form-control-solid form-control-lg"
                                          name="City"
                                          placeholder="City"
                                          value={values.City}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "City",
                                              e.target.value
                                            );
                                          }}
                                        />
                                        <span className="form-text text-muted">
                                          Please enter your City.
                                        </span>
                                        <div className="fv-plugins-message-container"></div>
                                      </div>
                                      {/*end::Input*/}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-6">
                                      {/*begin::Input*/}
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>State</label>
                                        <Field
                                          type="text"
                                          className="form-control form-control-solid form-control-lg"
                                          name="State"
                                          placeholder="State"
                                          value={values.State}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "State",
                                              e.target.value
                                            );
                                          }}
                                        />
                                        <span className="form-text text-muted">
                                          Please enter your State.
                                        </span>
                                        <div className="fv-plugins-message-container"></div>
                                      </div>
                                      {/*end::Input*/}
                                    </div>
                                    <div className="col-xl-6">
                                      {/*begin::Select*/}
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>Country</label>
                                        <Select
                                          isSearchable={true}
                                          isClearable
                                          placeholder={"Select Country"}
                                          options={CountriesHelper}
                                          value={CountriesHelper.filter(
                                            (a) => a.value === values.Country
                                          )}
                                          onChange={(e) => {
                                            setFieldValue("Country", e?.value);
                                          }}
                                        />
                                        <div className="fv-plugins-message-container"></div>
                                      </div>
                                      {/*end::Select*/}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                  <div className="mr-2">
                                    <button
                                      id="showBilling"
                                      type="button"
                                      onClick={() => {
                                        setShowFormAddEmail(1);
                                      }}
                                      className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                                    >
                                      Previous
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      id="showCard"
                                      onClick={() => {
                                        setShowFormAddEmail(3);
                                        var myHeaders = new Headers();
                                        myHeaders.append(
                                          "Authorization",
                                          `Bearer ${
                                            db.read().getState()?.Token
                                          }`
                                        );

                                        var requestOptions = {
                                          method: "GET",
                                          headers: myHeaders,
                                          redirect: "follow",
                                        };

                                        fetch(
                                          `${window.$apiurl}/api/Emails/ValidateCoupon?coupon=&count=${EmailName.length}`,
                                          requestOptions
                                        )
                                          .then(async (response) => {
                                            const statusCode =
                                              await response.status;
                                            const contentType =
                                              response.headers.get(
                                                "content-type"
                                              );
                                            if (
                                              contentType &&
                                              contentType.indexOf(
                                                "application/json"
                                              ) !== -1
                                            ) {
                                              return response
                                                .json()
                                                .then((data) => [
                                                  statusCode,
                                                  data,
                                                ]);
                                            } else {
                                              return response
                                                .text()
                                                .then((text) => [
                                                  statusCode,
                                                  { Message: "" },
                                                ]);
                                            }
                                          })
                                          .then(async (result) => {
                                            if (result[0] === 200) {
                                              var data = JSON.parse(result[1]);
                                              setFieldValue("Price", data);
                                              //InTransit
                                            } else if (result[0] === 401) {
                                              history.push("/sign-in");
                                            }
                                          })
                                          .catch((error) => {
                                            console.log("error", error);
                                          });
                                      }}
                                      className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {ShowFormAddEmail === 3 && (
                              <div className="card-step">
                                <div className="pb-5">
                                  <h4 className="mb-10 font-weight-bold text-dark">
                                    Enter your Payment Details
                                  </h4>
                                  <div className="row">
                                    <div className="col-xl-6">
                                      {/*begin::Input*/}
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>Name on Card</label>
                                        <Field
                                          type="text"
                                          className="form-control form-control-solid form-control-lg"
                                          name="CardName"
                                          placeholder="Card Name"
                                          value={values.CardName}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "CardName",
                                              e.target.value
                                            );
                                          }}
                                        />
                                        <span className="form-text text-muted">
                                          Please enter your Card Name.
                                        </span>
                                        <div className="fv-plugins-message-container"></div>
                                      </div>
                                      {/*end::Input*/}
                                    </div>
                                    <div className="col-xl-6">
                                      {/*begin::Input*/}
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>Card Number</label>
                                        <Field
                                          type="text"
                                          className="form-control form-control-solid form-control-lg"
                                          name="CardNumber"
                                          placeholder="Card Number"
                                          value={values.CardNumber}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "CardNumber",
                                              e.target.value
                                            );
                                          }}
                                        />
                                        <span className="form-text text-muted">
                                          Please enter your Address.
                                        </span>
                                        {errors.CardNumber &&
                                        touched.CardNumber ? (
                                          <div className="fv-plugins-message-container">
                                            <div
                                              data-field="width"
                                              data-validator="digits"
                                              class="fv-help-block"
                                            >
                                              {errors.CardNumber}
                                            </div>
                                          </div>
                                        ) : null}
                                        <div className="fv-plugins-message-container"></div>
                                      </div>
                                      {/*end::Input*/}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-4">
                                      {/*begin::Input*/}
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>Card Expiry Month</label>
                                        <input
                                          type="number"
                                          className="form-control form-control-solid form-control-lg"
                                          name="CardExpiryMonth"
                                          placeholder="Card Expiry Month"
                                          value={values.CardExpiryMonth}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "CardExpiryMonth",
                                              e.target.value
                                            );
                                          }}
                                        />
                                        <span className="form-text text-muted">
                                          Please enter your Card Expiry Month.
                                        </span>
                                        {errors.CardExpiryMonth &&
                                        touched.CardExpiryMonth ? (
                                          <div className="fv-plugins-message-container">
                                            <div
                                              data-field="width"
                                              data-validator="digits"
                                              class="fv-help-block"
                                            >
                                              {errors.CardExpiryMonth}
                                            </div>
                                          </div>
                                        ) : null}
                                        <div className="fv-plugins-message-container"></div>
                                      </div>
                                      {/*end::Input*/}
                                    </div>
                                    <div className="col-xl-4">
                                      {/*begin::Input*/}
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>Card Expiry Year</label>
                                        <input
                                          type="number"
                                          className="form-control form-control-solid form-control-lg"
                                          name="CardExpiryYear"
                                          placeholder="Card Expire Year"
                                          value={values.CardExpiryYear}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "CardExpiryYear",
                                              e.target.value
                                            );
                                          }}
                                        />
                                        <span className="form-text text-muted">
                                          Please enter your Card Expiry Year.
                                        </span>
                                        {errors.CardExpiryYear &&
                                        touched.CardExpiryYear ? (
                                          <div className="fv-plugins-message-container">
                                            <div
                                              data-field="width"
                                              data-validator="digits"
                                              class="fv-help-block"
                                            >
                                              {errors.CardExpiryYear}
                                            </div>
                                          </div>
                                        ) : null}
                                        <div className="fv-plugins-message-container"></div>
                                      </div>
                                      {/*end::Input*/}
                                    </div>
                                    <div className="col-xl-4">
                                      {/*begin::Input*/}
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>Card CVV Number</label>
                                        <input
                                          type="password"
                                          className="form-control form-control-solid form-control-lg"
                                          name="CardCvc"
                                          placeholder="Card CVV Number"
                                          value={values.CardCvc}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "CardCvc",
                                              e.target.value
                                            );
                                          }}
                                        />
                                        <span className="form-text text-muted">
                                          Please enter your Card CVV Number.
                                        </span>
                                        {errors.CardCvc && touched.CardCvc ? (
                                          <div className="fv-plugins-message-container">
                                            <div
                                              data-field="width"
                                              data-validator="digits"
                                              class="fv-help-block"
                                            >
                                              {errors.CardCvc}
                                            </div>
                                          </div>
                                        ) : null}
                                        <div className="fv-plugins-message-container"></div>
                                      </div>
                                      {/*end::Input*/}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-4">
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>Price</label>
                                        <div className="input-group">
                                          <input
                                            type="text"
                                            disabled={true}
                                            value={values.Price}
                                            className="form-control form-control-solid form-control-lg"
                                          />
                                          <span className="input-group-text border-0 rounded-right">
                                            $/Month
                                          </span>
                                        </div>
                                        <div className="fv-plugins-message-container"></div>
                                      </div>
                                    </div>
                                    <div className="col-xl-8">
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>Coupon</label>
                                        <div className="input-group">
                                          <input
                                            type="text"
                                            className="form-control form-control-solid form-control-lg"
                                            value={values.Coupon}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "Coupon",
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <button
                                            className="btn btn-success rounded-right"
                                            type="button"
                                            disabled={LoadingPostEmail}
                                            onClick={() => {
                                              if (
                                                values.Coupon === "" ||
                                                values.Coupon === null ||
                                                values.Coupon === undefined
                                              ) {
                                                Swal.fire(
                                                  "Ooops?",
                                                  "invalid coupon",
                                                  "error"
                                                );
                                                return;
                                              }
                                              setLoadingPostEmail(true);
                                              var myHeaders = new Headers();
                                              myHeaders.append(
                                                "Authorization",
                                                `Bearer ${
                                                  db.read().getState()?.Token
                                                }`
                                              );

                                              var requestOptions = {
                                                method: "GET",
                                                headers: myHeaders,
                                                redirect: "follow",
                                              };

                                              fetch(
                                                `${window.$apiurl}/api/Emails/ValidateCoupon?coupon=${values.Coupon}&count=${EmailName.length}`,
                                                requestOptions
                                              )
                                                .then(async (response) => {
                                                  const statusCode =
                                                    await response.status;
                                                  const contentType =
                                                    response.headers.get(
                                                      "content-type"
                                                    );
                                                  if (
                                                    contentType &&
                                                    contentType.indexOf(
                                                      "application/json"
                                                    ) !== -1
                                                  ) {
                                                    return response
                                                      .json()
                                                      .then((data) => [
                                                        statusCode,
                                                        data,
                                                      ]);
                                                  } else {
                                                    return response
                                                      .text()
                                                      .then((text) => [
                                                        statusCode,
                                                        { Message: "" },
                                                      ]);
                                                  }
                                                })
                                                .then(async (result) => {
                                                  if (result[0] === 200) {
                                                    var data = JSON.parse(
                                                      result[1]
                                                    );
                                                    setFieldValue(
                                                      "Price",
                                                      data
                                                    );
                                                    Swal.fire(
                                                      "Great?",
                                                      "the price have been updated",
                                                      "success"
                                                    );

                                                    //InTransit
                                                  } else if (
                                                    result[0] === 401
                                                  ) {
                                                    history.push("/sign-in");
                                                  }
                                                  setLoadingPostEmail(false);
                                                })
                                                .catch((error) => {
                                                  console.log("error", error);
                                                });
                                            }}
                                          >
                                            Apply Coupon
                                            {LoadingPostEmail && (
                                              <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                              />
                                            )}
                                          </button>
                                        </div>
                                        <div className="fv-plugins-message-container"></div>
                                      </div>
                                      {/*begin::Input*/}
                                      {/*end::Input*/}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                  <div className="mr-2">
                                    <button
                                      id="hideCard"
                                      type="button"
                                      onClick={() => setShowFormAddEmail(2)}
                                      className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                                    >
                                      Previous
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      id="showsubmit"
                                      onClick={() => setShowFormAddEmail(4)}
                                      className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {ShowFormAddEmail === 4 && (
                              <div className="submit-step">
                                <div className="pb-5">
                                  {/*begin::Section*/}
                                  <h6 className="font-weight-bolder mb-3">
                                    Email Account Details:
                                  </h6>
                                  <div className="row">
                                    <div className="col-xl-6">
                                      <div className="text-dark-50 line-height-lg">
                                        Emails:
                                      </div>
                                    </div>
                                    <div className="col-xl-6 text-right">
                                      <div className="text-dark-70 line-height-lg">
                                        {EmailName.join(", ")}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-6">
                                      <div className="text-dark-50 line-height-lg">
                                        Email Registrar/Provider:
                                      </div>
                                    </div>
                                    <div className="col-xl-6 text-right">
                                      <div className="text-dark-70 line-height-lg">
                                        {values.Service}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-6">
                                      <div className="text-dark-50 line-height-lg">
                                        Daily Interactions:
                                      </div>
                                    </div>
                                    <div className="col-xl-6 text-right">
                                      <div className="text-dark-70 line-height-lg">
                                        {values.DailyInteractions}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-6">
                                      <div className="text-dark-50 line-height-lg">
                                        Reply Rate:
                                      </div>
                                    </div>
                                    <div className="col-xl-6 text-right">
                                      <div className="text-dark-70 line-height-lg">
                                        {values.ReplyRate}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="separator separator-dashed my-5"></div>
                                  {/*end::Section*/}
                                  {/*begin::Section*/}
                                  <h6 className="font-weight-bolder mb-3">
                                    Billing Info:
                                  </h6>
                                  <div className="row">
                                    <div className="col-xl-6">
                                      <div className="text-dark-50 line-height-lg">
                                        Address Line 1:
                                      </div>
                                    </div>
                                    <div className="col-xl-6 text-right">
                                      <div className="text-dark-70 line-height-lg">
                                        {values.Address1}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-6">
                                      <div className="text-dark-50 line-height-lg">
                                        Address Line 2:
                                      </div>
                                    </div>
                                    <div className="col-xl-6 text-right">
                                      <div className="text-dark-70 line-height-lg">
                                        {values.Address2}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-6">
                                      <div className="text-dark-50 line-height-lg">
                                        City:
                                      </div>
                                    </div>
                                    <div className="col-xl-6 text-right">
                                      <div className="text-dark-70 line-height-lg">
                                        {values.City}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-6">
                                      <div className="text-dark-50 line-height-lg">
                                        State:
                                      </div>
                                    </div>
                                    <div className="col-xl-6 text-right">
                                      <div className="text-dark-70 line-height-lg">
                                        {values.State}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-6">
                                      <div className="text-dark-50 line-height-lg">
                                        Postale Code:
                                      </div>
                                    </div>
                                    <div className="col-xl-6 text-right">
                                      <div className="text-dark-70 line-height-lg">
                                        {values.Zipcode}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-6">
                                      <div className="text-dark-50 line-height-lg">
                                        Country:
                                      </div>
                                    </div>
                                    <div className="col-xl-6 text-right">
                                      <div className="text-dark-70 line-height-lg">
                                        {values.Country}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                  <div className="mr-2">
                                    <button
                                      id="hidesubmit"
                                      type="button"
                                      onClick={() => setShowFormAddEmail(3)}
                                      className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                                    >
                                      Previous
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      disabled={LoadingPostEmail}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                      }}
                                      className="btn btn-primary font-weight-bolder text-uppercase font-weight-bolder px-9 py-4"
                                    >
                                      Add Email
                                      {LoadingPostEmail && (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                {/*end::Wizard Step 1*/}
                {/*begin::Wizard Step 2*/}
              </div>
              {/*end::Wizard Form*/}
            </div>
          </div>
          {/*end::Wizard Body*/}
        </div>
        {/*end::Wizard*/}
      </div>
      {/*end::Wizard*/}
    </div>
  );
}

export default AddEmails;
