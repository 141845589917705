import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
function UserActivationEmail() {
  const [accountverified, setaccountverified] = useState(false);
  function sendCodeFunction(params) {
    if (params !== null && params !== "" && params !== undefined) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        Code: params,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${window.$apiurl}/api/Account/ConfirmEmail`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setaccountverified(true);
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    }
  }
  var url = new URL(window.location.href);
  var code = url.searchParams.get("code");
  useEffect(() => {
    sendCodeFunction(code);
  }, [code]);

  return (
    <div className="d-flex flex-column flex-root">
      {/*begin::Login*/}
      <div
        className="login login-4 login-signin-on d-flex flex-row-fluid"
        id="kt_login"
      >
        <div
          className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
          style={{ backgroundImage: 'url("/assets/media/bg/bg-3.jpg")' }}
        >
          <div className="login-form text-center p-7 position-relative overflow-hidden">
            {/*begin::Login Header*/}
            <div className="d-flex flex-center mb-8">
              <Link
                to="/"
                className="login-logo pb-lg-4 pb-10 font-size-h1 font-weight-bold "
                style={{
                  color: "#8950fc",
                  display: "grid",
                  alignSelf: "center",
                  justifyItems: "center",
                }}
              >
                <img alt="logo" src="/assets/media/logos/logo.svg" />
                SpamDoctor
              </Link>
            </div>
            {/*end::Login Header*/}
            {/*begin::Login Sign in form*/}
            <div className="login-signin">
              {accountverified === true ? (
                <div className="mb-20">
                  <h3>Welcome to SpamDoctor</h3>
                  <div className="text-muted font-weight-bold">
                  The #1 email deliverability platform.
Never land in spam again!
                  </div>
                </div>
              ) : (
                <div className="mb-20">
                  <h3>Verify Your Email</h3>
                  <div className="text-muted font-weight-bold">
                    We have sent an email from support@SpamDoctor.io pelase
                    follow a link to verify your email.
                  </div>
                </div>
              )}

              <div className="mt-10">
                <span className="opacity-70 mr-4">
                  {accountverified === true
                    ? "Go to homePage?"
                    : "Skip for now?"}
                </span>
                <Link
                  to="/sign-in"
                  className={`text-muted text-hover-primary font-weight-bold`}
                >
                  Signin!
                </Link>
              </div>
            </div>
            {/*end::Login Sign in form*/}
            {/*begin::Login forgot password form*/}
            <div className="login-forgot">
              <div className="mb-20">
                <h3>Forgotten your Password?</h3>
                <div className="text-muted font-weight-bold">
                  Enter your email to reset your password
                </div>
              </div>
              <form
                className="form fv-plugins-bootstrap fv-plugins-framework"
                id="kt_login_forgot_form"
              >
                <div className="form-group mb-10 fv-plugins-icon-container">
                  <input
                    className="form-control form-control-solid h-auto py-4 px-8"
                    type="text"
                    placeholder="Email"
                    name="email"
                    autoComplete="off"
                  />
                  <div className="fv-plugins-message-container" />
                </div>
                <div className="form-group d-flex flex-wrap flex-center mt-10">
                  <button
                    id="kt_login_forgot_submit"
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2"
                  >
                    Request
                  </button>
                  <button
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-2"
                  >
                    Cancel
                  </button>
                </div>
                <div />
              </form>
            </div>
            {/*end::Login forgot password form*/}
          </div>
        </div>
      </div>
      {/*end::Login*/}
    </div>
  );
}
export default UserActivationEmail;
