import React from "react";
import { PaginationLinks } from "./paginationsLinks";
import { PaginationToolbar } from "./paginationsToolbar";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../helpers/AtHelper";

export function Pagination(props) {
  const { children, isLoading, paginationProps } = props;
  return (
    <>
      {children}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <PaginationLinks paginationProps={paginationProps} />
        <PaginationToolbar
          isLoading={isLoading}
          paginationProps={paginationProps}
        />
      </div>
    </>
  );
}

export const sortCaret = (order, column) => {
    if (!order) return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
        <SVG src={toAbsoluteUrl("/assets/media/svg/service/Sort1.svg")}/>
      </span>
    );
    else if (order === "asc")
      return (
        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
          <SVG src={toAbsoluteUrl("/assets/media/svg/service/Up-2.svg")}/>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
          <SVG src={toAbsoluteUrl("/assets/media/svg/service/Down-2.svg")}/>
        </span>
      );
    return null;
  };