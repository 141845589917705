import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { LanguageHelpers, TimezoneHelpers } from "../../helpers/AtHelper";
import KTImageInput from "../imageInput";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
const adapter = new LocalStorage("db");
const db = low(adapter);

var KTUserEdit = (function () {
  // Base elements

  var initUserForm = function () {
    new KTImageInput("kt_user_edit_avatar");
  };

  return {
    // public functions
    init: function () {
      initUserForm();
    },
  };
})();

function Profile() {
  const history = useHistory();
  new KTImageInput("kt_user_edit_avatar");
  (function () {
    // your page initialization code here
    // the DOM will be available here
    KTUserEdit.init();
  })();
  const [step, setstep] = useState(0);
  const [isLoadingAuth, setisLoadingAuth] = useState(false);
  const [initProduct, setinitProduct] = useState({
    FirstName: "",
    LastName: "",
    CompanyName: "",
    CompanyPhone: "",
    CompanySite: "",
    LanguageId: "en",
    TimezoneId: "Eastern Time (US & Canada)",
    CommunicationEmail: false,
    CommunicationSms: false,
    CommunicationPhone: false,
    ProfilePicture: "",
    OldPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
  });
  function ChangeAccountInfo(params) {
    setisLoadingAuth(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FirstName: params.FirstName,
      LastName: params.LastName,
      CompanyName: params.CompanyName,
      CompanyPhone: params.CompanyPhone,
      CompanySite: params.CompanySite,
      LanguageId: 1,
      TimezoneId: 1,
      CommunicationEmail: params.CommunicationEmail,
      CommunicationSms: params.CommunicationSms,
      CommunicationPhone: params.CommunicationPhone,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Account/Profile`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          const ProfilePicture = document.getElementById("profile_avatar");

          var formdata = new FormData();
          if (ProfilePicture.files.length > 0) {
            formdata.append(
              "File",
              ProfilePicture.files[0],
              ProfilePicture.value.split("\\")[2]
            );
            var requestOptions2 = {
              method: "POST",
              body: formdata,
              redirect: "follow",
              withCredentials: true,
              headers: {
                Authorization: `bearer ${db.read().getState().Token}`,
                "X-FP-API-KEY": "iphone",
              },
            };
            await fetch(
              `${window.$apiurl}/api/Account/ProfilePicture`,
              requestOptions2
            ).then(async (response) => {
              let data = response.json();
              if (data.Success) {
                console.log("data.NewProfilePicture", data.NewProfilePicture);
              }
            });
          }

          Swal.fire(
            "Great?",
            "Account info has been updated successfuly",
            "success"
          );
          getProfileData();
          setisLoadingAuth(false);
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function (k) {
                modelState[k].forEach((element) => {
                  Swal.fire("Ooops?", element, "error");
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Swal.fire("Ooops?", result[1].Message, "error");
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            Swal.fire("Ooops?", result[1].error_description, "error");
          } else {
            Swal.fire("Ooops?", "Bad Request", "error");
          }
        }
        setisLoadingAuth(false);
      })
      .catch((error) => {
        console.log("error", error);
        setisLoadingAuth(false);
      });
  }
  function getProfileData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Account/Profile`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var data = JSON.parse(result);
        db.set("FirstName", data.FirstName).write();
        db.set("LastName", data.LastName).write();
        db.set("CompanyName", data.CompanyName).write();
        db.set("ProfilePicture", data.ProfilePicture).write();
        setinitProduct(data);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    getProfileData();
  }, []);
  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      {/*begin::Subheader*/}
      <div
        className="subheader py-2 py-lg-4 subheader-transparent"
        id="kt_subheader"
      >
        <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          {/*begin::Details*/}
          <div className="d-flex align-items-center flex-wrap mr-2">
            {/*begin::Title*/}
            <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
              Edit User
            </h5>
            {/*end::Title*/}
            {/*begin::Separator*/}
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
            {/*end::Separator*/}
            {/*begin::Search Form*/}

            {/*end::Search Form*/}
          </div>
          {/*end::Details*/}
          {/*begin::Toolbar*/}
          <div className="d-flex align-items-center">
            {/*begin::Button*/}
            <a
              href="/metronic/demo4/#.html"
              className="btn btn-default font-weight-bold btn-sm px-3 font-size-base"
            >
              Back
            </a>
            {/*end::Button*/}
            {/*begin::Dropdown*/}
            <div className="ml-2">
              <button
                type="button"
                onClick={() => {
                  document.getElementById("submitformAccount").click();
                }}
                className="btn btn-primary font-weight-bold btn-sm px-3 font-size-base"
              >
                Save Changes
                {isLoadingAuth && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
              {/* <button
                type="button"
                className="btn btn-primary font-weight-bold btn-sm px-3 font-size-base dropdown-toggle dropdown-toggle-split"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              /> */}
              <div className="dropdown-menu dropdown-menu-sm p-0 m-0 dropdown-menu-right">
                <ul className="navi py-5">
                  <li className="navi-item">
                    <a href={() => false} className="navi-link">
                      <span className="navi-icon">
                        <i className="flaticon2-writing" />
                      </span>
                      <span className="navi-text">Save &amp; continue</span>
                    </a>
                  </li>
                  <li className="navi-item">
                    <a href={() => false} className="navi-link">
                      <span className="navi-icon">
                        <i className="flaticon2-medical-records" />
                      </span>
                      <span className="navi-text">Save &amp; add new</span>
                    </a>
                  </li>
                  <li className="navi-item">
                    <a href={() => false} className="navi-link">
                      <span className="navi-icon">
                        <i className="flaticon2-hourglass-1" />
                      </span>
                      <span className="navi-text">Save &amp; exit</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/*end::Dropdown*/}
          </div>
          {/*end::Toolbar*/}
        </div>
      </div>
      {/*end::Subheader*/}
      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">
          {/*begin::Card*/}
          <div className="card card-custom">
            {/*begin::Card header*/}
            <div className="card-header card-header-tabs-line nav-tabs-line-3x">
              {/*begin::Toolbar*/}
              <div className="card-toolbar">
                <ul className="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-3x">
                  {/*begin::Item*/}
                  <li className="nav-item mr-3">
                    <a
                      className={`nav-link ${step === 0 && "active"}`}
                      data-toggle="tab"
                      href={() => false}
                      onClick={() => {
                        setstep(0);
                      }}
                    >
                      <span className="nav-icon">
                        <span className="svg-icon">
                          {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Design/Layers.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <polygon points="0 0 24 0 24 24 0 24" />
                              <path
                                d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                                fill="#000000"
                                fillRule="nonzero"
                              />
                              <path
                                d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                      </span>
                      <span className="nav-text font-size-lg">Profile</span>
                    </a>
                  </li>
                  {/*end::Item*/}
                  {/*begin::Item*/}
                  <li className="nav-item mr-3">
                    <a
                      className={`nav-link ${step === 1 && "active"}`}
                      data-toggle="tab"
                      href={() => false}
                      onClick={() => {
                        setstep(1);
                      }}
                    >
                      <span className="nav-icon">
                        <span className="svg-icon">
                          {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/General/User.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <polygon points="0 0 24 0 24 24 0 24" />
                              <path
                                d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                              />
                              <path
                                d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                fill="#000000"
                                fillRule="nonzero"
                              />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                      </span>
                      <span className="nav-text font-size-lg">Account</span>
                    </a>
                  </li>
                  {/*end::Item*/}
                  {/*begin::Item*/}
                  <li className="nav-item mr-3">
                    <a
                      className={`nav-link ${step === 2 && "active"}`}
                      data-toggle="tab"
                      href={() => false}
                      onClick={() => {
                        setstep(2);
                      }}
                    >
                      <span className="nav-icon">
                        <span className="svg-icon">
                          {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Communication/Shield-user.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <path
                                d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                              <path
                                d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                              <path
                                d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                      </span>
                      <span className="nav-text font-size-lg">
                        Change Password
                      </span>
                    </a>
                  </li>
                  {/*end::Item*/}
                  {/*begin::Item*/}

                  {/*end::Item*/}
                </ul>
              </div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body">
              <Formik
                initialValues={initProduct}
                enableReinitialize={true}
                onSubmit={(values) => {
                  ChangeAccountInfo(values);
                }}
              >
                {({
                  values,
                  status,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form className="form" id="kt_form">
                    <div className="tab-content">
                      {/*begin::Tab*/}

                      <div
                        className={`tab-pane show ${
                          step === 0 && "active"
                        } px-7`}
                        id="kt_user_edit_tab_1"
                        role="tabpanel"
                      >
                        {/*begin::Row*/}
                        <div className="row">
                          <div className="col-xl-2" />
                          <div className="col-xl-7 my-2">
                            {/*begin::Row*/}
                            <div className="row">
                              <label className="col-3" />
                              <div className="col-9">
                                <h6 className="text-dark font-weight-bold mb-10">
                                  Customer Info:
                                </h6>
                              </div>
                            </div>
                            {/*end::Row*/}
                            {/*begin::Group*/}
                            <div className="form-group row">
                              <label className="col-form-label col-3 text-lg-right text-left">
                                Avatar
                              </label>
                              <div className="col-9">
                                <div
                                  className="image-input image-input-outline image-input-circle"
                                  id="kt_user_edit_avatar"
                                >
                                  <div
                                    className="image-input-wrapper"
                                    style={{
                                      backgroundImage:
                                        "url('" + values?.ProfilePicture + "')",
                                      backgroundPosition: "center center",
                                      backgroundSize: "contain",
                                    }}
                                  ></div>
                                  <label
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="change"
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Change avatar"
                                  >
                                    <i className="fa fa-pen icon-sm text-muted"></i>
                                    <input
                                      type="file"
                                      id="profile_avatar"
                                      name="profile_avatar"
                                      accept=".png, .jpg, .jpeg"
                                    />
                                    <input
                                      type="hidden"
                                      name="profile_avatar_remove"
                                    />
                                  </label>
                                  <span
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="cancel"
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Cancel avatar"
                                  >
                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                  </span>
                                  <span
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="remove"
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Remove avatar"
                                  >
                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/*end::Group*/}
                            {/*begin::Group*/}
                            <div className="form-group row">
                              <label className="col-form-label col-3 text-lg-right text-left">
                                First Name
                              </label>
                              <div className="col-9">
                                <input
                                  className="form-control form-control-lg form-control-solid"
                                  type="text"
                                  value={values.FirstName}
                                  placeholder="First Name"
                                  onChange={(e) => {
                                    setFieldValue("FirstName", e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            {/*end::Group*/}
                            {/*begin::Group*/}
                            <div className="form-group row">
                              <label className="col-form-label col-3 text-lg-right text-left">
                                Last Name
                              </label>
                              <div className="col-9">
                                <input
                                  className="form-control form-control-lg form-control-solid"
                                  type="text"
                                  value={values.LastName}
                                  placeholder="Last Name"
                                  onChange={(e) => {
                                    setFieldValue("LastName", e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            {/*end::Group*/}
                            {/*begin::Group*/}
                            <div className="form-group row">
                              <label className="col-form-label col-3 text-lg-right text-left">
                                Company Name
                              </label>
                              <div className="col-9">
                                <input
                                  className="form-control form-control-lg form-control-solid"
                                  type="text"
                                  value={values.CompanyName}
                                  placeholder="Company Name"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "CompanyName",
                                      e.target.value
                                    );
                                  }}
                                />
                                <span className="form-text text-muted">
                                </span>
                              </div>
                            </div>
                            {/*end::Group*/}
                            {/*begin::Group*/}
                            <div className="form-group row">
                              <label className="col-form-label col-3 text-lg-right text-left">
                                Contact Phone
                              </label>
                              <div className="col-9">
                                <div className="input-group input-group-lg input-group-solid">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="la la-phone" />
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    placeholder="Phone"
                                    value={values.CompanyPhone}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "CompanyPhone",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                                <span className="form-text text-muted">
                                </span>
                              </div>
                            </div>
                            {/*end::Group*/}
                            {/*begin::Group*/}
                            <div className="form-group row">
                              <label className="col-form-label col-3 text-lg-right text-left">
                                Email Address
                              </label>
                              <div className="col-9">
                                <div className="input-group input-group-lg input-group-solid">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="la la-at" />
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    placeholder="Email"
                                    disabled={true}
                                    value={db.read().getState()?.userName}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "CompanySite",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/*end::Group*/}
                            {/*begin::Group*/}
                            <div className="form-group row">
                              <label className="col-form-label col-3 text-lg-right text-left">
                                Company Site
                              </label>
                              <div className="col-9">
                                <div className="input-group input-group-lg input-group-solid">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg form-control-solid"
                                    placeholder="Company Site"
                                    value={values.CompanySite}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "CompanySite",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/*end::Group*/}
                          </div>
                        </div>
                        {/*end::Row*/}
                      </div>

                      {/*end::Tab*/}
                      {/*begin::Tab*/}
                      <div
                        className={`tab-pane px-7 ${step === 1 && "active"}`}
                        id="kt_user_edit_tab_2"
                        role="tabpanel"
                      >
                        {/*begin::Row*/}
                        <div className="row">
                          <div className="col-xl-2" />
                          <div className="col-xl-7">
                            <div className="my-2">
                              {/*begin::Row*/}
                              <div className="row">
                                <label className="col-form-label col-3 text-lg-right text-left" />
                                <div className="col-9">
                                  <h6 className="text-dark font-weight-bold mb-10">
                                    Account:
                                  </h6>
                                </div>
                              </div>
                              {/*end::Row*/}
                              {/*begin::Group*/}
                              {/*end::Group*/}
                              {/*begin::Group*/}
                              <div className="form-group row">
                                <label className="col-form-label col-3 text-lg-right text-left">
                                  Email Address
                                </label>
                                <div className="col-9">
                                  <div className="input-group input-group-lg input-group-solid">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="la la-at" />
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      disabled={true}
                                      value={db.read().getState()?.userName}
                                      placeholder="Email"
                                    />
                                  </div>
                                  <span className="form-text text-muted">
                                  </span>
                                </div>
                              </div>
                              {/*end::Group*/}
                              {/*begin::Group*/}
                              <div className="form-group row">
                                <label className="col-form-label col-3 text-lg-right text-left">
                                  Language
                                </label>
                                <div className="col-9">
                                  <Select
                                    isSearchable={true}
                                    isClearable
                                    placeholder={"Select Language"}
                                    options={LanguageHelpers}
                                    value={LanguageHelpers.filter(
                                      (a) => a.value === "en"
                                    )}
                                    onChange={(e) => {
                                      setFieldValue("LanguageId", e?.value);
                                    }}
                                  />
                                </div>
                              </div>
                              {/*end::Group*/}
                              {/*begin::Group*/}
                              <div className="form-group row">
                                <label className="col-form-label col-3 text-lg-right text-left">
                                  Time Zone
                                </label>
                                <div className="col-9">
                                  <Select
                                    isSearchable={true}
                                    isClearable
                                    placeholder={"Select Time zone"}
                                    value={TimezoneHelpers.filter(
                                      (a) =>
                                        a.value === "Eastern Time (US & Canada)"
                                    )}
                                    onChange={(e) => {
                                      setFieldValue("TimezoneId", e?.value);
                                    }}
                                    options={TimezoneHelpers}
                                  />
                                </div>
                              </div>
                              {/*end::Group*/}
                              {/*begin::Group*/}
                              <div className="form-group row align-items-center">
                                <label className="col-form-label col-3 text-lg-right text-left">
                                  Communication
                                </label>
                                <div className="col-9">
                                  <div className="checkbox-inline">
                                    <label className="checkbox">
                                      <input
                                        type="checkbox"
                                        checked={values.CommunicationEmail}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "CommunicationEmail",
                                            e.target.checked
                                          );
                                        }}
                                      />
                                      <span />
                                      Email
                                    </label>
                                    <label className="checkbox">
                                      <input
                                        type="checkbox"
                                        checked={values.CommunicationSms}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "CommunicationSms",
                                            e.target.checked
                                          );
                                        }}
                                      />
                                      <span />
                                      SMS
                                    </label>
                                    <label className="checkbox">
                                      <input
                                        type="checkbox"
                                        checked={values.CommunicationPhone}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "CommunicationPhone",
                                            e.target.checked
                                          );
                                        }}
                                      />
                                      <span />
                                      Phone
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/*end::Group*/}
                            </div>
                          </div>
                        </div>
                        {/*end::Row*/}
                    
                      </div>

                      {/*end::Tab*/}
                      {/*begin::Tab*/}

                      <div
                        className={`tab-pane px-7 ${step === 2 && "active"}`}
                        id="kt_user_edit_tab_3"
                        role="tabpanel"
                      >
                        {/*begin::Body*/}
                        <div className="card-body">
                          {/*begin::Row*/}
                          <div className="row">
                            <div className="col-xl-2" />
                            <div className="col-xl-7">
                              {/*begin::Row*/}
                              {/*end::Row*/}
                              {/*begin::Row*/}
                              <div className="row">
                                <label className="col-3" />
                                <div className="col-9">
                                  <h6 className="text-dark font-weight-bold mb-10">
                                    Change Or Recover Your Password:
                                  </h6>
                                </div>
                              </div>
                              {/*end::Row*/}
                              {/*begin::Group*/}
                              <div className="form-group row">
                                <label className="col-form-label col-3 text-lg-right text-left">
                                  Current Password
                                </label>
                                <div className="col-9">
                                  <input
                                    className="form-control form-control-lg form-control-solid mb-1"
                                    type="password"
                                    value={values.OldPassword}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "OldPassword",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <a
                                    href={() => false}
                                    className="font-weight-bold font-size-sm"
                                  >
                                    Forgot your password?
                                  </a>
                                </div>
                              </div>
                              {/*end::Group*/}
                              {/*begin::Group*/}
                              <div className="form-group row">
                                <label className="col-form-label col-3 text-lg-right text-left">
                                  New Password
                                </label>
                                <div className="col-9">
                                  <input
                                    className="form-control form-control-lg form-control-solid"
                                    type="password"
                                    value={values.NewPassword}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "NewPassword",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              {/*end::Group*/}
                              {/*begin::Group*/}
                              <div className="form-group row">
                                <label className="col-form-label col-3 text-lg-right text-left">
                                  Verify Password
                                </label>
                                <div className="col-9">
                                  <input
                                    className="form-control form-control-lg form-control-solid"
                                    type="password"
                                    value={values.ConfirmPassword}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "ConfirmPassword",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              {/*end::Group*/}
                            </div>
                          </div>
                          {/*end::Row*/}
                        </div>
                        {/*end::Body*/}
                        {/*begin::Footer*/}
                        <div className="card-footer pb-0">
                          <div className="row">
                            <div className="col-xl-2" />
                            <div className="col-xl-7">
                              <div className="row">
                                <div className="col-3" />
                                <div className="col-9">
                                  <a
                                    href={() => false}
                                    onClick={() => {
                                      if (
                                        values.OldPassword !== "" &&
                                        values.OldPassword !== null &&
                                        values.OldPassword !== undefined
                                      ) {
                                        var raw2 = JSON.stringify({
                                          OldPassword: values.OldPassword,
                                          NewPassword: values.NewPassword,
                                          ConfirmPassword:
                                            values.ConfirmPassword,
                                        });
                                        var myHeaders = new Headers();
                                        myHeaders.append(
                                          "Authorization",
                                          `Bearer ${
                                            db.read().getState()?.Token
                                          }`
                                        );
                                        myHeaders.append(
                                          "Content-Type",
                                          "application/json"
                                        );

                                        var requestOptions3 = {
                                          method: "POST",
                                          headers: myHeaders,
                                          body: raw2,
                                          redirect: "follow",
                                        };

                                        fetch(
                                          `${window.$apiurl}/api/Account/ChangePassword`,
                                          requestOptions3
                                        )
                                          .then(async (response) => {
                                            const statusCode =
                                              await response.status;
                                            const contentType =
                                              response.headers.get(
                                                "content-type"
                                              );
                                            if (
                                              contentType &&
                                              contentType.indexOf(
                                                "application/json"
                                              ) !== -1
                                            ) {
                                              return response
                                                .json()
                                                .then((data) => [
                                                  statusCode,
                                                  data,
                                                ]);
                                            } else {
                                              return response
                                                .text()
                                                .then((text) => [
                                                  statusCode,
                                                  { Message: "" },
                                                ]);
                                            }
                                          })
                                          .then(async (result) => {
                                            if (result[0] === 200) {
                                              Swal.fire(
                                                "Great?",
                                                "password has been updated successfuly",
                                                "success"
                                              );
                                            } else {
                                              if (
                                                result[1].ModelState !== null &&
                                                result[1].ModelState !==
                                                  undefined &&
                                                result[1].ModelState !== ""
                                              ) {
                                                let modelState =
                                                  result[1].ModelState;
                                                if (modelState)
                                                  Object.keys(
                                                    modelState
                                                  ).forEach(function (k) {
                                                    modelState[k].forEach(
                                                      (element) => {
                                                        Swal.fire(
                                                          "Ooops?",
                                                          element,
                                                          "error"
                                                        );
                                                      }
                                                    );
                                                  });
                                              } else if (
                                                result[1].Message !== null &&
                                                result[1].Message !==
                                                  undefined &&
                                                result[1].Message !== ""
                                              ) {
                                                Swal.fire(
                                                  "Ooops?",
                                                  result[1].Message,
                                                  "error"
                                                );
                                              } else if (
                                                result[1].error_description !==
                                                  null &&
                                                result[1].error_description !==
                                                  undefined &&
                                                result[1].error_description !==
                                                  ""
                                              ) {
                                                Swal.fire(
                                                  "Ooops?",
                                                  result[1].error_description,
                                                  "error"
                                                );
                                              } else {
                                                Swal.fire(
                                                  "Ooops?",
                                                  "Bad Request",
                                                  "error"
                                                );
                                              }
                                            }
                                          })
                                          .catch((error) => {
                                            console.log("error", error);
                                          });
                                      }
                                    }}
                                    className="btn btn-light-primary font-weight-bold"
                                  >
                                    Save changes
                                  </a>
                                  <a
                                    href={() => false}
                                    className="btn btn-clean font-weight-bold"
                                  >
                                    Cancel
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*end::Footer*/}
                      </div>

                      {/*end::Tab*/}
                      {/*begin::Tab*/}
                      <div
                        className={`tab-pane px-7 ${step === 3 && "active"}`}
                        id="kt_user_edit_tab_4"
                        role="tabpanel"
                      >
                        <div className="row">
                          <div className="col-xl-2" />
                          <div className="col-xl-8">
                            <div className="my-2">
                              <div className="row">
                                <label className="col-form-label col-3 text-lg-right text-left" />
                                <div className="col-9">
                                  <h6 className="text-dark font-weight-bold mb-7">
                                    Setup Email Notification:
                                  </h6>
                                </div>
                              </div>
                              <div className="form-group row mb-2">
                                <label className="col-form-label col-3 text-lg-right text-left">
                                  Email Notification
                                </label>
                                <div className="col-3">
                                  <span className="switch">
                                    <label>
                                      <input
                                        type="checkbox"
                                        defaultChecked="checked"
                                        name="select"
                                      />
                                      <span />
                                    </label>
                                  </span>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-form-label col-3 text-lg-right text-left">
                                  Send Copy To Personal Email
                                </label>
                                <div className="col-3">
                                  <span className="switch">
                                    <label>
                                      <input type="checkbox" name="select" />
                                      <span />
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <label className="col-form-label col-3 text-lg-right text-left" />
                              <div className="col-9">
                                <h6 className="text-dark font-weight-bold mb-7">
                                  Activity Related Emails:
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed mb-10" />
                        <div className="row">
                          <div className="col-xl-2" />
                          <div className="col-xl-8">
                            <div className="form-group row">
                              <label className="col-form-label col-3 text-lg-right text-left">
                                When To Email
                              </label>
                              <div className="col-9">
                                <div className="checkbox-inline mb-2">
                                  <label className="checkbox">
                                    <input type="checkbox" />
                                    <span />
                                    You have new notifications.
                                  </label>
                                </div>
                                <div className="checkbox-inline mb-2">
                                  <label className="checkbox">
                                    <input type="checkbox" />
                                    <span />
                                    You're sent a direct message
                                  </label>
                                </div>
                                <div className="checkbox-inline mb-2">
                                  <label className="checkbox">
                                    <input
                                      type="checkbox"
                                      defaultChecked="checked"
                                    />
                                    <span />
                                    Someone adds you as a connection
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-form-label col-3 text-lg-right text-left">
                                When To Escalate Emails
                              </label>
                              <div className="col-9">
                                <div className="checkbox-inline mb-2">
                                  <label className="checkbox checkbox-success">
                                    <input type="checkbox" />
                                    <span />
                                    Upon new order
                                  </label>
                                </div>
                                <div className="checkbox-inline mb-2">
                                  <label className="checkbox checkbox-success">
                                    <input type="checkbox" />
                                    <span />
                                    New membership approval
                                  </label>
                                </div>
                                <div className="checkbox-inline mb-2">
                                  <label className="checkbox checkbox-success">
                                    <input type="checkbox" />
                                    <span />
                                    Member registration
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*end::Tab*/}
                    </div>
                    <input
                      id="submitformAccount"
                      style={{ display: "none" }}
                      onClick={() => {
                        handleSubmit();
                      }}
                    ></input>
                  </form>
                )}
              </Formik>
            </div>
            {/*begin::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Entry*/}
    </div>
  );
}
export default Profile;
