import moment from "moment";
export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export function getDate(d) {
  if (d === null || d === "" || d === undefined) return "";
  var format = "MM-DD-YYYY, hh:mm A";
  if (moment(d).startOf("day").valueOf() === moment(d).valueOf()) {
    format = "MM-DD-YYYY";
  }
  return moment(d).format(format);
}

export function currencyFormat(no) {
  if (no === null) {
    no = 0;
  }
  var ar = (+no).toFixed(2).split(".");
  return [numberFormat(ar[0] | 0), ".", ar[1]].join("");
}

function numberFormat(no) {
  var str = no + "";
  var ar = [];
  var i = str.length - 1;

  while (i >= 0) {
    ar.push((str[i - 2] || "") + (str[i - 1] || "") + (str[i] || ""));
    i = i - 3;
  }
  return ar.reverse().join(",");
}
export const TimezoneHelpers = [
  {
    value: "International Date Line West",
    label: "(GMT-11:00) International Date Line West",
  },
  { value: "Midway Island", label: "(GMT-11:00) Midway Island" },
  { value: "Samoa", label: "(GMT-11:00) Samoa" },
  { value: "Hawaii", label: "(GMT-10:00) Hawaii" },
  { value: "Alaska", label: "(GMT-08:00) Alaska" },
  {
    value: "Pacific Time (US & Canada)",
    label: "(GMT-07:00) Pacific Time (US &amp; Canada)",
  },
  { value: "Tijuana", label: "(GMT-07:00) Tijuana" },
  { value: "Arizona", label: "(GMT-07:00) Arizona" },
  {
    value: "Mountain Time (US & Canada)",
    label: "(GMT-06:00) Mountain Time (US &amp;Canada)",
  },
  { value: "Chihuahua", label: "(GMT-06:00) Chihuahua" },
  { value: "Mazatlan", label: "(GMT-06:00) Mazatlan" },
  { value: "Saskatchewan", label: "(GMT-06:00) Saskatchewan" },
  { value: "Central America", label: "(GMT-06:00) Central America" },
  {
    value: "Central Time (US & Canada)",
    label: "(GMT-05:00) Central Time (US &amp; Canada)",
  },
  { value: "Guadalajara", label: "(GMT-05:00) Guadalajara" },
  { value: "Mexico City", label: "(GMT-05:00) Mexico City" },
  { value: "Monterrey", label: "(GMT-05:00) Monterrey" },
  { value: "Bogota", label: "(GMT-05:00) Bogota" },
  { value: "Lima", label: "(GMT-05:00) Lima" },
  { value: "Quito", label: "(GMT-05:00) Quito" },
  {
    value: "Eastern Time (US & Canada)",
    label: "(GMT-04:00) Eastern Time (US &amp; Canada)",
  },
  { value: "Yerevan", label: "(GMT+04:00) Yerevan" },
  { value: "Tehran", label: "(GMT+04:30) Tehran" },
  { value: "Kabul", label: "(GMT+04:30) Kabul" },
  { value: "Ekaterinburg", label: "(GMT+05:00) Ekaterinburg" },
  { value: "Islamabad", label: "(GMT+05:00) Islamabad" },
  { value: "Karachi", label: "(GMT+05:00) Karachi" },
  { value: "Tashkent", label: "(GMT+05:00) Tashkent" },
  { value: "Chennai", label: "(GMT+05:30) Chennai" },
  { value: "Kolkata", label: "(GMT+05:30) Kolkata" },
  { value: "Mumbai", label: "(GMT+05:30) Mumbai" },
  { value: "New Delhi", label: "(GMT+05:30) New Delhi" },
  { value: "Sri Jayawardenepura", label: "(GMT+05:30) Sri Jayawardenepura" },
  { value: "Kathmandu", label: "(GMT+05:45) Kathmandu" },
  { value: "Astana", label: "(GMT+06:00) Astana" },
  { value: "Dhaka", label: "(GMT+06:00) Dhaka" },
  { value: "Almaty", label: "(GMT+06:00) Almaty" },
  { value: "Urumqi", label: "(GMT+06:00) Urumqi" },
  { value: "Rangoon", label: "(GMT+06:30) Rangoon" },
  { value: "Novosibirsk", label: "(GMT+07:00) Novosibirsk" },
  { value: "Bangkok", label: "(GMT+07:00) Bangkok" },
  { value: "Hanoi", label: "(GMT+07:00) Hanoi" },
  { value: "Jakarta", label: "(GMT+07:00) Jakarta" },
  { value: "Krasnoyarsk", label: "(GMT+07:00) Krasnoyarsk" },
  { value: "Beijing", label: "(GMT+08:00) Beijing" },
  { value: "Chongqing", label: "(GMT+08:00) Chongqing" },
  { value: "Hong Kong", label: "(GMT+08:00) Hong Kong" },
  { value: "Kuala Lumpur", label: "(GMT+08:00) Kuala Lumpur" },
  { value: "Singapore", label: "(GMT+08:00) Singapore" },
  { value: "Taipei", label: "(GMT+08:00) Taipei" },
  { value: "Perth", label: "(GMT+08:00) Perth" },
  { value: "Irkutsk", label: "(GMT+08:00) Irkutsk" },
  { value: "Ulaan Bataar", label: "(GMT+08:00) Ulaan Bataar" },
  { value: "Seoul", label: "(GMT+09:00) Seoul" },
  { value: "Osaka", label: "(GMT+09:00) Osaka" },
  { value: "Sapporo", label: "(GMT+09:00) Sapporo" },
  { value: "Tokyo", label: "(GMT+09:00) Tokyo" },
  { value: "Yakutsk", label: "(GMT+09:00) Yakutsk" },
  { value: "Darwin", label: "(GMT+09:30) Darwin" },
  { value: "Adelaide", label: "(GMT+09:30) Adelaide" },
  { value: "Canberra", label: "(GMT+10:00) Canberra" },
  { value: "Melbourne", label: "(GMT+10:00) Melbourne" },
  { value: "Sydney", label: "(GMT+10:00) Sydney" },
  { value: "Brisbane", label: "(GMT+10:00) Brisbane" },
  { value: "Hobart", label: "(GMT+10:00) Hobart" },
  { value: "Vladivostok", label: "(GMT+10:00) Vladivostok" },
  { value: "Guam", label: "(GMT+10:00) Guam" },
  { value: "Port Moresby", label: "(GMT+10:00) Port Moresby" },
  { value: "Solomon Is.", label: "(GMT+10:00) Solomon Is." },
  { value: "Magadan", label: "(GMT+11:00) Magadan" },
  { value: "New Caledonia", label: "(GMT+11:00) New Caledonia" },
  { value: "Fiji", label: "(GMT+12:00) Fiji" },
  { value: "Kamchatka", label: "(GMT+12:00) Kamchatka" },
  { value: "Marshall Is.", label: "(GMT+12:00) Marshall Is." },
  { value: "Auckland", label: "(GMT+12:00) Auckland" },
  { value: "Wellington", label: "(GMT+12:00) Wellington" },
  { value: "Nuku'alofa", label: "(GMT+13:00) Nuku'alofa" },
];
export const LanguageHelpers = [
  { value: "id", label: "Bahasa Indonesia - Indonesian" },
  { value: "msa", label: "Bahasa Melayu - Malay" },
  { value: "ca", label: "Català - Catalan" },
  { value: "cs", label: "Čeština - Czech" },
  { value: "da", label: "Dansk - Danish" },
  { value: "de", label: "Deutsch - German" },
  { value: "en", label: "English" },
  { value: "en-gb", label: "English UK - British English" },
  { value: "es", label: "Español - Spanish" },
  { value: "eu", label: "Euskara - Basque (beta)" },
  { value: "fil", label: "Filipino" },
  { value: "fr", label: "Français - French" },
  { value: "ga", label: "Gaeilge - Irish (beta)" },
  { value: "gl", label: "Galego - Galician (beta)" },
  { value: "hr", label: "Hrvatski - Croatian" },
  { value: "it", label: "Italiano - Italian" },
  { value: "hu", label: "Magyar - Hungarian" },
  { value: "nl", label: "Nederlands - Dutch" },
  { value: "no", label: "Norsk - Norwegian" },
  { value: "pl", label: "Polski - Polish" },
  { value: "pt", label: "Português - Portuguese" },
  { value: "ro", label: "Română - Romanian" },
  { value: "sk", label: "Slovenčina - Slovak" },
  { value: "fi", label: "Suomi - Finnish" },
  { value: "sv", label: "Svenska - Swedish" },
  { value: "vi", label: "Tiếng Việt - Vietnamese" },
  { value: "tr", label: "Türkçe - Turkish" },
  { value: "el", label: "Ελληνικά - Greek" },
  { value: "bg", label: "Български език - Bulgarian" },
  { value: "ru", label: "Русский - Russian" },
  { value: "sr", label: "Српски - Serbian" },
  { value: "uk", label: "Українська мова - Ukrainian" },
  { value: "he", label: "עִבְרִית - Hebrew" },
  { value: "ur", label: "اردو - Urdu (beta)" },
  { value: "ar", label: "العربية - Arabic" },
  { value: "fa", label: "فارسی - Persian" },
  { value: "mr", label: "मराठी - Marathi" },
  { value: "hi", label: "हिन्दी - Hindi" },
  { value: "bn", label: "বাংলা - Bangla" },
  { value: "gu", label: "ગુજરાતી - Gujarati" },
  { value: "ta", label: "தமிழ் - Tamil" },
  { value: "kn", label: "ಕನ್ನಡ - Kannada" },
  { value: "th", label: "ภาษาไทย - Thai" },
  { value: "ko", label: "한국어 - Korean" },
  { value: "ja", label: "日本語 - Japanese" },
  { value: "zh-cn", label: "简体中文 - Simplified Chinese" },
  { value: "zh-tw", label: "繁體中文 - Traditional Chinese" },
];

export const CountriesHelper = [
  { value: "AF", label: "Afghanistan" },
  { value: "AX", label: "Åland Islands" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia, Plurinational State of" },
  { value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet Island" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cape Verde" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos (Keeling) Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo" },
  { value: "CD", label: "Congo, the Democratic Republic of the" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Côte d'Ivoire" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CW", label: "Curaçao" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands (Malvinas)" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard Island and McDonald Islands" },
  { value: "VA", label: "Holy See (Vatican City State)" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran, Islamic Republic of" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KP", label: "Korea, Democratic People's Republic of" },
  { value: "KR", label: "Korea, Republic of" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Lao People's Democratic Republic" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MK", label: "Macedonia, the former Yugoslav Republic of" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia, Federated States of" },
  { value: "MD", label: "Moldova, Republic of" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestinian Territory, Occupied" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "Réunion" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russian Federation" },
  { value: "RW", label: "Rwanda" },
  { value: "BL", label: "Saint Barthélemy" },
  { value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
  { value: "KN", label: "Saint Kitts and Nevis" },
  { value: "LC", label: "Saint Lucia" },
  { value: "MF", label: "Saint Martin (French part)" },
  { value: "PM", label: "Saint Pierre and Miquelon" },
  { value: "VC", label: "Saint Vincent and the Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tome and Principe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SX", label: "Sint Maarten (Dutch part)" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "GS", label: "South Georgia and the South Sandwich Islands" },
  { value: "SS", label: "South Sudan" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard and Jan Mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syrian Arab Republic" },
  { value: "TW", label: "Taiwan, Province of China" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania, United Republic of" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks and Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "UM", label: "United States Minor Outlying Islands" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela, Bolivarian Republic of" },
  { value: "VN", label: "Viet Nam" },
  { value: "VG", label: "Virgin Islands, British" },
  { value: "VI", label: "Virgin Islands, U.S." },
  { value: "WF", label: "Wallis and Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
];
