/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BootstrapTable from "react-bootstrap-table-next";
import { sortCaret } from "../Metronic/Control/paginations";
import { toAbsoluteUrl } from "../../helpers/AtHelper";
import SVG from "react-inlinesvg";

import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { IconButton } from "@material-ui/core";
import moment from "moment";
const adapter = new LocalStorage("db");
const db = low(adapter);
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function SharedReport({ id }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [InfoJsonResult, setInfoJsonResult] = useState([]);
  const [DkimListResult, setDkimListResult] = useState([]);
  const [DmarkListResult, setDmarkListResult] = useState([]);
  const [DnsListResult, setDnsListResult] = useState([]);
  const [BlackListListResult, setBlackListListResult] = useState([]);
  const [mxListResult, setmxListResult] = useState([]);
  const [srvListResult, setsrvListResult] = useState([]);
  const [spfListResult, setspfListResult] = useState([]);

  const [DomainInfo, setDomainInfo] = useState(null);
  const [InfoDmarkListResult, setInfoDmarkListResult] = useState([]);
  const [InfoDkimListResult, setInfoDkimListResult] = useState([]);
  const [InfoDnsListResult, setInfoDnsListResult] = useState([]);
  const [InfoBlackListListResult, setInfoBlackListListResult] = useState([]);
  const [InfomxListResult, setInfomxListResult] = useState([]);
  const [InfosrvListResult, setInfosrvListResult] = useState([]);
  const [InfospfListResult, setInfospfListResult] = useState([]);
  const [ShowInfoModal, setShowInfoModal] = useState(false);
  const [TypeModalInfoShow, setTypeModalInfoShow] = useState("");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const columns = [
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <OverlayTrigger
            overlay={
              <Tooltip id="customers-delete-tooltip">{row.Status}</Tooltip>
            }
          >
            <a
              href={() => false}
              target="_blank"
              rel="noreferrer"
              className={`btn btn-icon btn-light ${
                row.Status === "Failed"
                  ? "btn-hover-danger"
                  : row.Status === "Warnings"
                  ? "btn-hover-warning"
                  : row.Status === "Passed"
                  ? "btn-hover-success"
                  : "btn-hover-info"
              } btn-sm`}
            >
              <span
                className={`svg-icon svg-icon-md ${
                  row.Status === "Failed"
                    ? "svg-icon-danger"
                    : row.Status === "Warnings"
                    ? "svg-icon-warning"
                    : row.Status === "Passed"
                    ? "svg-icon-success"
                    : "svg-icon-info"
                } `}
              >
                <SVG
                  src={toAbsoluteUrl(
                    `/assets/media/svg/service/${
                      row.Status === "Failed"
                        ? "Error-circle.svg"
                        : row.Status === "Warnings"
                        ? "Warning-1-circle.svg"
                        : row.Status === "Passed"
                        ? "Done-circle.svg"
                        : "Error-circle.svg"
                    }`
                  )}
                />
              </span>
            </a>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: "Name",
      text: "Test",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Info",
      text: "Result",
      sort: true,
      sortCaret: sortCaret,
    },
  ];

  function getdataFromApi() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/domains/getreport/${id}`, requestOptions)
      .then((response) => response.text())
      .then(async (result) => {
        var data = JSON.parse(result);
        setDomainInfo(data?.Domain);
        //DMARK
        var datadmark = JSON.parse(
          data?.ReportDetails.find((a) => a.Command === "dmarc")?.Response
        );
        var dmark = [];
        await datadmark.Failed?.forEach((a) => {
          a.Status = "Failed";
          dmark.push(a);
        });
        await datadmark?.Warnings?.forEach((a) => {
          a.Status = "Warnings";
          dmark.push(a);
        });
        await datadmark?.Passed?.forEach((a) => {
          a.Status = "Passed";
          dmark.push(a);
        });
        setDmarkListResult(dmark);
        setInfoDmarkListResult(datadmark?.Information);
        //EndDmark

        //DKIM
        if (data?.ReportDetails.find((a) => a.Command === "dkim")) {
          var datadkim = JSON.parse(
            data?.ReportDetails.find((a) => a.Command === "dkim")?.Response
          );
          var dkim = [];
          await datadkim.Failed?.forEach((a) => {
            a.Status = "Failed";
            dkim.push(a);
          });
          await datadkim?.Warnings?.forEach((a) => {
            a.Status = "Warnings";
            dkim.push(a);
          });
          await datadkim?.Passed?.forEach((a) => {
            a.Status = "Passed";
            dkim.push(a);
          });
          setDkimListResult(dkim || []);
          setInfoDkimListResult(datadkim?.Information || []);
        }
    
        //EndDKIM

        //Dns
        var datadns = JSON.parse(
          data?.ReportDetails.find((a) => a.Command === "dns")?.Response
        );
        var dns = [];
        await datadns.Failed?.forEach((a) => {
          a.Status = "Failed";
          dmark.push(a);
        });
        await datadns?.Warnings?.forEach((a) => {
          a.Status = "Warnings";
          dns.push(a);
        });
        await datadns?.Passed?.forEach((a) => {
          a.Status = "Passed";
          dns.push(a);
        });
        setDnsListResult(dns);
        setInfoDnsListResult(datadns?.Information);

        //EndDmark

        //Blacklist
        var datablacklist = JSON.parse(
          data?.ReportDetails.find((a) => a.Command === "blacklist")?.Response
        );
        var blacklist = [];
        await datablacklist.Failed?.forEach((a) => {
          a.Status = "Failed";
          blacklist.push(a);
        });
        await datablacklist?.Warnings?.forEach((a) => {
          a.Status = "Warnings";
          blacklist.push(a);
        });
        await datablacklist?.Passed?.forEach((a) => {
          a.Status = "Passed";
          blacklist.push(a);
        });
        console.log("datablacklist", datablacklist);
        setBlackListListResult(blacklist);
        setInfoBlackListListResult(datablacklist?.Information);

        //EndDmark
        //mx
        var datamx = JSON.parse(
          data?.ReportDetails.find((a) => a.Command === "mx")?.Response
        );
        var mx = [];
        await datamx.Failed?.forEach((a) => {
          a.Status = "Failed";
          mx.push(a);
        });
        await datamx?.Warnings?.forEach((a) => {
          a.Status = "Warnings";
          mx.push(a);
        });
        await datamx?.Passed?.forEach((a) => {
          a.Status = "Passed";
          mx.push(a);
        });
        setmxListResult(mx);
        setInfomxListResult(datamx?.Information);

        //Endmx

        //spf
        var dataspf = JSON.parse(
          data?.ReportDetails.find((a) => a.Command === "spf")?.Response
        );
        console.log("dataspf",dataspf);
        var spf = [];
        await dataspf.Failed?.forEach((a) => {
          a.Status = "Failed";
          spf.push(a);
        });
        await dataspf?.Warnings?.forEach((a) => {
          a.Status = "Warnings";
          spf.push(a);
        });
        await dataspf?.Passed?.forEach((a) => {
          a.Status = "Passed";
          spf.push(a);
        });
        setspfListResult(spf);
        setInfospfListResult(dataspf?.Information);

        //Endspf

        //srv
        var datasrv = JSON.parse(
          data?.ReportDetails.find((a) => a.Command === "srv")?.Response
        );
        var srv = [];
        await datasrv.Failed?.forEach((a) => {
          a.Status = "Failed";
          srv.push(a);
        });
        await datasrv?.Warnings?.forEach((a) => {
          a.Status = "Warnings";
          srv.push(a);
        });
        await datasrv?.Passed?.forEach((a) => {
          a.Status = "Passed";
          srv.push(a);
        });
        setsrvListResult(srv);
        setInfosrvListResult(datasrv?.Information);

        //Endsrv
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    getdataFromApi();
  }, []);

  return (
    <>
      <Modal
        size="xl"
        show={ShowInfoModal}
        onHide={() => {
          setShowInfoModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <div className="row w-100">
            <div className="col-lg-6">
              <h3 className="font-weight-bolder text-dark">
                {TypeModalInfoShow} Information
              </h3>
            </div>
          </div>
          <IconButton
            aria-label="Close"
            onClick={() => {
              setShowInfoModal(false);
            }}
          >
            <i class="flaticon-close"></i>
          </IconButton>
        </Modal.Header>
        <Modal.Body className="overlay-block">
          <div className={`h-60 card card-custom`}>
            {/* Body */}
            <div className="card-body pt-5 row now-rapTable">
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                id="SPFsList"
                keyField="Name"
                noDataIndication={
                  <h4 style={{ textAlign: "center" }}>
                    {"There is no data available at the moment"}.
                  </h4>
                }
                data={InfoJsonResult || []}
                pagination={paginationFactory({
                  showTotal: true,
                  sizePerPage: 5,
                  sizePerPageList: [
                    { text: "5", value: 5 },
                    { text: "10", value: 10 },
                  ],
                })}
                columns={
                  InfoJsonResult.length > 0
                    ? InfoJsonResult.map((a, index) => {
                        return {
                          dataField: Object.keys(a)[index],
                          text: Object.keys(a)[index],
                        };
                      })
                    : [{ dataField: "Name", text: "Name" }]
                }
              ></BootstrapTable>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        {/*begin::Modal*/}
        <div
          className="modal fade"
          id="subheader7Modal"
          data-backdrop="static"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="staticBackdrop"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Select Location</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" className="ki ki-close" />
                </button>
              </div>
              <div className="modal-body">
                <div
                  id="kt_subheader_leaflet"
                  style={{ height: "450px", width: "100%" }}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  id="submit"
                  type="button"
                  className="btn btn-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column-fluid pt-10">
          {/*begin::Container*/}
          <div className="container">
            {/*begin::Dashboard*/}
            {/*begin::Row*/}
            <div className="row mb-5">
              <div className="col-md-12 mb-5">
                <div className="font-weight-bolder text-muted">
                  <h3>
                    {DomainInfo?.Name} | {DomainInfo?.Provider}
                  </h3>
                </div>
                <span className="text-muted font-sizw-sm">
                  <b>Created:</b>{" "}
                  {DomainInfo !== null
                    ? moment(DomainInfo?.Created).format("MMM Do YYYY, hh:mm A")
                    : ""}
                </span>
              </div>
              <div className="col-md-12 mt-5">
                <div className={classes.root}>
                  <ExpansionPanel
                    expanded={expanded === "panel5"}
                    style={{ borderRadius: "4px", marginBottom: "15px" }}
                    onChange={handleChange("panel5")}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel5bh-header"
                      className={`text-white ${
                        spfListResult.filter((a) => a.Status === "Failed")
                          .length > 0
                          ? "bg-danger-o-70"
                          : spfListResult.filter((a) => a.Status === "Warnings")
                              .length > 0
                          ? "bg-warning-o-70"
                          : spfListResult.filter((a) => a.Status === "Passed")
                              .length > 0
                          ? "bg-success-o-70"
                          : "bg-success-o-70"
                      }`}
                    >
                      <div className="row col-md-12">
                        <div
                          className="col-md-6 mt-3"
                          style={{ textAlign: "initial" }}
                        >
                          <h5>SPF Lists</h5>
                        </div>
                        <div
                          className="col-md-6"
                          style={{ textAlign: "right" }}
                        ></div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className="row col-md-12" style={{ width: "100%" }}>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          bordered={false}
                          classes="table table-head-custom table-vertical-center"
                          bootstrap4
                          id="SPFList"
                          keyField="Name"
                          noDataIndication={
                            <h4 style={{ textAlign: "center" }}>
                              {"There is no data available at the moment"}.
                            </h4>
                          }
                          data={spfListResult || []}
                          pagination={paginationFactory({
                            showTotal: true,
                            sizePerPage: 5,
                            sizePerPageList: [
                              { text: "5", value: 5 },
                              { text: "10", value: 10 },
                            ],
                          })}
                          columns={columns}
                        ></BootstrapTable>
                        <div className="row col-md-12 ">
                          <p className="mt-5 pt-5">
                            The SPF Record Check is a diagnostic tool that acts
                            as a Sender Policy Framework (SPF) record lookup and
                            SPF validator. This test will lookup an SPF record
                            for the queried domain name, display the SPF Record
                            (if found), and run a series of diagnostic tests
                            (SPF Validation) against the record, highlighting
                            any errors found with the record that could impact
                            email delivery.
                            <br></br>
                            Sender Policy Framework (SPF) records allow domain
                            owners to publish a list of IP addresses or subnets
                            that are authorized to send email on their behalf.
                            The goal is to reduce the amount of spam and fraud
                            by making it much harder for malicious senders to
                            disguise their identity.
                            <Button
                              variant="link"
                              onClick={() => {
                                setTypeModalInfoShow("SPF");
                                setInfoJsonResult(InfospfListResult);
                                setShowInfoModal(true);
                              }}
                            >
                              See Informations
                            </Button>
                          </p>
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    expanded={expanded === "panel12"}
                    style={{ borderRadius: "4px", marginBottom: "15px" }}
                    onChange={handleChange("panel12")}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel12bh-header"
                      className={`text-white ${
                        DkimListResult.filter((a) => a.Status === "Failed")
                          .length > 0
                          ? "bg-danger-o-70"
                          : DkimListResult.filter(
                              (a) => a.Status === "Warnings"
                            ).length > 0
                          ? "bg-warning-o-70"
                          : DkimListResult.filter((a) => a.Status === "Passed")
                              .length > 0
                          ? "bg-success-o-70"
                          : "bg-success-o-70"
                      }`}
                    >
                      <div className="row col-md-12">
                        <div
                          className="col-md-6 mt-3"
                          style={{ textAlign: "initial" }}
                        >
                          <h5>DKIM Lists</h5>
                        </div>
                        <div
                          className="col-md-6"
                          style={{ textAlign: "right" }}
                        ></div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className="row col-md-12" style={{ width: "100%" }}>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          bordered={false}
                          classes="table table-head-custom table-vertical-center"
                          bootstrap4
                          id="DKIMList"
                          keyField="Name"
                          noDataIndication={
                            <h4 style={{ textAlign: "center" }}>
                              {"There is no data available at the moment"}.
                            </h4>
                          }
                          data={DkimListResult || []}
                          pagination={paginationFactory({
                            showTotal: true,
                            sizePerPage: 5,
                            sizePerPageList: [
                              { text: "5", value: 5 },
                              { text: "10", value: 10 },
                            ],
                          })}
                          columns={columns}
                        ></BootstrapTable>
                        <div className="row col-md-12 mt-5">
                          <div className="tools_description mt-5">
                            <h3>ABOUT DKIM RECORD CHECK</h3>
                            <p>
                              The DKIM Check tool will perform a DKIM record
                              test against a domain name and selector for a
                              valid published DKIM key record. DomainKeys
                              Identified Mail (DKIM) defines a domain-level
                              digital signature authentication framework for
                              email by permitting a signing domain to assert
                              responsibility for a message in transit. DKIM
                              authenticates the reputation and identity of the
                              email sender and their email signing practices for
                              additional handling (i.e. does email get
                              delivered, quarantined, or rejected). DKIM
                              authentication of a message is validated via a
                              cryptographic signature and querying the signer’s
                              domain to retrieve a public key. This tool tests
                              the ability to retrieve the DKIM public key using
                              a domain and a selector.
                            </p>
                          
                            <p />
                            <p>
                              What is a <strong>DKIM Selector</strong>? A DKIM
                              selector is text that is added with the domain to
                              create a unique DNS record used during DKIM. This
                              allows multiple keys to exist under one domain
                              which allows for different signatures to be
                              created by different systems, date ranges, or
                              third party services.{" "}
                              <Button
                                variant="link"
                                onClick={() => {
                                  setTypeModalInfoShow("DKIM");
                                  setInfoJsonResult(InfoDkimListResult);
                                  setShowInfoModal(true);
                                }}
                              >
                                See Informations
                              </Button>
                            </p>
                          </div>
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    expanded={expanded === "panel1"}
                    style={{ borderRadius: "4px", marginBottom: "15px" }}
                    onChange={handleChange("panel1")}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      className={`text-white ${
                        DmarkListResult.filter((a) => a.Status === "Failed")
                          .length > 0
                          ? "bg-danger-o-70"
                          : DmarkListResult.filter(
                              (a) => a.Status === "Warnings"
                            ).length > 0
                          ? "bg-warning-o-70"
                          : DmarkListResult.filter((a) => a.Status === "Passed")
                              .length > 0
                          ? "bg-success-o-70"
                          : "bg-success-o-70"
                      }`}
                    >
                      <div className="row col-md-12">
                        <div
                          className="col-md-6 mt-3"
                          style={{ textAlign: "initial" }}
                        >
                          <h5>DMARC</h5>
                        </div>
                        <div
                          className="col-md-6"
                          style={{ textAlign: "right" }}
                        ></div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className="row col-md-12">
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          bordered={false}
                          classes="table table-head-custom table-vertical-center"
                          bootstrap4
                          id="dmarcPassed"
                          keyField="Name"
                          noDataIndication={
                            <h4 style={{ textAlign: "center" }}>
                              {"There is no data available at the moment"}.
                            </h4>
                          }
                          data={DmarkListResult || []}
                          pagination={paginationFactory({
                            showTotal: true,
                            sizePerPage: 5,
                            sizePerPageList: [
                              { text: "5", value: 5 },
                              { text: "10", value: 10 },
                            ],
                          })}
                          columns={columns}
                        ></BootstrapTable>
                        <div className="row col-md-12 ">
                          <p className="mt-5 pt-5">
                            The DMARC Record Lookup / DMARC Check is a
                            diagnostic tool that will parse the DMARC Record for
                            the queried domain name, display the DMARC Record,
                            and run a series of diagnostic checks against the
                            record. Domain-based Message Authentication,
                            Reporting, and Conformance (DMARC) is a mechanism
                            for policy distribution by which an organization
                            that is the originator of an email can communicate
                            domain-level policies and preferences for message
                            validation, disposition, and reporting.
                            <br></br>
                            DMARC Records standardize how mail originators
                            associate and authenticate domain identifiers with
                            messages, handle message policies using those
                            identifiers, and report about mail using those
                            identifiers. According to RFC 7489, the DMARC
                            mechanism for policy distribution enables the strict
                            handling of email messages that fail authentication
                            checks, such as SPF and/or DKIM. If neither of those
                            authentication methods passes, DMARC tells the
                            receiver how to handle the message, such as junk it
                            (quarantine) or reject the message entirely.
                            <Button
                              variant="link"
                              onClick={() => {
                                setTypeModalInfoShow("Dmark");
                                setInfoJsonResult(InfoDmarkListResult);
                                setShowInfoModal(true);
                              }}
                            >
                              See Informations
                            </Button>
                          </p>
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    expanded={expanded === "panel3"}
                    style={{ borderRadius: "4px", marginBottom: "15px" }}
                    onChange={handleChange("panel3")}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel3bh-header"
                      className={`text-white ${
                        BlackListListResult.filter((a) => a.Status === "Failed")
                          .length > 0
                          ? "bg-danger-o-70"
                          : BlackListListResult.filter(
                              (a) => a.Status === "Warnings"
                            ).length > 0
                          ? "bg-warning-o-70"
                          : BlackListListResult.filter(
                              (a) => a.Status === "Passed"
                            ).length > 0
                          ? "bg-success-o-70"
                          : "bg-success-o-70"
                      }`}
                    >
                      <div className="row col-md-12">
                        <div
                          className="col-md-6 mt-3"
                          style={{ textAlign: "initial" }}
                        >
                          <h5>Black Lists</h5>
                        </div>
                        <div
                          className="col-md-6"
                          style={{ textAlign: "right" }}
                        ></div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className="row col-md-12" style={{ width: "100%" }}>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          bordered={false}
                          classes="table table-head-custom table-vertical-center"
                          bootstrap4
                          id="blacklist"
                          keyField="Name"
                          noDataIndication={
                            <h4 style={{ textAlign: "center" }}>
                              {"There is no data available at the moment"}.
                            </h4>
                          }
                          data={BlackListListResult || []}
                          pagination={paginationFactory({
                            showTotal: true,
                            sizePerPage: 5,
                            sizePerPageList: [
                              { text: "5", value: 5 },
                              { text: "10", value: 10 },
                            ],
                          })}
                          columns={columns}
                        ></BootstrapTable>
                        <div className="row col-md-12 ">
                          <p className="mt-5 pt-5">
                            The blacklist check will test a mail server IP
                            address against{" "}
                            <span id="ctl00_ContentPlaceHolder1_lblNumberofBlackLists">
                              over 100
                            </span>{" "}
                            DNS based email blacklists.&nbsp;(Commonly called
                            Realtime blacklist, DNSBL or&nbsp;RBL).&nbsp; If
                            your mail server has been blacklisted, some email
                            you send may not be delivered.&nbsp; Email
                            blacklists are a common way of reducing spam.&nbsp;
                            If you don't know your mail server's address
                            <Button
                              variant="link"
                              onClick={() => {
                                setTypeModalInfoShow("Black List");
                                setInfoJsonResult(InfoBlackListListResult);
                                setShowInfoModal(true);
                              }}
                            >
                              See Informations
                            </Button>
                          </p>
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    expanded={expanded === "panel2"}
                    style={{ borderRadius: "4px", marginBottom: "15px" }}
                    onChange={handleChange("panel2")}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel2bh-header"
                      className={`text-white ${
                        DnsListResult.filter((a) => a.Status === "Failed")
                          .length > 0
                          ? "bg-danger-o-70"
                          : DnsListResult.filter((a) => a.Status === "Warnings")
                              .length > 0
                          ? "bg-warning-o-70"
                          : DnsListResult.filter((a) => a.Status === "Passed")
                              .length > 0
                          ? "bg-success-o-70"
                          : "bg-success-o-70"
                      }`}
                    >
                      <div className="row col-md-12">
                        <div
                          className="col-md-6 mt-3"
                          style={{ textAlign: "initial" }}
                        >
                          <h5>DNS</h5>
                        </div>
                        <div
                          className="col-md-6"
                          style={{ textAlign: "right" }}
                        ></div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className="row col-md-12">
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          bordered={false}
                          classes="table table-head-custom table-vertical-center"
                          bootstrap4
                          id="dns"
                          keyField="Name"
                          noDataIndication={
                            <h4 style={{ textAlign: "center" }}>
                              {"There is no data available at the moment"}.
                            </h4>
                          }
                          data={DnsListResult || []}
                          pagination={paginationFactory({
                            showTotal: true,
                            sizePerPage: 5,
                            sizePerPageList: [
                              { text: "5", value: 5 },
                              { text: "10", value: 10 },
                            ],
                          })}
                          columns={columns}
                        ></BootstrapTable>
                        <div className="row col-md-12 ">
                          <p className="mt-5 pt-5">
                            The DNS Check test will run a comprehensive DNS
                            Report for your domain. A DNS lookup is done
                            directly against the root servers (or TLD Servers).
                            Then we query each name server to make sure your DNS
                            Servers all respond, measure their performance and
                            audit the results against common best practices.
                            <Button
                              variant="link"
                              onClick={() => {
                                setTypeModalInfoShow("DNS");
                                setInfoJsonResult(InfoDnsListResult);
                                setShowInfoModal(true);
                              }}
                            >
                              See Informations
                            </Button>
                          </p>
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    expanded={expanded === "panel4"}
                    style={{ borderRadius: "4px", marginBottom: "15px" }}
                    onChange={handleChange("panel4")}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel4bh-header"
                      className={`text-white ${
                        mxListResult.filter((a) => a.Status === "Failed")
                          .length > 0
                          ? "bg-danger-o-70"
                          : mxListResult.filter((a) => a.Status === "Warnings")
                              .length > 0
                          ? "bg-warning-o-70"
                          : mxListResult.filter((a) => a.Status === "Passed")
                              .length > 0
                          ? "bg-success-o-70"
                          : "bg-success-o-70"
                      }`}
                    >
                      <div className="row col-md-12">
                        <div
                          className="col-md-6 mt-3"
                          style={{ textAlign: "initial" }}
                        >
                          <h5>MX Lists</h5>
                        </div>
                        <div
                          className="col-md-6"
                          style={{ textAlign: "right" }}
                        ></div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className="row col-md-12" style={{ width: "100%" }}>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          bordered={false}
                          classes="table table-head-custom table-vertical-center"
                          bootstrap4
                          id="mxList"
                          keyField="Name"
                          noDataIndication={
                            <h4 style={{ textAlign: "center" }}>
                              {"There is no data available at the moment"}.
                            </h4>
                          }
                          data={mxListResult || []}
                          pagination={paginationFactory({
                            showTotal: true,
                            sizePerPage: 5,
                            sizePerPageList: [
                              { text: "5", value: 5 },
                              { text: "10", value: 10 },
                            ],
                          })}
                          columns={columns}
                        ></BootstrapTable>
                        <div className="row col-md-12 ">
                          <p className="mt-5 pt-5">
                            This test will list MX records for a domain in
                            priority order. The MX lookup is done directly
                            against the domain's authoritative name server, so
                            changes to MX Records should show up instantly. You
                            can click Diagnostics , which will connect to the
                            mail server, verify reverse DNS records, perform a
                            simple Open Relay check and measure response time
                            performance. You may also check each MX record (IP
                            Address) against 105 DNS based blacklists .
                            (Commonly called RBLs, DNSBLs)
                            <Button
                              variant="link"
                              onClick={() => {
                                setTypeModalInfoShow("MX");
                                setInfoJsonResult(InfomxListResult);
                                setShowInfoModal(true);
                              }}
                            >
                              See Informations
                            </Button>
                          </p>
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    expanded={expanded === "panel6"}
                    style={{ borderRadius: "4px", marginBottom: "15px" }}
                    onChange={handleChange("panel6")}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel6bh-header"
                      className={`text-white ${
                        srvListResult.filter((a) => a.Status === "Failed")
                          .length > 0
                          ? "bg-danger-o-70"
                          : srvListResult.filter((a) => a.Status === "Warnings")
                              .length > 0
                          ? "bg-warning-o-70"
                          : srvListResult.filter((a) => a.Status === "Passed")
                              .length > 0
                          ? "bg-success-o-70"
                          : "bg-success-o-70"
                      }`}
                    >
                      <div className="row col-md-12">
                        <div
                          className="col-md-6 mt-3"
                          style={{ textAlign: "initial" }}
                        >
                          <h5>SRV Lists</h5>
                        </div>
                        <div
                          className="col-md-6"
                          style={{ textAlign: "right" }}
                        ></div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className="row col-md-12" style={{ width: "100%" }}>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          bordered={false}
                          classes="table table-head-custom table-vertical-center"
                          bootstrap4
                          id="srvList"
                          keyField="Name"
                          noDataIndication={
                            <h4 style={{ textAlign: "center" }}>
                              {"There is no data available at the moment"}.
                            </h4>
                          }
                          data={srvListResult || []}
                          pagination={paginationFactory({
                            showTotal: true,
                            sizePerPage: 5,
                            sizePerPageList: [
                              { text: "5", value: 5 },
                              { text: "10", value: 10 },
                            ],
                          })}
                          columns={columns}
                        ></BootstrapTable>
                        <div className="row col-md-12 ">
                          <p className="mt-5 pt-5">
                            This test will list SRV records for a domain. The
                            DNS lookup is done directly against the domain's
                            authoritative name server, so changes to DNS Records
                            should show up instantly. SRV Records are used to
                            provide information about available services
                            available for a domain. The naming system is an
                            underscore followed by the name of the service,
                            followed by a period, and underscore, and then the
                            protocol, another period, and then the name of the
                            domain. Example _http._tcp.spamdoctor.com
                            <Button
                              variant="link"
                              onClick={() => {
                                setTypeModalInfoShow("SRV");
                                setInfoJsonResult(InfosrvListResult);
                                setShowInfoModal(true);
                              }}
                            >
                              See Informations
                            </Button>
                          </p>
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SharedReport;
