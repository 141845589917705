import { useState } from "react";
import "./App.css";
import SignIn from "./components/account/signIn";
import Header from "./components/header";
import low from "lowdb";
import { BrowserRouter as Router, Route } from "react-router-dom";

import LocalStorage from "lowdb/adapters/LocalStorage";
import { Switch } from "react-router-dom";
import SignUp from "./components/account/signUp";
import UserActivationEmail from "./components/account/userActivationEmail";
import ResetPassword from "./components/account/resetPassword";
const adapter = new LocalStorage("db");
const db = low(adapter);
// import { shallowEqual, useSelector } from "react-redux";

function App() {
  const [token, setToken] = useState(
    db.read().getState()?.Token !== null &&
      db.read().getState()?.Token !== "" &&
      db.read().getState()?.Token !== undefined
      ? db.read().getState()?.Token
      : ""
  );
  // useEffect(() => {
  //   console.log(db.read().getState()?.Token);
  //   setToken(
  //     db.read().getState()?.Token !== null &&
  //       db.read().getState()?.Token !== "" &&
  //       db.read().getState()?.Token !== undefined
  //       ? db.read().getState()?.Token
  //       : ""
  //   );
  // }, [db.read().getState()?.Token]);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/sign-up">
            {" "}
            <SignUp />
          </Route>
          <Route path="/UserActivationEmail">
            {" "}
            <UserActivationEmail />
          </Route>
          <Route path="/ResetPassword">
            {" "}
            <ResetPassword />
          </Route>
          <Route path="/sign-in">
            {" "}
            <SignIn setToken={setToken} />
          </Route>
          {token !== "" ? <Header /> : <SignIn setToken={setToken} />}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
