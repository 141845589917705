import { Field, Formik } from "formik";
import React, { useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import { CountriesHelper, toAbsoluteUrl } from "../../helpers/AtHelper";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import "../emails/email.css";
import * as Yup from "yup";
const adapter = new LocalStorage("db");
const db = low(adapter);
const SignupSchema = Yup.object().shape({
  ProviderName: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  DomainUserName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
  CardExpiryMonth: Yup.string()
    .min(2, "Card Expiry Month is not valid!")
    .max(2, "Card Expiry Month is not valid!")
    .required("Card Expiry Month is required"),
  CardExpiryYear: Yup.string()
    .min(4, "Card Expiry Month is not valid!")
    .max(4, "Card Expiry Month is not valid!")
    .required("Card Expiry Year is required"),
  CardNumber: Yup.string()
    .min(16, "The credit card number is not valid!")
    .required("Card Number is required"),
  CardCvc: Yup.string().min(3, "CVV is not valid!").required("CVV is required"),
  DomainPassword: Yup.string(),
  ConfirmPassword: Yup.string().when("DomainPassword", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("DomainPassword")],
      "Both password need to be the same"
    ),
  }),
});
function AddDomain() {
  const history = useHistory();

  const [DomainName, setDomainName] = useState([""]);
  const [stepShild, setstepShild] = useState(0);
  const [customStep, setcustomStep] = useState(0);
  const [LoadingPostDomain, setLoadingPostDomain] = useState(false);

  function PostDomain(params) {
    if (
      params.CardExpiryMonth >= 13 &&
      params.ProviderName !== "GoDaddy" &&
      params.ProviderName !== "NameCheap" &&
      params.ProviderName !== "bluehost"
    ) {
      Swal.fire("Ooops?", "invalid Card Expiry Month", "error");
      return;
    }
    if (
      params.CardExpiryYear < new Date().getFullYear() &&
      params.ProviderName !== "GoDaddy" &&
      params.ProviderName !== "NameCheap" &&
      params.ProviderName !== "bluehost"
    ) {
      Swal.fire("Ooops?", "invalid Card Expiry Year", "error");
      return;
    }
    setLoadingPostDomain(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Name: params.Name,
      Names: DomainName,
      ProviderId: params.ProviderId,
      ProviderName: params.ProviderName,
      DomainUserName: params.DomainUserName,
      DomainPassword: params.DomainPassword,
      ConfirmPassword: params.ConfirmPassword,
      Address1: params.Address1,
      Address2: params.Address2,
      Zipcode: params.Zipcode,
      City: params.City,
      State: params.State,
      Country: params.Country,
      CardName: params.CardName,
      CardNumber: params.CardNumber,
      CardExpiryMonth: params.CardExpiryMonth,
      CardExpiryYear: params.CardExpiryYear,
      CardCvc: params.CardCvc,
      Coupon: params.Coupon,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Domains`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          Swal.fire("Great?", "Domain has been created successfuly", "success");
          setLoadingPostDomain(false);
          history.push("/my-domains");
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function (k) {
                modelState[k].forEach((element) => {
                  Swal.fire("Ooops?", element, "error");
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Swal.fire("Ooops?", result[1].Message, "error");
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            Swal.fire("Ooops?", result[1].error_description, "error");
          } else {
            Swal.fire("Ooops?", "Bad Request", "error");
          }
        }
        setLoadingPostDomain(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingPostDomain(false);
      });
  }
  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="d-flex flex-column-fluid pt-10">
        {/*begin::Container*/}
        <div className="container">
          <div className="card card-custom">
            <div className="card-body p-0">
              {/*begin::Wizard*/}
              <div
                className="wizard wizard-1"
                id="kt_wizard"
                data-wizard-state="first"
                data-wizard-clickable="false"
              >
                {/*begin::Wizard Nav*/}

                {/*end::Wizard Nav*/}
                {/*begin::Wizard Body*/}
                <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                  <div className="col-xl-12 col-xxl-12">
                    {/*begin::Wizard Form*/}
                    <Formik
                      validationSchema={SignupSchema}
                      initialValues={{
                        Name: "",
                        Names: [""],
                        ProviderId: "",
                        ProviderName: "",
                        DomainUserName: "",
                        DomainPassword: "",
                        ConfirmPassword: "",
                        Address1: "",
                        Address2: "",
                        Zipcode: "",
                        State: "",
                        City: "",
                        Country: "US",
                        CardName: "",
                        CardNumber: "",
                        CardExpiryMonth: "",
                        CardExpiryYear: "",
                        CardCvc: "",
                        Coupon: "",
                        Price: "",
                      }}
                      onSubmit={(values) => {
                        PostDomain(values);
                      }}
                    >
                      {({
                        values,
                        status,
                        errors,
                        touched,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form
                          className="form fv-plugins-bootstrap fv-plugins-framework"
                          id="kt_form"
                        >
                          {/*begin::Wizard Step 1*/}

                          {/*end::Wizard Step 1*/}
                          {/*begin::Wizard Step 2*/}
                          <div
                            className="pb-5"
                            data-wizard-type="step-content"
                            data-wizard-state={"current"}
                          >
                            <h4 className="mb-10 font-weight-bold text-dark">
                              <a
                                href={() => false}
                                onClick={() => {
                                  history.push("MyDomains");
                                }}
                                className="btn btn-icon btn-light btn-sm"
                              >
                                <span className="svg-icon svg-icon-success">
                                  <span className="svg-icon svg-icon-md">
                                    <i className="fas fa-angle-left font-size-h6 " />
                                  </span>
                                </span>
                              </a>
                              My Domains
                            </h4>
                            {/*begin::Input*/}
                            <div>
                              {errors.DomainUserName &&
                              touched.DomainUserName ? (
                                <div className="fv-plugins-message-container">
                                  <div
                                    data-field="width"
                                    data-validator="digits"
                                    class="fv-help-block"
                                  >
                                    {errors.DomainUserName}
                                  </div>
                                </div>
                              ) : null}
                              {errors.DomainPassword &&
                              touched.DomainPassword ? (
                                <div className="fv-plugins-message-container">
                                  <div
                                    data-field="width"
                                    data-validator="digits"
                                    class="fv-help-block"
                                  >
                                    {errors.DomainPassword}
                                  </div>
                                </div>
                              ) : null}
                              {errors.ConfirmPassword &&
                              touched.ConfirmPassword ? (
                                <div className="fv-plugins-message-container">
                                  <div
                                    data-field="width"
                                    data-validator="digits"
                                    class="fv-help-block"
                                  >
                                    {errors.ConfirmPassword}
                                  </div>
                                </div>
                              ) : null}
                              {errors.Zipcode && touched.Zipcode ? (
                                <div className="fv-plugins-message-container">
                                  <div
                                    data-field="width"
                                    data-validator="digits"
                                    class="fv-help-block"
                                  >
                                    {errors.Zipcode}
                                  </div>
                                </div>
                              ) : null}
                              {errors.CardNumber && touched.CardNumber ? (
                                <div className="fv-plugins-message-container">
                                  <div
                                    data-field="width"
                                    data-validator="digits"
                                    class="fv-help-block"
                                  >
                                    {errors.CardNumber}
                                  </div>
                                </div>
                              ) : null}
                              {errors.CardExpiryMonth &&
                              touched.CardExpiryMonth ? (
                                <div className="fv-plugins-message-container">
                                  <div
                                    data-field="width"
                                    data-validator="digits"
                                    class="fv-help-block"
                                  >
                                    {errors.CardExpiryMonth}
                                  </div>
                                </div>
                              ) : null}
                              {errors.CardExpiryYear &&
                              touched.CardExpiryYear ? (
                                <div className="fv-plugins-message-container">
                                  <div
                                    data-field="width"
                                    data-validator="digits"
                                    class="fv-help-block"
                                  >
                                    {errors.CardExpiryYear}
                                  </div>
                                </div>
                              ) : null}
                              {errors.CardCvc && touched.CardCvc ? (
                                <div className="fv-plugins-message-container">
                                  <div
                                    data-field="width"
                                    data-validator="digits"
                                    class="fv-help-block"
                                  >
                                    {errors.CardCvc}
                                  </div>
                                </div>
                              ) : null}
                              {customStep === 0 && (
                                <div className="Provider-select">
                                  <div className="row pb-7">
                                    <span className="label pulse mx-5">
                                      <span className="position-relative">
                                        1
                                      </span>
                                      <span className="pulse-ring" />
                                    </span>
                                    <h4>
                                      Select your domain service
                                      provider/registrar
                                    </h4>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div
                                        onClick={() => {
                                          setstepShild(0);
                                          setFieldValue("ProviderName", "");
                                        }}
                                        className={`bg-light border rounded  text-center ${
                                          stepShild === 0 ? "active" : ""
                                        }`}
                                        style={{
                                          paddingBottom: "29px",
                                          paddingTop: "29px",
                                        }}
                                        id="customer_provider"
                                      >
                                        <div>
                                          <img
                                            alt="customer"
                                            className
                                            src="/assets/media/svg/service/customer.svg"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div
                                        className={`bg-light border rounded px-10 py-5 text-center ${
                                          stepShild === 1 ? "active" : ""
                                        }`}
                                        id="GoDaddy"
                                        onClick={() => {
                                          setstepShild(1);
                                          setFieldValue(
                                            "ProviderName",
                                            "GoDaddy"
                                          );
                                        }}
                                      >
                                        <div>
                                          <img
                                            alt="GoDaddy"
                                            className="w-100"
                                            src="/assets/media/svg/service/gsuite.svg"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div
                                        className={`bg-light border rounded px-10 py-6 text-center  ${
                                          stepShild === 2 ? "active" : ""
                                        }`}
                                        id="NameCheap"
                                        onClick={() => {
                                          setstepShild(2);
                                          setFieldValue(
                                            "ProviderName",
                                            "NameCheap"
                                          );
                                        }}
                                      >
                                        <div>
                                          <img
                                            alt="outloock"
                                            className="w-100"
                                            src="/assets/media/svg/service/outloock.svg"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div
                                        className={`bg-light border rounded px-10 py-6 text-center  ${
                                          stepShild === 3 ? "active" : ""
                                        }`}
                                        id="bluehost"
                                        onClick={() => {
                                          setstepShild(3);
                                          setFieldValue(
                                            "ProviderName",
                                            "bluehost"
                                          );
                                        }}
                                      >
                                        <div>
                                          <img
                                            alt="aws"
                                            className="w-100"
                                            src="/assets/media/svg/service/aws.svg"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div
                                className="col-md-6 px-0 pt-10 pb-7 customer"
                                style={{
                                  display: "block",
                                }}
                              >
                                <div
                                  className="account-step"
                                  style={{
                                    display:
                                      customStep === 0 ? "block" : "none",
                                  }}
                                >
                                  <div className="row pb-7">
                                    <span className="label pulse mx-5">
                                      <span className="position-relative">
                                        2
                                      </span>
                                      <span className="pulse-ring" />
                                    </span>
                                    <h4>Account Credentials</h4>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-6 customer_records">
                                      {/*begin::Input*/}

                                      <div className="form-group fv-plugins-icon-container">
                                        <label>Domain name</label>
                                        <Field
                                          type="text"
                                          className="form-control  form-control-lg"
                                          placeholder="example.com"
                                          value={DomainName[0]}
                                          onChange={(e) => {
                                            let values = [...DomainName];
                                            values[0] =
                                              e.target?.value === undefined
                                                ? ""
                                                : e.target?.value;
                                            setDomainName(values);
                                          }}
                                        />
                                        <div className="fv-plugins-message-container"></div>
                                      </div>

                                      {/*end::Input*/}
                                    </div>
                                    {stepShild === 0 && (
                                      <div className="col-xl-6">
                                        {/*begin::Input*/}
                                        <div className="form-group fv-plugins-icon-container">
                                          <label>
                                            Domain provider/registrar
                                          </label>
                                          <Field
                                            type="text"
                                            className="form-control  form-control-lg"
                                            name="height"
                                            value={values.ProviderName}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "ProviderName",
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <div className="fv-plugins-message-container"></div>
                                        </div>
                                        {/*end::Input*/}
                                      </div>
                                    )}
                                  </div>
                                  <div class="col-md-12">
                                    {DomainName.map((a, i) => {
                                      if (i === 0) {
                                        return "";
                                      }
                                      return (
                                        <>
                                          <div className="row">
                                            <div className="customer_records_dynamic col-xl-11">
                                              <div className="remove row px-2">
                                                <div className="form-group fv-plugins-icon-container">
                                                  <label>Domain name</label>
                                                  <input
                                                    type="text"
                                                    className="form-control  form-control-lg"
                                                    name="width0"
                                                    placeholder="example.com"
                                                    value={a}
                                                    onChange={(e) => {
                                                      let values = [
                                                        ...DomainName,
                                                      ];
                                                      values[i] =
                                                        e.target?.value ===
                                                        undefined
                                                          ? ""
                                                          : e.target?.value;
                                                      setDomainName(values);
                                                    }}
                                                  />
                                                  <div className="fv-plugins-message-container"></div>

                                                  <div class="fv-plugins-message-container"></div>
                                                </div>
                                                <a
                                                  href={() => false}
                                                  class="remove-field btn-remove-customer align-self-center"
                                                  onClick={() => {
                                                    const list = [
                                                      ...DomainName,
                                                    ];
                                                    list.splice(i, 1);
                                                    setDomainName(list);
                                                  }}
                                                >
                                                  <i class="flaticon-close ml-2"></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="customer_records_dynamic col-xl-11"></div>
                                    </div>
                                  </div>
                                  <div className="col-xl-12 px-0">
                                    <h6
                                      className="font-weight-bolder text-primary px-2 py-2 extra-fields-customer"
                                      onClick={() => {
                                        setDomainName([...DomainName, ""]);
                                      }}
                                    >
                                      Add Domain
                                    </h6>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-12">
                                      {/*begin::Input*/}
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>
                                          Username{" "}
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="customers-delete-tooltip" className="mytooltip">
                                                SpamDoctor will need access to
                                                your domain registrar/provider
                                                to set-up missing records such
                                                as SPF/DKIM/DMARC records
                                              </Tooltip>
                                            }
                                          >
                                            <span
                                              className={`svg-icon svg-icon-md svg-icon-info`}
                                            >
                                              <SVG
                                                src={toAbsoluteUrl(
                                                  `/assets/media/svg/service/info-circle.svg`
                                                )}
                                              />
                                            </span>
                                          </OverlayTrigger>
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control  form-control-lg"
                                          name="DomainUserName"
                                          placeholder
                                          value={values.DomainUserName}
                                          onChange={(e) => {
                                            handleChange(e);
                                            setFieldValue(
                                              "DomainUserName",
                                              e.target.value
                                            );
                                          }}
                                        />
                                        <div className="fv-plugins-message-container"></div>
                                        {errors.DomainUserName &&
                                        touched.DomainUserName ? (
                                          <div className="fv-plugins-message-container">
                                            <div
                                              data-field="width"
                                              data-validator="digits"
                                              class="fv-help-block"
                                            >
                                              {errors.DomainUserName}
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                      {/*end::Input*/}
                                    </div>
                                    <div className="col-xl-6">
                                      {/*begin::Input*/}
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>Password</label>
                                        <Field
                                          type="password"
                                          className="form-control  form-control-lg"
                                          name="DomainPassword"
                                          autoComplete="new-password"
                                          placeholder
                                          value={values.DomainPassword}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "DomainPassword",
                                              e.target.value
                                            );
                                          }}
                                        />
                                        {errors.DomainPassword &&
                                        touched.DomainPassword ? (
                                          <div className="fv-plugins-message-container">
                                            <div
                                              data-field="width"
                                              data-validator="digits"
                                              class="fv-help-block"
                                            >
                                              {errors.DomainPassword}
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                      {/*end::Input*/}
                                    </div>
                                    <div className="col-xl-6">
                                      {/*begin::Input*/}
                                      <div className="form-group fv-plugins-icon-container">
                                        <label>Confirm Password</label>
                                        <Field
                                          type="password"
                                          className="form-control  form-control-lg"
                                          name="ConfirmPassword"
                                          placeholder
                                          value={values.ConfirmPassword}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "ConfirmPassword",
                                              e.target.value
                                            );
                                          }}
                                        />
                                        {errors.ConfirmPassword &&
                                        touched.ConfirmPassword ? (
                                          <div className="fv-plugins-message-container">
                                            <div
                                              data-field="width"
                                              data-validator="digits"
                                              class="fv-help-block"
                                            >
                                              {errors.ConfirmPassword}
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                      {/*end::Input*/}
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-end border-top mt-5 pt-10 ">
                                    <div>
                                      <button
                                        type="button"
                                        id="hideAccount"
                                        onClick={() => {
                                          setcustomStep(1);
                                        }}
                                        className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="billing-step"
                                  style={{
                                    display:
                                      customStep === 1 ? "block" : "none",
                                  }}
                                >
                                  <div className="row pb-7">
                                    <span className="label pulse mx-5">
                                      <span className="position-relative">
                                        3
                                      </span>
                                      <span className="pulse-ring" />
                                    </span>
                                    <h4>Billing Address</h4>
                                  </div>
                                  <div className="pb-5">
                                    <h3 className="mb-10 font-weight-bold text-dark">
                                      Setup Your Billing Address
                                    </h3>
                                    {/*begin::Input*/}
                                    <div className="form-group fv-plugins-icon-container">
                                      <label>Address Line 1</label>
                                      <input
                                        type="text"
                                        className="form-control form-control-solid form-control-lg"
                                        name="address1"
                                        placeholder="Address Line 1"
                                        value={values.Address1}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "Address1",
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <span className="form-text text-muted">
                                        Please enter your Address.
                                      </span>
                                      <div className="fv-plugins-message-container"></div>
                                    </div>
                                    {/*end::Input*/}
                                    {/*begin::Input*/}
                                    <div className="form-group">
                                      <label>Address Line 2</label>
                                      <input
                                        type="text"
                                        className="form-control form-control-solid form-control-lg"
                                        name="address2"
                                        placeholder="Address Line 2"
                                        value={values.Address2}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "Address2",
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <span className="form-text text-muted">
                                        Please enter your Address.
                                      </span>
                                    </div>
                                    {/*end::Input*/}
                                    <div className="row">
                                      <div className="col-xl-6">
                                        {/*begin::Input*/}
                                        <div className="form-group fv-plugins-icon-container">
                                          <label>Zip Code</label>
                                          <Field
                                            type="text"
                                            className="form-control form-control-solid form-control-lg"
                                            name="Zipcode"
                                            placeholder="Zipcode"
                                            value={values.Zipcode}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "Zipcode",
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <span className="form-text text-muted">
                                            Please enter your Zipcode.
                                          </span>
                                          {errors.Zipcode && touched.Zipcode ? (
                                            <div className="fv-plugins-message-container">
                                              <div
                                                data-field="width"
                                                data-validator="digits"
                                                class="fv-help-block"
                                              >
                                                {errors.Zipcode}
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                        {/*end::Input*/}
                                      </div>
                                      <div className="col-xl-6">
                                        {/*begin::Input*/}
                                        <div className="form-group fv-plugins-icon-container">
                                          <label>City</label>
                                          <input
                                            type="text"
                                            className="form-control form-control-solid form-control-lg"
                                            name="city"
                                            placeholder="City"
                                            value={values.City}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "City",
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <span className="form-text text-muted">
                                            Please enter your City.
                                          </span>
                                          <div className="fv-plugins-message-container"></div>
                                        </div>
                                        {/*end::Input*/}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        {/*begin::Input*/}
                                        <div className="form-group fv-plugins-icon-container">
                                          <label>State</label>
                                          <input
                                            type="text"
                                            className="form-control form-control-solid form-control-lg"
                                            name="state"
                                            placeholder="State"
                                            value={values.State}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "State",
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <span className="form-text text-muted">
                                            Please enter your State.
                                          </span>
                                          <div className="fv-plugins-message-container"></div>
                                        </div>
                                        {/*end::Input*/}
                                      </div>
                                      <div className="col-xl-6">
                                        {/*begin::Select*/}
                                        <div className="form-group fv-plugins-icon-container">
                                          <label>Country</label>
                                          <Select
                                            isSearchable={true}
                                            isClearable
                                            placeholder={"Select Country"}
                                            options={CountriesHelper}
                                            value={CountriesHelper.filter(
                                              (a) => a.value === values.Country
                                            )}
                                            onChange={(e) => {
                                              console.log("e", e);
                                              setFieldValue(
                                                "Country",
                                                e?.value
                                              );
                                            }}
                                          />

                                          <div className="fv-plugins-message-container"></div>
                                        </div>
                                        {/*end::Select*/}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                    <div className="mr-2">
                                      <button
                                        id="showBilling"
                                        type="button"
                                        className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                                        onClick={() => {
                                          setcustomStep(0);
                                        }}
                                      >
                                        Previous
                                      </button>
                                    </div>
                                    <div>
                                      <button
                                        type="button"
                                        id="showCard"
                                        onClick={() => {
                                          setcustomStep(2);

                                          var myHeaders = new Headers();
                                          myHeaders.append(
                                            "Authorization",
                                            `Bearer ${
                                              db.read().getState()?.Token
                                            }`
                                          );

                                          var requestOptions = {
                                            method: "GET",
                                            headers: myHeaders,
                                            redirect: "follow",
                                          };

                                          fetch(
                                            `${window.$apiurl}/api/Domains/ValidateCoupon?coupon=&count=${DomainName.length}`,
                                            requestOptions
                                          )
                                            .then(async (response) => {
                                              const statusCode =
                                                await response.status;
                                              const contentType =
                                                response.headers.get(
                                                  "content-type"
                                                );
                                              if (
                                                contentType &&
                                                contentType.indexOf(
                                                  "application/json"
                                                ) !== -1
                                              ) {
                                                return response
                                                  .json()
                                                  .then((data) => [
                                                    statusCode,
                                                    data,
                                                  ]);
                                              } else {
                                                return response
                                                  .text()
                                                  .then((text) => [
                                                    statusCode,
                                                    { Message: "" },
                                                  ]);
                                              }
                                            })
                                            .then(async (result) => {
                                              if (result[0] === 200) {
                                                var data = JSON.parse(
                                                  result[1]
                                                );
                                                setFieldValue("Price", data);
                                                //InTransit
                                              } else if (result[0] === 401) {
                                                history.push("/sign-in");
                                              }
                                            })
                                            .catch((error) => {
                                              console.log("error", error);
                                            });
                                        }}
                                        className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="card-step"
                                  style={{
                                    display:
                                      customStep === 2 ? "block" : "none",
                                  }}
                                >
                                  <div className="row pb-7">
                                    <span className="label pulse mx-5">
                                      <span className="position-relative">
                                        4
                                      </span>
                                      <span className="pulse-ring" />
                                    </span>
                                    <h4>Credit Card</h4>
                                  </div>
                                  <div className="pb-5">
                                    <h4 className="mb-10 font-weight-bold text-dark">
                                      Enter your Payment Details
                                    </h4>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        {/*begin::Input*/}
                                        <div className="form-group fv-plugins-icon-container">
                                          <label>Name on Card</label>
                                          <Field
                                            type="text"
                                            className="form-control form-control-solid form-control-lg"
                                            name="ccname"
                                            placeholder="Card Name"
                                            value={values.CardName}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "CardName",
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <span className="form-text text-muted">
                                            Please enter your Card Name.
                                          </span>
                                          <div className="fv-plugins-message-container"></div>
                                        </div>
                                        {/*end::Input*/}
                                      </div>
                                      <div className="col-xl-6">
                                        {/*begin::Input*/}
                                        <div className="form-group fv-plugins-icon-container">
                                          <label>Card Number</label>
                                          <Field
                                            type="number"
                                            className="form-control form-control-solid form-control-lg"
                                            name="CardNumber"
                                            placeholder="Card Number"
                                            value={values.CardNumber}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "CardNumber",
                                                e.target.value
                                              );
                                            }}
                                          />
                                          {errors.CardNumber &&
                                          touched.CardNumber ? (
                                            <div className="fv-plugins-message-container">
                                              <div
                                                data-field="width"
                                                data-validator="digits"
                                                class="fv-help-block"
                                              >
                                                {errors.CardNumber}
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                        {/*end::Input*/}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-4">
                                        {/*begin::Input*/}
                                        <div className="form-group fv-plugins-icon-container">
                                          <label>Card Expiry Month</label>
                                          <Field
                                            type="number"
                                            className="form-control form-control-solid form-control-lg"
                                            name="CardExpiryMonth"
                                            placeholder="Card Expiry Month"
                                            value={values.CardExpiryMonth}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "CardExpiryMonth",
                                                e.target.value
                                              );
                                            }}
                                          />
                                          {errors.CardExpiryMonth &&
                                          touched.CardExpiryMonth ? (
                                            <div className="fv-plugins-message-container">
                                              <div
                                                data-field="width"
                                                data-validator="digits"
                                                class="fv-help-block"
                                              >
                                                {errors.CardExpiryMonth}
                                              </div>
                                            </div>
                                          ) : null}
                                          <div className="fv-plugins-message-container"></div>
                                        </div>
                                        {/*end::Input*/}
                                      </div>
                                      <div className="col-xl-4">
                                        {/*begin::Input*/}
                                        <div className="form-group fv-plugins-icon-container">
                                          <label>Card Expiry Year</label>
                                          <Field
                                            type="number"
                                            className="form-control form-control-solid form-control-lg"
                                            name="CardExpiryYear"
                                            placeholder="Card Expire Year"
                                            value={values.CardExpiryYear}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "CardExpiryYear",
                                                e.target.value
                                              );
                                            }}
                                          />
                                          {errors.CardExpiryYear &&
                                          touched.CardExpiryYear ? (
                                            <div className="fv-plugins-message-container">
                                              <div
                                                data-field="width"
                                                data-validator="digits"
                                                class="fv-help-block"
                                              >
                                                {errors.CardExpiryYear}
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                        {/*end::Input*/}
                                      </div>
                                      <div className="col-xl-4">
                                        {/*begin::Input*/}
                                        <div className="form-group fv-plugins-icon-container">
                                          <label>Card CVV Number</label>
                                          <Field
                                            type="password"
                                            className="form-control form-control-solid form-control-lg"
                                            name="CardCvc"
                                            placeholder="Card CVV Number"
                                            value={values.CardCvc}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "CardCvc",
                                                e.target.value
                                              );
                                            }}
                                          />
                                          {errors.CardCvc && touched.CardCvc ? (
                                            <div className="fv-plugins-message-container">
                                              <div
                                                data-field="width"
                                                data-validator="digits"
                                                class="fv-help-block"
                                              >
                                                {errors.CardCvc}
                                              </div>
                                            </div>
                                          ) : null}
                                          <div className="fv-plugins-message-container"></div>
                                        </div>
                                        {/*end::Input*/}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-4">
                                        <div className="form-group fv-plugins-icon-container">
                                          <label>Price</label>
                                          <div className="input-group">
                                            <input
                                              type="text"
                                              disabled={true}
                                              value={values.Price}
                                              className="form-control form-control-solid form-control-lg"
                                            />
                                            <span className="input-group-text border-0 rounded-right">
                                              $/Month
                                            </span>
                                          </div>
                                          <div className="fv-plugins-message-container"></div>
                                        </div>
                                        {/*begin::Input*/}
                                        {/*end::Input*/}
                                      </div>
                                      <div className="col-xl-8">
                                        <div className="form-group fv-plugins-icon-container">
                                          <label>Coupon</label>
                                          <div className="input-group">
                                            <input
                                              type="text"
                                              className="form-control form-control-solid form-control-lg"
                                              value={values.Coupon}
                                              disabled={LoadingPostDomain}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "Coupon",
                                                  e.target.value
                                                );
                                              }}
                                            />
                                            <button
                                              className="btn btn-success rounded-right"
                                              type="button"
                                              onClick={() => {
                                                if (
                                                  values.Coupon === "" ||
                                                  values.Coupon === null ||
                                                  values.Coupon === undefined
                                                ) {
                                                  Swal.fire(
                                                    "Ooops?",
                                                    "invalid coupon",
                                                    "error"
                                                  );
                                                  return;
                                                }
                                                setLoadingPostDomain(true);
                                                var myHeaders = new Headers();
                                                myHeaders.append(
                                                  "Authorization",
                                                  `Bearer ${
                                                    db.read().getState()?.Token
                                                  }`
                                                );

                                                var requestOptions = {
                                                  method: "GET",
                                                  headers: myHeaders,
                                                  redirect: "follow",
                                                };

                                                fetch(
                                                  `${window.$apiurl}/api/Domains/ValidateCoupon?coupon=${values.Coupon}&count=${DomainName.length}`,
                                                  requestOptions
                                                )
                                                  .then(async (response) => {
                                                    const statusCode =
                                                      await response.status;
                                                    const contentType =
                                                      response.headers.get(
                                                        "content-type"
                                                      );
                                                    if (
                                                      contentType &&
                                                      contentType.indexOf(
                                                        "application/json"
                                                      ) !== -1
                                                    ) {
                                                      return response
                                                        .json()
                                                        .then((data) => [
                                                          statusCode,
                                                          data,
                                                        ]);
                                                    } else {
                                                      return response
                                                        .text()
                                                        .then((text) => [
                                                          statusCode,
                                                          { Message: "" },
                                                        ]);
                                                    }
                                                  })
                                                  .then(async (result) => {
                                                    if (result[0] === 200) {
                                                      var data = JSON.parse(
                                                        result[1]
                                                      );
                                                      setFieldValue(
                                                        "Price",
                                                        data
                                                      );
                                                      Swal.fire(
                                                        "Great?",
                                                        "the price have been updated",
                                                        "success"
                                                      );

                                                      //InTransit
                                                    } else if (
                                                      result[0] === 401
                                                    ) {
                                                      history.push("/sign-in");
                                                    }
                                                    setLoadingPostDomain(false);
                                                  })
                                                  .catch((error) => {
                                                    console.log("error", error);
                                                  });
                                              }}
                                            >
                                              Apply Coupon
                                              {LoadingPostDomain && (
                                                <Spinner
                                                  as="span"
                                                  animation="border"
                                                  size="sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                />
                                              )}
                                            </button>
                                          </div>
                                          <div className="fv-plugins-message-container"></div>
                                        </div>
                                        {/*begin::Input*/}
                                        {/*end::Input*/}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                    <div className="mr-2">
                                      <button
                                        id="hideCard"
                                        type="button"
                                        className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                                        onClick={() => {
                                          setcustomStep(1);
                                        }}
                                      >
                                        Previous
                                      </button>
                                    </div>
                                    <div>
                                      <button
                                        type="button"
                                        id="showsubmit"
                                        onClick={() => {
                                          setcustomStep(3);
                                        }}
                                        className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="submit-step"
                                  style={{
                                    display:
                                      customStep === 3 ? "block" : "none",
                                  }}
                                >
                                  <div className="row pb-7">
                                    <span className="label pulse mx-5">
                                      <span className="position-relative">
                                        5
                                      </span>
                                      <span className="pulse-ring" />
                                    </span>
                                    <h4>Review Your Details and Submit</h4>
                                  </div>
                                  <div className="pb-5">
                                    {/*begin::Section*/}
                                    <h6 className="font-weight-bolder mb-3">
                                      Domain Details:
                                    </h6>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="text-dark-50 line-height-lg">
                                          Domain:
                                        </div>
                                      </div>
                                      <div className="col-xl-6 text-right">
                                        <div className="text-dark-70 line-height-lg">
                                          {DomainName.join(", ")}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="text-dark-50 line-height-lg">
                                          Domain Registrar/Provider:
                                        </div>
                                      </div>
                                      <div className="col-xl-6 text-right">
                                        <div className="text-dark-70 line-height-lg">
                                          {values.ProviderName}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="separator separator-dashed my-5"></div>
                                    {/*end::Section*/}
                                    {/*begin::Section*/}
                                    <h6 className="font-weight-bolder mb-3">
                                      Billing Info:
                                    </h6>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="text-dark-50 line-height-lg">
                                          Address Line 1:
                                        </div>
                                      </div>
                                      <div className="col-xl-6 text-right">
                                        <div className="text-dark-70 line-height-lg">
                                          {values.Address1}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="text-dark-50 line-height-lg">
                                          Address Line 2:
                                        </div>
                                      </div>
                                      <div className="col-xl-6 text-right">
                                        <div className="text-dark-70 line-height-lg">
                                          {values.Address2}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="text-dark-50 line-height-lg">
                                          City:
                                        </div>
                                      </div>
                                      <div className="col-xl-6 text-right">
                                        <div className="text-dark-70 line-height-lg">
                                          {values.City}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="text-dark-50 line-height-lg">
                                          State:
                                        </div>
                                      </div>
                                      <div className="col-xl-6 text-right">
                                        <div className="text-dark-70 line-height-lg">
                                          {values.State}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="text-dark-50 line-height-lg">
                                          Postale Code:
                                        </div>
                                      </div>
                                      <div className="col-xl-6 text-right">
                                        <div className="text-dark-70 line-height-lg">
                                          {values.Zipcode}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-6">
                                        <div className="text-dark-50 line-height-lg">
                                          Country:
                                        </div>
                                      </div>
                                      <div className="col-xl-6 text-right">
                                        <div className="text-dark-70 line-height-lg">
                                          {values.Country}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                    <div className="mr-2">
                                      <button
                                        id="hidesubmit"
                                        type="button"
                                        className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                                        onClick={() => {
                                          setcustomStep(2);
                                        }}
                                      >
                                        Previous
                                      </button>
                                    </div>
                                    <div>
                                      <button
                                        disabled={LoadingPostDomain}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleSubmit();
                                        }}
                                        className="btn btn-primary font-weight-bolder text-uppercase font-weight-bolder px-9 py-4"
                                      >
                                        Add Domain
                                        {LoadingPostDomain && (
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                  {/*end::Wizard Form*/}
                </div>
              </div>
              {/*end::Wizard Body*/}
            </div>
            {/*end::Wizard*/}
          </div>
          {/*end::Wizard*/}
        </div>
      </div>
      {/*end::Container*/}
    </div>
  );
}
export default AddDomain;
