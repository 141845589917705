import React, { useEffect, useState } from "react";
import { Route, Link } from "react-router-dom";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";

import AddDomain from "./domains/addDomain";
import AddEmails from "./emails/addEmail";
import Domains from "./domains/domains";
import Emails from "./emails/emials";
import Faq from "./faq";
import Footer from "./footer";
import ReportingDomain from "./domains/reportingDomain";
import SenderEmail from "./emails/senderEmail";
import SharedReport from "./domains/sharedReport";
import { useHistory } from "react-router-dom";
import Profile from "./account/profile";
const adapter = new LocalStorage("db");
const db = low(adapter);

function Header() {
  const history = useHistory();
  var url = window.location.pathname;
  var id = url.substring(url.lastIndexOf("/") + 1);
  function getProfileData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Account/Profile`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var data = JSON.parse(result);
        db.set("FirstName", data.FirstName).write();
        db.set("LastName", data.LastName).write();
        db.set("CompanyName", data.CompanyName).write();
        db.set("ProfilePicture", data.ProfilePicture).write();
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    if (window.location.pathname.toLocaleLowerCase().includes("domain")) {
      setActiveMenu("my-domains");
    } else if (window.location.pathname.toLocaleLowerCase().includes("email")) {
      setActiveMenu("my-emails");
    } else if (window.location.pathname.toLocaleLowerCase().includes("faq")) {
      setActiveMenu("faq");
    } else {
      setActiveMenu("Home");
    }
    getProfileData();
  }, []);
  const [ActiveMenu, setActiveMenu] = useState("Home");
  const [openQuickUser, setopenQuickUser] = useState(false);
  return (
    <div>
      <div id="kt_header_mobile" className="header-mobile header-mobile-fixed">
        {/*begin::Logo*/}
        <a href="/index.html">
          <img
            alt="Logo"
            src="/assets/media/logos/logo-letter-13.png"
            className="logo-default max-h-30px"
          />
        </a>
        {/*end::Logo*/}
        {/*begin::Toolbar*/}
        <div className="d-flex align-items-center">
          {/*begin::Aside Mobile Toggle*/}
          <button
            className="btn p-0 burger-icon burger-icon-left"
            id="kt_aside_mobile_toggle"
          >
            <span />
          </button>
          {/*end::Aside Mobile Toggle*/}
          {/*begin::Header Menu Mobile Toggle*/}
          <button
            className="btn p-0 burger-icon ml-4"
            id="kt_header_mobile_toggle"
          >
            <span />
          </button>
          {/*end::Header Menu Mobile Toggle*/}
          {/*begin::Topbar Mobile Toggle*/}
          <button
            className="btn btn-hover-text-primary p-0 ml-2"
            id="kt_header_mobile_topbar_toggle"
          >
            <span className="svg-icon svg-icon-xl">
              {/*begin::Svg Icon | path:/assets/media/svg/icons/General/User.svg*/}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <path
                    d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    opacity="0.3"
                  />
                  <path
                    d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                    fill="#000000"
                    fillRule="nonzero"
                  />
                </g>
              </svg>
              {/*end::Svg Icon*/}
            </span>
          </button>
          {/*end::Topbar Mobile Toggle*/}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Header Mobile*/}
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {/*begin::Aside*/}
          <div className="aside aside-left d-flex flex-column" id="kt_aside">
            {/*begin::Brand*/}
            <div className="aside-brand d-flex flex-column align-items-center flex-column-auto py-4 py-lg-6">
              {/*begin::Logo*/}
              <Link
                to="/"
                onClick={() => {
                  setActiveMenu("Home");
                }}
                className="nav-link btn btn-icon btn-icon-white btn-lg"
              >
                <img
                  alt="Logo"
                  src="/assets/media/logos/logo-letter-9.png"
                  className="max-h-30px"
                />
              </Link>

              {/*end::Logo*/}
            </div>
            {/*end::Brand*/}
            {/*begin::Nav Wrapper*/}
            <div
              className="aside-nav d-flex flex-column align-items-center flex-column-fluid pt-7 scroll ps"
              style={{ height: "550px", overflow: "hidden" }}
            >
              {/*begin::Nav*/}
              <ul className="nav flex-column">
                {/*begin::Item*/}
                <li
                  className="nav-item mb-5"
                  data-toggle="tooltip"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
                  data-original-title="Home"
                >
                  <Link
                    to="/"
                    onClick={() => {
                      setActiveMenu("Home");
                    }}
                    className={`nav-link btn btn-icon btn-icon-white btn-lg ${
                      ActiveMenu === "Home" ? "btn-clean active" : ""
                    }`}
                  >
                    <i className="flaticon2-protection icon-lg" />
                  </Link>
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li
                  className="nav-item mb-5"
                  data-toggle="tooltip"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
                  data-original-title="My domains"
                >
                  <Link
                    to="/my-domains"
                    onClick={() => {
                      setActiveMenu("MyDomains");
                    }}
                    className={`nav-link btn btn-icon btn-icon-white btn-lg ${
                      ActiveMenu === "MyDomains" ? "btn-clean active" : ""
                    }`}
                  >
                    <i className="flaticon2-world icon-lg" />
                  </Link>
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li
                  className="nav-item mb-5"
                  data-toggle="tooltip"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
                  data-original-title="My Emails"
                >
                  <Link
                    to="/my-emails"
                    onClick={() => {
                      setActiveMenu("my-emails");
                    }}
                    className={`nav-link btn btn-icon btn-icon-white btn-lg ${
                      ActiveMenu === "my-emails" ? "btn-clean active" : ""
                    }`}
                  >
                    <i className="flaticon-email-black-circular-button icon-lg" />
                  </Link>
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li
                  className="nav-item mb-5"
                  data-toggle="tooltip"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
                  data-original-title="FAQ"
                >
                  <Link
                    to="/faq"
                    onClick={() => {
                      setActiveMenu("faq");
                    }}
                    className={`nav-link btn btn-icon btn-icon-white btn-lg ${
                      ActiveMenu === "faq" ? "btn-clean active" : ""
                    }`}
                  >
                    <i className="flaticon-info icon-lg" />
                  </Link>
                </li>
                {/*end::Item*/}
                {/*end::Item*/}
              </ul>
              {/*end::Nav*/}
              <div
                className="ps__rail-x"
                style={{ left: "0px", bottom: "0px" }}
              >
                <div
                  className="ps__thumb-x"
                  tabIndex={0}
                  style={{ left: "0px", width: "0px" }}
                />
              </div>
              <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
                <div
                  className="ps__thumb-y"
                  tabIndex={0}
                  style={{ top: "0px", height: "0px" }}
                />
              </div>
            </div>
            {/*end::Nav Wrapper*/}
            {/*begin::Footer*/}
            <div className="aside-footer d-none flex-column align-items-center flex-column-auto py-8">
              {/*begin::Quick Panel*/}
              <a
                href={() => false}
                className="btn btn-icon btn-clean btn-lg mb-1"
                id="kt_quick_panel_toggle"
                data-toggle="tooltip"
                data-placement="right"
                data-container="body"
                data-boundary="window"
                data-original-title="Quick Panel"
              >
                <i className="flaticon2-gear icon-lg" />
              </a>
              {/*end::Quick Panel*/}
            </div>
            {/*end::Footer*/}
          </div>
          {/*end::Aside*/}
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            {/*begin::Header*/}
            <div id="kt_header" className="header bg-white header-fixed">
              {/*begin::Container*/}
              <div className="container-fluid d-flex align-items-stretch justify-content-between">
                {/*begin::Left*/}
                <div className="d-flex align-items-stretch mr-2">
                  {/*begin::Page Title*/}
                  {/*end::Page Title*/}
                  {/*begin::Header Menu Wrapper*/}
                  <div
                    className="header-menu-wrapper header-menu-wrapper-left"
                    id="kt_header_menu_wrapper"
                  >
                    {/*begin::Header Menu*/}
                    <div
                      id="kt_header_menu"
                      className="header-menu header-menu-mobile header-menu-layout-default align-items-center"
                    >
                      {/*begin::Header Nav*/}
                      <h3 class="d-none text-dark d-lg-flex align-items-center mr-13 mb-0">
                        {" "}
                      </h3>
                      <Link
                        to="/add-domain"
                        className="btn btn-success font-weight-bold mr-2"
                      >
                        <i className="flaticon2-plus-1 text-white mr-1" />
                        Add a domain
                      </Link>

                      <form className="form mb-0 d-none">
                        <div className="form-group mb-0">
                          <div>
                            <select
                              className="form-control selectpicker"
                              disabled
                            >
                              <option>@doctorspam.io</option>
                            </select>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/*end::Header Menu*/}
                  </div>
                  {/*end::Header Menu Wrapper*/}
                </div>
                {/*end::Left*/}
                {/*begin::Topbar*/}
                <div className="topbar">
                  {/*begin::Search*/}
                  {/*end::Search*/}
                  {/*begin::Notifications*/}
                  <div className="dropdown">
                    {/*begin::Toggle*/}
                    <div
                      className="topbar-item mr-3 d-none"
                      data-toggle="dropdown"
                      data-offset="10px,0px"
                    >
                      <div className="btn btn-icon btn-clean btn-dropdown btn-lg pulse pulse-primary">
                        <span className="svg-icon svg-icon-xl">
                          {/*begin::Svg Icon | path:/assets/media/svg/icons/Code/Compiling.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <path
                                d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                              <path
                                d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z"
                                fill="#000000"
                              />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                        <span className="pulse-ring" />
                      </div>
                    </div>
                    {/*end::Toggle*/}
                    {/*begin::Dropdown*/}
                    <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
                      <form>
                        {/*begin::Header*/}
                        <div
                          className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                          style={{
                            backgroundImage:
                              "url(/assets/media/misc/bg-1.jpg)",
                          }}
                        >
                          {/*begin::Title*/}
                          <h4 className="d-flex flex-center rounded-top">
                            <span className="text-white">
                              User Notifications
                            </span>
                            <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                              23 new
                            </span>
                          </h4>
                          {/*end::Title*/}
                          {/*begin::Tabs*/}
                          <ul
                            className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link active show"
                                data-toggle="tab"
                                href="#topbar_notifications_notifications"
                              >
                                Alerts
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#topbar_notifications_events"
                              >
                                Events
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#topbar_notifications_logs"
                              >
                                Logs
                              </a>
                            </li>
                          </ul>
                          {/*end::Tabs*/}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Content*/}
                        <div className="tab-content">
                          {/*begin::Tabpane*/}
                          <div
                            className="tab-pane active show p-8"
                            id="topbar_notifications_notifications"
                            role="tabpanel"
                          >
                            {/*begin::Scroll*/}
                            <div
                              className="scroll pr-7 mr-n7 ps"
                              data-scroll="true"
                              data-height={300}
                              data-mobile-height={200}
                              style={{ height: "300px", overflow: "hidden" }}
                            >
                              {/*begin::Item*/}
                              <div className="d-flex align-items-center mb-6">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-40 symbol-light-primary mr-5">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-lg svg-icon-primary">
                                      {/*begin::Svg Icon | path:/assets/media/svg/icons/Home/Library.svg*/}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth={1}
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x={0}
                                            y={0}
                                            width={24}
                                            height={24}
                                          ></rect>
                                          <path
                                            d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z"
                                            fill="#000000"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)"
                                            x="16.3255682"
                                            y="2.94551858"
                                            width={3}
                                            height={18}
                                            rx={1}
                                          />
                                        </g>
                                      </svg>
                                      {/*end::Svg Icon*/}
                                    </span>
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Text*/}
                                <div className="d-flex flex-column font-weight-bold">
                                  <a
                                    href={() => false}
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                  >
                                    Cool App
                                  </a>
                                  <span className="text-muted">
                                    Marketing campaign planning
                                  </span>
                                </div>
                                {/*end::Text*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex align-items-center mb-6">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-40 symbol-light-warning mr-5">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-lg svg-icon-warning">
                                      {/*begin::Svg Icon | path:/assets/media/svg/icons/Communication/Write.svg*/}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth={1}
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x={0}
                                            y={0}
                                            width={24}
                                            height={24}
                                          ></rect>
                                          <path
                                            d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                            fill="#000000"
                                            fillRule="nonzero"
                                            transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                                          ></path>
                                          <path
                                            d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                            fill="#000000"
                                            fillRule="nonzero"
                                            opacity="0.3"
                                          />
                                        </g>
                                      </svg>
                                      {/*end::Svg Icon*/}
                                    </span>
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Text*/}
                                <div className="d-flex flex-column font-weight-bold">
                                  <a
                                    href={() => false}
                                    className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                                  >
                                    Awesome SAAS
                                  </a>
                                  <span className="text-muted">
                                    Project status update meeting
                                  </span>
                                </div>
                                {/*end::Text*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex align-items-center mb-6">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-40 symbol-light-success mr-5">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-lg svg-icon-success">
                                      {/*begin::Svg Icon | path:/assets/media/svg/icons/Communication/Group-chat.svg*/}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth={1}
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x={0}
                                            y={0}
                                            width={24}
                                            height={24}
                                          ></rect>
                                          <path
                                            d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z"
                                            fill="#000000"
                                          />
                                          <path
                                            d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                          />
                                        </g>
                                      </svg>
                                      {/*end::Svg Icon*/}
                                    </span>
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Text*/}
                                <div className="d-flex flex-column font-weight-bold">
                                  <a
                                    href={() => false}
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                  >
                                    Claudy Sys
                                  </a>
                                  <span className="text-muted">
                                    Project Deployment &amp; Launch
                                  </span>
                                </div>
                                {/*end::Text*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex align-items-center mb-6">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-40 symbol-light-danger mr-5">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-lg svg-icon-danger">
                                      {/*begin::Svg Icon | path:/assets/media/svg/icons/General/Attachment2.svg*/}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth={1}
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x={0}
                                            y={0}
                                            width={24}
                                            height={24}
                                          ></rect>
                                          <path
                                            d="M11.7573593,15.2426407 L8.75735931,15.2426407 C8.20507456,15.2426407 7.75735931,15.6903559 7.75735931,16.2426407 C7.75735931,16.7949254 8.20507456,17.2426407 8.75735931,17.2426407 L11.7573593,17.2426407 L11.7573593,18.2426407 C11.7573593,19.3472102 10.8619288,20.2426407 9.75735931,20.2426407 L5.75735931,20.2426407 C4.65278981,20.2426407 3.75735931,19.3472102 3.75735931,18.2426407 L3.75735931,14.2426407 C3.75735931,13.1380712 4.65278981,12.2426407 5.75735931,12.2426407 L9.75735931,12.2426407 C10.8619288,12.2426407 11.7573593,13.1380712 11.7573593,14.2426407 L11.7573593,15.2426407 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                            transform="translate(7.757359, 16.242641) rotate(-45.000000) translate(-7.757359, -16.242641)"
                                          ></path>
                                          <path
                                            d="M12.2426407,8.75735931 L15.2426407,8.75735931 C15.7949254,8.75735931 16.2426407,8.30964406 16.2426407,7.75735931 C16.2426407,7.20507456 15.7949254,6.75735931 15.2426407,6.75735931 L12.2426407,6.75735931 L12.2426407,5.75735931 C12.2426407,4.65278981 13.1380712,3.75735931 14.2426407,3.75735931 L18.2426407,3.75735931 C19.3472102,3.75735931 20.2426407,4.65278981 20.2426407,5.75735931 L20.2426407,9.75735931 C20.2426407,10.8619288 19.3472102,11.7573593 18.2426407,11.7573593 L14.2426407,11.7573593 C13.1380712,11.7573593 12.2426407,10.8619288 12.2426407,9.75735931 L12.2426407,8.75735931 Z"
                                            fill="#000000"
                                            transform="translate(16.242641, 7.757359) rotate(-45.000000) translate(-16.242641, -7.757359)"
                                          ></path>
                                          <path
                                            d="M5.89339828,3.42893219 C6.44568303,3.42893219 6.89339828,3.87664744 6.89339828,4.42893219 L6.89339828,6.42893219 C6.89339828,6.98121694 6.44568303,7.42893219 5.89339828,7.42893219 C5.34111353,7.42893219 4.89339828,6.98121694 4.89339828,6.42893219 L4.89339828,4.42893219 C4.89339828,3.87664744 5.34111353,3.42893219 5.89339828,3.42893219 Z M11.4289322,5.13603897 C11.8194565,5.52656326 11.8194565,6.15972824 11.4289322,6.55025253 L10.0147186,7.96446609 C9.62419433,8.35499039 8.99102936,8.35499039 8.60050506,7.96446609 C8.20998077,7.5739418 8.20998077,6.94077682 8.60050506,6.55025253 L10.0147186,5.13603897 C10.4052429,4.74551468 11.0384079,4.74551468 11.4289322,5.13603897 Z M0.600505063,5.13603897 C0.991029355,4.74551468 1.62419433,4.74551468 2.01471863,5.13603897 L3.42893219,6.55025253 C3.81945648,6.94077682 3.81945648,7.5739418 3.42893219,7.96446609 C3.0384079,8.35499039 2.40524292,8.35499039 2.01471863,7.96446609 L0.600505063,6.55025253 C0.209980772,6.15972824 0.209980772,5.52656326 0.600505063,5.13603897 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                            transform="translate(6.014719, 5.843146) rotate(-45.000000) translate(-6.014719, -5.843146)"
                                          ></path>
                                          <path
                                            d="M17.9142136,15.4497475 C18.4664983,15.4497475 18.9142136,15.8974627 18.9142136,16.4497475 L18.9142136,18.4497475 C18.9142136,19.0020322 18.4664983,19.4497475 17.9142136,19.4497475 C17.3619288,19.4497475 16.9142136,19.0020322 16.9142136,18.4497475 L16.9142136,16.4497475 C16.9142136,15.8974627 17.3619288,15.4497475 17.9142136,15.4497475 Z M23.4497475,17.1568542 C23.8402718,17.5473785 23.8402718,18.1805435 23.4497475,18.5710678 L22.0355339,19.9852814 C21.6450096,20.3758057 21.0118446,20.3758057 20.6213203,19.9852814 C20.2307961,19.5947571 20.2307961,18.9615921 20.6213203,18.5710678 L22.0355339,17.1568542 C22.4260582,16.76633 23.0592232,16.76633 23.4497475,17.1568542 Z M12.6213203,17.1568542 C13.0118446,16.76633 13.6450096,16.76633 14.0355339,17.1568542 L15.4497475,18.5710678 C15.8402718,18.9615921 15.8402718,19.5947571 15.4497475,19.9852814 C15.0592232,20.3758057 14.4260582,20.3758057 14.0355339,19.9852814 L12.6213203,18.5710678 C12.2307961,18.1805435 12.2307961,17.5473785 12.6213203,17.1568542 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                            transform="translate(18.035534, 17.863961) scale(1, -1) rotate(45.000000) translate(-18.035534, -17.863961)"
                                          ></path>
                                        </g>
                                      </svg>
                                      {/*end::Svg Icon*/}
                                    </span>
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Text*/}
                                <div className="d-flex flex-column font-weight-bold">
                                  <a
                                    href={() => false}
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                  >
                                    Trilo Service
                                  </a>
                                  <span className="text-muted">
                                    Analytics &amp; Requirement Study
                                  </span>
                                </div>
                                {/*end::Text*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex align-items-center mb-6">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-40 symbol-light-info mr-5">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-lg svg-icon-info">
                                      {/*begin::Svg Icon | path:/assets/media/svg/icons/Communication/Shield-user.svg*/}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth={1}
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x={0}
                                            y={0}
                                            width={24}
                                            height={24}
                                          ></rect>
                                          <path
                                            d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                          />
                                          <path
                                            d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                          />
                                          <path
                                            d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                          />
                                        </g>
                                      </svg>
                                      {/*end::Svg Icon*/}
                                    </span>
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Text*/}
                                <div className="d-flex flex-column font-weight-bold">
                                  <a
                                    href={() => false}
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                  >
                                    Bravia SAAS
                                  </a>
                                  <span className="text-muted">
                                    Reporting Application
                                  </span>
                                </div>
                                {/*end::Text*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex align-items-center mb-6">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-40 symbol-light-danger mr-5">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-lg svg-icon-danger">
                                      {/*begin::Svg Icon | path:/assets/media/svg/icons/Communication/Mail-notification.svg*/}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth={1}
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x={0}
                                            y={0}
                                            width={24}
                                            height={24}
                                          ></rect>
                                          <path
                                            d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                                            fill="#000000"
                                          />
                                          <circle
                                            fill="#000000"
                                            opacity="0.3"
                                            cx="19.5"
                                            cy="17.5"
                                            r="2.5"
                                          />
                                        </g>
                                      </svg>
                                      {/*end::Svg Icon*/}
                                    </span>
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Text*/}
                                <div className="d-flex flex-column font-weight-bold">
                                  <a
                                    href={() => false}
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                  >
                                    Express Wind
                                  </a>
                                  <span className="text-muted">
                                    Software Analytics &amp; Design
                                  </span>
                                </div>
                                {/*end::Text*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex align-items-center mb-6">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-40 symbol-light-success mr-5">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-lg svg-icon-success">
                                      {/*begin::Svg Icon | path:/assets/media/svg/icons/Design/Bucket.svg*/}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth={1}
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x={0}
                                            y={0}
                                            width={24}
                                            height={24}
                                          ></rect>
                                          <path
                                            d="M5,5 L5,15 C5,15.5948613 5.25970314,16.1290656 5.6719139,16.4954176 C5.71978107,16.5379595 5.76682388,16.5788906 5.81365532,16.6178662 C5.82524933,16.6294602 15,7.45470952 15,7.45470952 C15,6.9962515 15,6.17801499 15,5 L5,5 Z M5,3 L15,3 C16.1045695,3 17,3.8954305 17,5 L17,15 C17,17.209139 15.209139,19 13,19 L7,19 C4.790861,19 3,17.209139 3,15 L3,5 C3,3.8954305 3.8954305,3 5,3 Z"
                                            fill="#000000"
                                            fillRule="nonzero"
                                            transform="translate(10.000000, 11.000000) rotate(-315.000000) translate(-10.000000, -11.000000)"
                                          ></path>
                                          <path
                                            d="M20,22 C21.6568542,22 23,20.6568542 23,19 C23,17.8954305 22,16.2287638 20,14 C18,16.2287638 17,17.8954305 17,19 C17,20.6568542 18.3431458,22 20,22 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                          />
                                        </g>
                                      </svg>
                                      {/*end::Svg Icon*/}
                                    </span>
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Text*/}
                                <div className="d-flex flex-column font-weight-bold">
                                  <a
                                    href={() => false}
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                  >
                                    Bruk Fitness
                                  </a>
                                  <span className="text-muted">
                                    Web Design &amp; Development
                                  </span>
                                </div>
                                {/*end::Text*/}
                              </div>
                              {/*end::Item*/}
                              <div
                                className="ps__rail-x"
                                style={{ left: "0px", bottom: "0px" }}
                              >
                                <div
                                  className="ps__thumb-x"
                                  tabIndex={0}
                                  style={{ left: "0px", width: "0px" }}
                                />
                              </div>
                              <div
                                className="ps__rail-y"
                                style={{ top: "0px", right: "0px" }}
                              >
                                <div
                                  className="ps__thumb-y"
                                  tabIndex={0}
                                  style={{ top: "0px", height: "0px" }}
                                />
                              </div>
                            </div>
                            {/*end::Scroll*/}
                            {/*begin::Action*/}
                            <div className="d-flex flex-center pt-7">
                              <a
                                href={() => false}
                                className="btn btn-light-primary font-weight-bold text-center"
                              >
                                See All
                              </a>
                            </div>
                            {/*end::Action*/}
                          </div>
                          {/*end::Tabpane*/}
                          {/*begin::Tabpane*/}
                          <div
                            className="tab-pane"
                            id="topbar_notifications_events"
                            role="tabpanel"
                          >
                            {/*begin::Nav*/}
                            <div
                              className="navi navi-hover scroll my-4 ps"
                              data-scroll="true"
                              data-height={300}
                              data-mobile-height={200}
                              style={{ height: "300px", overflow: "hidden" }}
                            >
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-line-chart text-success" />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      New report has been received
                                    </div>
                                    <div className="text-muted">23 hrs ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-paper-plane text-danger" />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      Finance report has been generated
                                    </div>
                                    <div className="text-muted">25 hrs ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-user flaticon2-line- text-success" />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      New order has been received
                                    </div>
                                    <div className="text-muted">2 hrs ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-pin text-primary" />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      New customer is registered
                                    </div>
                                    <div className="text-muted">3 hrs ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-sms text-danger" />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      Application has been approved
                                    </div>
                                    <div className="text-muted">3 hrs ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-pie-chart-3 text-warning" />
                                  </div>
                                  <div className="navinavinavi-text">
                                    <div className="font-weight-bold">
                                      New file has been uploaded
                                    </div>
                                    <div className="text-muted">5 hrs ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon-pie-chart-1 text-info" />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      New user feedback received
                                    </div>
                                    <div className="text-muted">8 hrs ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-settings text-success" />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      System reboot has been successfully
                                      completed
                                    </div>
                                    <div className="text-muted">12 hrs ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon-safe-shield-protection text-primary" />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      New order has been placed
                                    </div>
                                    <div className="text-muted">15 hrs ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-notification text-primary" />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      Company meeting canceled
                                    </div>
                                    <div className="text-muted">19 hrs ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-fax text-success" />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      New report has been received
                                    </div>
                                    <div className="text-muted">23 hrs ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon-download-1 text-danger" />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      Finance report has been generated
                                    </div>
                                    <div className="text-muted">25 hrs ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon-security text-warning" />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      New customer comment recieved
                                    </div>
                                    <div className="text-muted">2 days ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <a href={() => false} className="navi-item">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-analytics-1 text-success" />
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">
                                      New customer is registered
                                    </div>
                                    <div className="text-muted">3 days ago</div>
                                  </div>
                                </div>
                              </a>
                              {/*end::Item*/}
                              <div
                                className="ps__rail-x"
                                style={{ left: "0px", bottom: "0px" }}
                              >
                                <div
                                  className="ps__thumb-x"
                                  tabIndex={0}
                                  style={{ left: "0px", width: "0px" }}
                                />
                              </div>
                              <div
                                className="ps__rail-y"
                                style={{ top: "0px", right: "0px" }}
                              >
                                <div
                                  className="ps__thumb-y"
                                  tabIndex={0}
                                  style={{ top: "0px", height: "0px" }}
                                />
                              </div>
                            </div>
                            {/*end::Nav*/}
                          </div>
                          {/*end::Tabpane*/}
                          {/*begin::Tabpane*/}
                          <div
                            className="tab-pane"
                            id="topbar_notifications_logs"
                            role="tabpanel"
                          >
                            {/*begin::Nav*/}
                            <div className="d-flex flex-center text-center text-muted min-h-200px">
                              All caught up!
                              <br />
                              No new notifications.
                            </div>
                            {/*end::Nav*/}
                          </div>
                          {/*end::Tabpane*/}
                        </div>
                        {/*end::Content*/}
                      </form>
                    </div>
                    {/*end::Dropdown*/}
                  </div>
                  <div className="topbar-item">
                    <div
                      className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                      id="kt_quick_user_toggle"
                      onClick={() => {
                        setopenQuickUser(!openQuickUser);
                      }}
                    >
                      <div className="d-flex flex-column text-right pr-3">
                        <span className="text-dark-75 font-weight-bold font-size-base d-none d-md-inline">
                          {db.read().getState()?.userName}
                        </span>
                      </div>
                      <span className="symbol symbol-35 symbol-light-primary">
                        <span className="symbol-label font-size-h5 font-weight-bold">
                          {String(db.read().getState()?.FirstName)
                            .charAt(0)
                            .toLocaleUpperCase()+String(db.read().getState()?.LastName)
                            .charAt(0)
                            .toLocaleUpperCase()}
                        </span>
                      </span>
                    </div>
                  </div>
                  {/*end::User*/}
                </div>
                {/*end::Topbar*/}
              </div>
              {/*end::Container*/}
            </div>
            {/*end::Header*/}
            {/*begin::Content*/}

            <Route path="/my-domains">
              <Domains />
            </Route>
            <Route path="/my-emails">
              <Emails />
            </Route>
            <Route path="/add-email">
              <AddEmails />
            </Route>
            <Route path="/email-details/:id/">
              <SenderEmail id={id} />
            </Route>
            <Route path="/add-domain">
              <AddDomain />
            </Route>
            <Route path="/domain-reports/:id/">
              <ReportingDomain id={id} />
            </Route>
            <Route path="/domain-report/:id/">
              <SharedReport id={id} />
            </Route>
            <Route path="/faq">
              <Faq />
            </Route>
            <Route path="/my-profile">
              <Profile />
            </Route>
            <Route path="//">
              <Footer />
            </Route>
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      {/*end::Main*/}
      {/*begin::Search Panel*/}
      <div id="kt_quick_search" className="offcanvas offcanvas-right p-10">
        {/*begin::Header*/}
        <div
          className="offcanvas-header d-flex align-items-center justify-content-between mb-5"
          kt-hidden-height={24}
          style={{}}
        >
          <h3 className="font-weight-bold m-0">
            Search
            <small className="text-muted font-size-sm ml-2">
              files, reports, members
            </small>
          </h3>
          <a
            href={() => false}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_search_close"
          >
            <i className="ki ki-close icon-xs text-muted" />
          </a>
        </div>
        {/*end::Header*/}
        {/*begin::Content*/}
        <div className="offcanvas-content">
          {/*begin::Container*/}
          <div
            className="quick-search quick-search-offcanvas quick-search-has-result"
            id="kt_quick_search_offcanvas"
          >
            {/*begin::Form*/}
            <form
              method="get"
              className="quick-search-form border-bottom pt-5 pb-1"
              kt-hidden-height={60}
              style={{}}
            >
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <span className="svg-icon svg-icon-lg">
                      {/*begin::Svg Icon | path:/assets/media/svg/icons/General/Search.svg*/}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x={0} y={0} width={24} height={24} />
                          <path
                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                            fill="#000000"
                            fillRule="nonzero"
                            opacity="0.3"
                          />
                          <path
                            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                        </g>
                      </svg>
                      {/*end::Svg Icon*/}
                    </span>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="quick-search-close ki ki-close icon-sm text-muted" />
                  </span>
                </div>
              </div>
            </form>
            {/*end::Form*/}
            {/*begin::Wrapper*/}
            <div
              className="quick-search-wrapper pt-5 scroll ps ps--active-y"
              style={{ height: "546px", overflow: "hidden" }}
            >
              <div className="quick-search-result">
                {/*begin::Message*/}
                <div className="text-muted d-none">No record found</div>
                {/*end::Message*/}
                {/*begin::Section*/}
                <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
                  Documents
                </div>
                <div className="mb-10">
                  {/*begin::Item*/}
                  <div className="d-flex align-items-center flex-grow-1 mb-2">
                    <div className="symbol symbol-30 bg-transparent flex-shrink-0">
                      <img src="/assets/media/svg/files/doc.svg" alt="" />
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <a
                        href={() => false}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        AirPlus Requirements
                      </a>
                      <span className="font-size-sm font-weight-bold text-muted">
                        by Grog John
                      </span>
                    </div>
                  </div>
                  {/*end::Item*/}
                  {/*begin::Item*/}
                  <div className="d-flex align-items-center flex-grow-1 mb-2">
                    <div className="symbol symbol-30 bg-transparent flex-shrink-0">
                      <img src="/assets/media/svg/files/pdf.svg" alt="" />
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <a
                        href={() => false}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        TechNav Documentation
                      </a>
                      <span className="font-size-sm font-weight-bold text-muted">
                        by Mary Broun
                      </span>
                    </div>
                  </div>
                  {/*end::Item*/}
                  {/*begin::Item*/}
                  <div className="d-flex align-items-center flex-grow-1 mb-2">
                    <div className="symbol symbol-30 bg-transparent flex-shrink-0">
                      <img src="/assets/media/svg/files/xml.svg" alt="" />
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <a
                        href={() => false}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        All Framework Docs
                      </a>
                      <span className="font-size-sm font-weight-bold text-muted">
                        by Nick Stone
                      </span>
                    </div>
                  </div>
                  {/*end::Item*/}
                  {/*begin::Item*/}
                  <div className="d-flex align-items-center flex-grow-1 mb-2">
                    <div className="symbol symbol-30 bg-transparent flex-shrink-0">
                      <img src="/assets/media/svg/files/csv.svg" alt="" />
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <a
                        href={() => false}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        Finance &amp; Accounting Reports
                      </a>
                      <span className="font-size-sm font-weight-bold text-muted">
                        by Jhon Larson
                      </span>
                    </div>
                  </div>
                  {/*end::Item*/}
                </div>
                {/*end::Section*/}
                {/*begin::Section*/}
                <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
                  Members
                </div>
                <div className="mb-10">
                  <div className="d-flex align-items-center flex-grow-1 mb-2">
                    <div className="symbol symbol-30 flex-shrink-0">
                      <div
                        className="symbol-label"
                        style={{
                          backgroundImage:
                            'url("/assets/media/users/300_20.jpg")',
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <a
                        href={() => false}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        Milena Gibson
                      </a>
                      <span className="font-size-sm font-weight-bold text-muted">
                        UI Designer
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-grow-1 mb-2">
                    <div className="symbol symbol-30 flex-shrink-0">
                      <div
                        className="symbol-label"
                        style={{
                          backgroundImage:
                            'url("/assets/media/users/300_15.jpg")',
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <a
                        href={() => false}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        Stefan JohnStefan
                      </a>
                      <span className="font-size-sm font-weight-bold text-muted">
                        Marketing Manager
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-grow-1 mb-2">
                    <div className="symbol symbol-30 flex-shrink-0">
                      <div
                        className="symbol-label"
                        style={{
                          backgroundImage:
                            'url("/assets/media/users/300_12.jpg")',
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <a
                        href={() => false}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        Anna Strong
                      </a>
                      <span className="font-size-sm font-weight-bold text-muted">
                        Software Developer
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-grow-1 mb-2">
                    <div className="symbol symbol-30 flex-shrink-0">
                      <div
                        className="symbol-label"
                        style={{
                          backgroundImage:
                            'url("/assets/media/users/300_16.jpg")',
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <a
                        href={() => false}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        Nick Bold
                      </a>
                      <span className="font-size-sm font-weight-bold text-muted">
                        Project Coordinator
                      </span>
                    </div>
                  </div>
                </div>
                {/*end::Section*/}
                {/*begin::Section*/}
                <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
                  Files
                </div>
                <div className="mb-10">
                  <div className="d-flex align-items-center flex-grow-1 mb-2">
                    <div className="symbol symbol-30 flex-shrink-0">
                      <div className="symbol-label">
                        <i className="flaticon-psd text-primary" />
                      </div>
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <a
                        href={() => false}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        79 PSD files generated
                      </a>
                      <span className="font-size-sm font-weight-bold text-muted">
                        by Grog John
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-grow-1 mb-2">
                    <div className="symbol symbol-30 flex-shrink-0">
                      <div className="symbol-label">
                        <i className="flaticon2-supermarket text-warning" />
                      </div>
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <a
                        href={() => false}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        $2900 worth products sold
                      </a>
                      <span className="font-size-sm font-weight-bold text-muted">
                        Total 234 items
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-grow-1 mb-2">
                    <div className="symbol symbol-30 flex-shrink-0">
                      <div className="symbol-label">
                        <i className="flaticon-safe-shield-protection text-info" />
                      </div>
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <a
                        href={() => false}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        4 New items submitted
                      </a>
                      <span className="font-size-sm font-weight-bold text-muted">
                        Marketing Manager
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-grow-1 mb-2">
                    <div className="symbol symbol-30 flex-shrink-0">
                      <div className="symbol-label">
                        <i className="flaticon-safe-shield-protection text-warning" />
                      </div>
                    </div>
                    <div className="d-flex flex-column ml-3 mt-2 mb-2">
                      <a
                        href={() => false}
                        className="font-weight-bold text-dark text-hover-primary"
                      >
                        4 New items submitted
                      </a>
                      <span className="font-size-sm font-weight-bold text-muted">
                        Marketing Manager
                      </span>
                    </div>
                  </div>
                </div>
                {/*end::Section*/}
              </div>
              <div
                className="ps__rail-x"
                style={{ left: "0px", bottom: "0px" }}
              >
                <div
                  className="ps__thumb-x"
                  tabIndex={0}
                  style={{ left: "0px", width: "0px" }}
                />
              </div>
              <div
                className="ps__rail-y"
                style={{ top: "0px", height: "546px", right: "0px" }}
              >
                <div
                  className="ps__thumb-y"
                  tabIndex={0}
                  style={{ top: "0px", height: "300px" }}
                />
              </div>
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Search Panel*/}
      {/* begin::User Panel*/}
      <div
        id="kt_quick_user"
        className={`offcanvas offcanvas-right p-10 ${
          openQuickUser === true ? "offcanvas-on" : ""
        }`}
      >
        {/*begin::Header*/}
        <div
          className="offcanvas-header d-flex align-items-center justify-content-between pb-5"
          kt-hidden-height={40}
          style={{}}
        >
          <h3 className="font-weight-bold m-0">
            User Profile
          </h3>
          <a
            href={() => false}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
            onClick={() => {
              setopenQuickUser(!openQuickUser);
            }}
          >
            <i className="ki ki-close icon-xs text-muted" />
          </a>
        </div>
        {/*end::Header*/}
        {/*begin::Content*/}
        <div
          className="offcanvas-content pr-5 mr-n5 scroll ps ps--active-y"
          style={{ height: "625px", overflow: "hidden" }}
        >
          {/*begin::Header*/}
          <div className="d-flex align-items-center mt-5">
            <div className="symbol symbol-100 mr-5">
              <div
                className="symbol-label"
                style={{
                  backgroundImage: `url("${
                    db.read().getState()?.ProfilePicture
                  }")`,
                }}
              />
              <i className="symbol-badge bg-success" />
            </div>
            <div className="d-flex flex-column">
              <a
                href={() => false}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push("/my-profile");
                }}
                className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {db.read().getState()?.FirstName +
                  " " +
                  db.read().getState()?.LastName}
              </a>
              <div className="text-muted mt-1">
                {db.read().getState()?.CompanyName}
              </div>
              <div className="navi mt-2">
                <a
                  href={() => false}
                  onClick={() => {
                    history.push("/my-profile");
                  }}
                  className="navi-item"
                >
                  <span className="navi-link p-0 pb-2">
                    <span className="navi-icon mr-1">
                      <span className="svg-icon svg-icon-lg svg-icon-primary">
                        {/*begin::Svg Icon | path:/assets/media/svg/icons/Communication/Mail-notification.svg*/}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <path
                              d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                              fill="#000000"
                            />
                            <circle
                              fill="#000000"
                              opacity="0.3"
                              cx="19.5"
                              cy="17.5"
                              r="2.5"
                            ></circle>
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                    </span>
                    <a
                      href="mailto: abc@example.com"
                      className="navi-text text-muted text-hover-primary"
                    >
                      {db.read().getState()?.userName}
                    </a>
                  </span>
                </a>
                <a
                  href={() => false}
                  onClick={() => {
                    db.set("Token", "").write();
                    db.set("userName", "").write();
                    db.set("FirstName", "").write();
                    db.set("LastName", "").write();
                    db.set("CompanyName", "").write();
                    db.set("ProfilePicture", "").write();
                    history.push("/sign-in");
                  }}
                  className="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5"
                >
                  Sign Out
                </a>
              </div>
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Separator*/}
          <div className="separator separator-dashed mt-8 mb-5" />
          {/*end::Separator*/}
          {/*begin::Nav*/}
          <div
            className="navi navi-spacer-x-0 p-0"
            style={{ cursor: "pointer" }}
          >
            {/*begin::Item*/}
            <a
              href={() => false}
              onClick={() => {
                history.push("/my-profile");
              }}
              className="navi-item"
            >
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-success">
                      {/*begin::Svg Icon | path:/assets/media/svg/icons/General/Notification2.svg*/}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x={0} y={0} width={24} height={24} />
                          <path
                            d="M13.2070325,4 C13.0721672,4.47683179 13,4.97998812 13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200119,11 19.5231682,10.9278328 20,10.7929675 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2070325,4 Z"
                            fill="#000000"
                          />
                          <circle
                            fill="#000000"
                            opacity="0.3"
                            cx="18.5"
                            cy="5.5"
                            r="2.5"
                          />
                        </g>
                      </svg>
                      {/*end::Svg Icon*/}
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Profile</div>
                  <div className="text-muted">
                    Account settings and more
                    <span className="label label-light-danger label-inline font-weight-bold ml-1">
                      update
                    </span>
                  </div>
                </div>
              </div>
            </a>
            {/*end:Item*/}
            {/*begin::Item*/}
            {/*end:Item*/}
          </div>
          {/*end::Nav*/}
          {/*begin::Separator*/}
          <div className="separator separator-dashed my-7" />
          {/*end::Separator*/}
          {/*begin::Notifications*/}
          {/*end::Notifications*/}
          <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
            <div
              className="ps__thumb-x"
              tabIndex={0}
              style={{ left: "0px", width: "0px" }}
            />
          </div>
          <div
            className="ps__rail-y"
            style={{ top: "0px", height: "625px", right: "0px" }}
          >
            <div
              className="ps__thumb-y"
              tabIndex={0}
              style={{ top: "0px", height: "300px" }}
            />
          </div>
        </div>
        {/*end::Content*/}
      </div>

      {/* end::User Panel*/}
      {/*begin::Quick Panel*/}
      <div id="kt_quick_panel" className="offcanvas offcanvas-left pt-5 pb-10">
        {/*begin::Header*/}
        <div
          className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5"
          kt-hidden-height={45}
          style={{}}
        >
          <ul
            className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#kt_quick_panel_settings"
              >
                Settings
              </a>
            </li>
          </ul>
          <div className="offcanvas-close mt-n1 pr-5">
            <a
              href={() => false}
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_panel_close"
            >
              <i className="ki ki-close icon-xs text-muted" />
            </a>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Content*/}
        <div className="offcanvas-content px-10">
          <div className="tab-content">
            {/*begin::Tabpane*/}
            {/*end::Tabpane*/}
            {/*begin::Tabpane*/}
            <div
              className="tab-pane fade pt-3 pr-5 mr-n5 scroll ps ps--active-y"
              id="kt_quick_panel_settings"
              role="tabpanel"
              style={{
                height: "620px",
                overflow: "hidden",
                display: "block",
                opacity: 1,
              }}
            >
              <form className="form">
                {/*begin::Section*/}
                <div>
                  <h5 className="font-weight-bold mb-3">Customer Care</h5>
                  <div className="form-group mb-0 row align-items-center">
                    <label className="col-8 col-form-label">
                      Enable Notifications:
                    </label>
                    <div className="col-4 d-flex justify-content-end">
                      <span className="switch switch-success switch-sm">
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked="checked"
                            name="select"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </div>
                  <div className="form-group mb-0 row align-items-center">
                    <label className="col-8 col-form-label">
                      Enable Case Tracking:
                    </label>
                    <div className="col-4 d-flex justify-content-end">
                      <span className="switch switch-success switch-sm">
                        <label>
                          <input
                            type="checkbox"
                            name="quick_panel_notifications_2"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </div>
                  <div className="form-group mb-0 row align-items-center">
                    <label className="col-8 col-form-label">
                      Support Portal:
                    </label>
                    <div className="col-4 d-flex justify-content-end">
                      <span className="switch switch-success switch-sm">
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked="checked"
                            name="select"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
                {/*end::Section*/}
                <div className="separator separator-dashed my-6" />
                {/*begin::Section*/}
                <div className="pt-2">
                  <h5 className="font-weight-bold mb-3">Reports</h5>
                  <div className="form-group mb-0 row align-items-center">
                    <label className="col-8 col-form-label">
                      Generate Reports:
                    </label>
                    <div className="col-4 d-flex justify-content-end">
                      <span className="switch switch-sm switch-danger">
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked="checked"
                            name="select"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </div>
                  <div className="form-group mb-0 row align-items-center">
                    <label className="col-8 col-form-label">
                      Enable Report Export:
                    </label>
                    <div className="col-4 d-flex justify-content-end">
                      <span className="switch switch-sm switch-danger">
                        <label>
                          <input type="checkbox" name="select" />
                          <span />
                        </label>
                      </span>
                    </div>
                  </div>
                  <div className="form-group mb-0 row align-items-center">
                    <label className="col-8 col-form-label">
                      Allow Data Collection:
                    </label>
                    <div className="col-4 d-flex justify-content-end">
                      <span className="switch switch-sm switch-danger">
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked="checked"
                            name="select"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
                {/*end::Section*/}
                <div className="separator separator-dashed my-6" />
                {/*begin::Section*/}
                <div className="pt-2">
                  <h5 className="font-weight-bold mb-3">Memebers</h5>
                  <div className="form-group mb-0 row align-items-center">
                    <label className="col-8 col-form-label">
                      Enable Member singup:
                    </label>
                    <div className="col-4 d-flex justify-content-end">
                      <span className="switch switch-sm switch-primary">
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked="checked"
                            name="select"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </div>
                  <div className="form-group mb-0 row align-items-center">
                    <label className="col-8 col-form-label">
                      Allow User Feedbacks:
                    </label>
                    <div className="col-4 d-flex justify-content-end">
                      <span className="switch switch-sm switch-primary">
                        <label>
                          <input type="checkbox" name="select" />
                          <span />
                        </label>
                      </span>
                    </div>
                  </div>
                  <div className="form-group mb-0 row align-items-center">
                    <label className="col-8 col-form-label">
                      Enable Customer Portal:
                    </label>
                    <div className="col-4 d-flex justify-content-end">
                      <span className="switch switch-sm switch-primary">
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked="checked"
                            name="select"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
                {/*end::Section*/}
              </form>
              <div
                className="ps__rail-x"
                style={{ left: "0px", bottom: "0px" }}
              >
                <div
                  className="ps__thumb-x"
                  tabIndex={0}
                  style={{ left: "0px", width: "0px" }}
                />
              </div>
              <div
                className="ps__rail-y"
                style={{ top: "0px", height: "620px", right: "0px" }}
              >
                <div
                  className="ps__thumb-y"
                  tabIndex={0}
                  style={{ top: "0px", height: "300px" }}
                />
              </div>
            </div>
            {/*end::Tabpane*/}
          </div>
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Quick Panel*/}
      {/*begin::Chat Panel*/}
      <div
        className="modal modal-sticky modal-sticky-bottom-right"
        id="kt_chat_modal"
        role="dialog"
        data-backdrop="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/*begin::Card*/}
            <div className="card card-custom">
              {/*begin::Header*/}
              <div className="card-header align-items-center px-4 py-3">
                <div className="text-left flex-grow-1">
                  {/*begin::Dropdown Menu*/}
                  <div className="dropdown dropdown-inline">
                    <button
                      type="button"
                      className="btn btn-clean btn-sm btn-icon btn-icon-md"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="svg-icon svg-icon-lg">
                        {/*begin::Svg Icon | path:/assets/media/svg/icons/Communication/Add-user.svg*/}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              opacity="0.3"
                            />
                            <path
                              d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                              fill="#000000"
                              fillRule="nonzero"
                            />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                    </button>
                    <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-md">
                      {/*begin::Navigation*/}
                      <ul className="navi navi-hover py-5">
                        <li className="navi-item">
                          <a href={() => false} className="navi-link">
                            <span className="navi-icon">
                              <i className="flaticon2-drop" />
                            </span>
                            <span className="navi-text">New Group</span>
                          </a>
                        </li>
                        <li className="navi-item">
                          <a href={() => false} className="navi-link">
                            <span className="navi-icon">
                              <i className="flaticon2-list-3" />
                            </span>
                            <span className="navi-text">Contacts</span>
                          </a>
                        </li>
                        <li className="navi-item">
                          <a href={() => false} className="navi-link">
                            <span className="navi-icon">
                              <i className="flaticon2-rocket-1" />
                            </span>
                            <span className="navi-text">Groups</span>
                            <span className="navi-link-badge">
                              <span className="label label-light-primary label-inline font-weight-bold">
                                new
                              </span>
                            </span>
                          </a>
                        </li>
                        <li className="navi-item">
                          <a href={() => false} className="navi-link">
                            <span className="navi-icon">
                              <i className="flaticon2-bell-2" />
                            </span>
                            <span className="navi-text">Calls</span>
                          </a>
                        </li>
                        <li className="navi-item">
                          <a href={() => false} className="navi-link">
                            <span className="navi-icon">
                              <i className="flaticon2-gear" />
                            </span>
                            <span className="navi-text">Settings</span>
                          </a>
                        </li>
                        <li className="navi-separator my-3" />
                        <li className="navi-item">
                          <a href={() => false} className="navi-link">
                            <span className="navi-icon">
                              <i className="flaticon2-magnifier-tool" />
                            </span>
                            <span className="navi-text">Help</span>
                          </a>
                        </li>
                        <li className="navi-item">
                          <a href={() => false} className="navi-link">
                            <span className="navi-icon">
                              <i className="flaticon2-bell-2" />
                            </span>
                            <span className="navi-text">Privacy</span>
                            <span className="navi-link-badge">
                              <span className="label label-light-danger label-rounded font-weight-bold">
                                5
                              </span>
                            </span>
                          </a>
                        </li>
                      </ul>
                      {/*end::Navigation*/}
                    </div>
                  </div>
                  {/*end::Dropdown Menu*/}
                </div>
                <div className="text-center flex-grow-1">
                  <div className="text-dark-75 font-weight-bold font-size-h5">
                    Matt Pears
                  </div>
                  <div>
                    <span className="label label-dot label-success" />
                    <span className="font-weight-bold text-muted font-size-sm">
                      Active
                    </span>
                  </div>
                </div>
                <div className="text-right flex-grow-1">
                  <button
                    type="button"
                    className="btn btn-clean btn-sm btn-icon btn-icon-md"
                    data-dismiss="modal"
                  >
                    <i className="ki ki-close icon-1x" />
                  </button>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body">
                {/*begin::Scroll*/}
                <div
                  className="scroll scroll-pull ps"
                  data-height={375}
                  data-mobile-height={300}
                  style={{ height: "375px", overflow: "hidden" }}
                >
                  {/*begin::Messages*/}
                  <div className="messages">
                    {/*begin::Message In*/}
                    <div className="d-flex flex-column mb-5 align-items-start">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-circle symbol-40 mr-3">
                          <img
                            alt="Pic"
                            src="/assets/media/users/300_12.jpg"
                          />
                        </div>
                        <div>
                          <a
                            href={() => false}
                            className="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                          >
                            Matt Pears
                          </a>
                          <span className="text-muted font-size-sm">
                            2 Hours
                          </span>
                        </div>
                      </div>
                      <div className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">
                        How likely are you to recommend our company to your
                        friends and family?
                      </div>
                    </div>
                    {/*end::Message In*/}
                    {/*begin::Message Out*/}
                    <div className="d-flex flex-column mb-5 align-items-end">
                      <div className="d-flex align-items-center">
                        <div>
                          <span className="text-muted font-size-sm">
                            3 minutes
                          </span>
                          <a
                            href={() => false}
                            className="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                          >
                            You
                          </a>
                        </div>
                        <div className="symbol symbol-circle symbol-40 ml-3">
                          <img
                            alt="Pic"
                            src="/assets/media/users/300_21.jpg"
                          />
                        </div>
                      </div>
                      <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">
                        Hey there, we’re just writing to let you know that
                        you’ve been subscribed to a repository on GitHub.
                      </div>
                    </div>
                    {/*end::Message Out*/}
                    {/*begin::Message In*/}
                    <div className="d-flex flex-column mb-5 align-items-start">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-circle symbol-40 mr-3">
                          <img
                            alt="Pic"
                            src="/assets/media/users/300_21.jpg"
                          />
                        </div>
                        <div>
                          <a
                            href={() => false}
                            className="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                          >
                            Matt Pears
                          </a>
                          <span className="text-muted font-size-sm">
                            40 seconds
                          </span>
                        </div>
                      </div>
                      <div className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">
                        Ok, Understood!
                      </div>
                    </div>
                    {/*end::Message In*/}
                    {/*begin::Message Out*/}
                    <div className="d-flex flex-column mb-5 align-items-end">
                      <div className="d-flex align-items-center">
                        <div>
                          <span className="text-muted font-size-sm">
                            Just now
                          </span>
                          <a
                            href={() => false}
                            className="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                          >
                            You
                          </a>
                        </div>
                        <div className="symbol symbol-circle symbol-40 ml-3">
                          <img
                            alt="Pic"
                            src="/assets/media/users/300_21.jpg"
                          />
                        </div>
                      </div>
                      <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">
                        You’ll receive notifications for all issues, pull
                        requests!
                      </div>
                    </div>
                    {/*end::Message Out*/}
                    {/*begin::Message In*/}
                    <div className="d-flex flex-column mb-5 align-items-start">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-circle symbol-40 mr-3">
                          <img
                            alt="Pic"
                            src="/assets/media/users/300_12.jpg"
                          />
                        </div>
                        <div>
                          <a
                            href={() => false}
                            className="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                          >
                            Matt Pears
                          </a>
                          <span className="text-muted font-size-sm">
                            40 seconds
                          </span>
                        </div>
                      </div>
                      <div className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">
                        You can unwatch this repository immediately by clicking
                        here:
                        <a href={() => false}>https://github.com</a>
                      </div>
                    </div>
                    {/*end::Message In*/}
                    {/*begin::Message Out*/}
                    <div className="d-flex flex-column mb-5 align-items-end">
                      <div className="d-flex align-items-center">
                        <div>
                          <span className="text-muted font-size-sm">
                            Just now
                          </span>
                          <a
                            href={() => false}
                            className="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                          >
                            You
                          </a>
                        </div>
                        <div className="symbol symbol-circle symbol-40 ml-3">
                          <img
                            alt="Pic"
                            src="/assets/media/users/300_21.jpg"
                          />
                        </div>
                      </div>
                      <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">
                        Discover what students who viewed Learn Figma - UI/UX
                        Design. Essential Training also viewed
                      </div>
                    </div>
                    {/*end::Message Out*/}
                    {/*begin::Message In*/}
                    <div className="d-flex flex-column mb-5 align-items-start">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-circle symbol-40 mr-3">
                          <img
                            alt="Pic"
                            src="/assets/media/users/300_12.jpg"
                          />
                        </div>
                        <div>
                          <a
                            href={() => false}
                            className="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                          >
                            Matt Pears
                          </a>
                          <span className="text-muted font-size-sm">
                            40 seconds
                          </span>
                        </div>
                      </div>
                      <div className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">
                        Most purchased Business courses during this sale!
                      </div>
                    </div>
                    {/*end::Message In*/}
                    {/*begin::Message Out*/}
                    <div className="d-flex flex-column mb-5 align-items-end">
                      <div className="d-flex align-items-center">
                        <div>
                          <span className="text-muted font-size-sm">
                            Just now
                          </span>
                          <a
                            href={() => false}
                            className="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                          >
                            You
                          </a>
                        </div>
                        <div className="symbol symbol-circle symbol-40 ml-3">
                          <img
                            alt="Pic"
                            src="/assets/media/users/300_21.jpg"
                          />
                        </div>
                      </div>
                      <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">
                        Company BBQ to celebrate the last quater achievements
                        and goals. Food and drinks provided
                      </div>
                    </div>
                    {/*end::Message Out*/}
                  </div>
                  {/*end::Messages*/}
                  <div
                    className="ps__rail-x"
                    style={{ left: "0px", bottom: "0px" }}
                  >
                    <div
                      className="ps__thumb-x"
                      tabIndex={0}
                      style={{ left: "0px", width: "0px" }}
                    />
                  </div>
                  <div
                    className="ps__rail-y"
                    style={{ top: "0px", right: "-2px" }}
                  >
                    <div
                      className="ps__thumb-y"
                      tabIndex={0}
                      style={{ top: "0px", height: "0px" }}
                    />
                  </div>
                </div>
                {/*end::Scroll*/}
              </div>
              {/*end::Body*/}
              {/*begin::Footer*/}
              <div className="card-footer align-items-center">
                {/*begin::Compose*/}
                <textarea
                  className="form-control border-0 p-0"
                  rows={2}
                  placeholder="Type a message"
                  defaultValue={""}
                />
                <div className="d-flex align-items-center justify-content-between mt-5">
                  <div className="mr-3">
                    <a
                      href={() => false}
                      className="btn btn-clean btn-icon btn-md mr-1"
                    >
                      <i className="flaticon2-photograph icon-lg" />
                    </a>
                    <a
                      href={() => false}
                      className="btn btn-clean btn-icon btn-md"
                    >
                      <i className="flaticon2-photo-camera icon-lg" />
                    </a>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6"
                    >
                      Send
                    </button>
                  </div>
                </div>
                {/*begin::Compose*/}
              </div>
              {/*end::Footer*/}
            </div>
            {/*end::Card*/}
          </div>
        </div>
      </div>
      {/*end::Chat Panel*/}
      {/*begin::Scrolltop*/}
      <div id="kt_scrolltop" className="scrolltop">
        <span className="svg-icon">
          {/*begin::Svg Icon | path:/assets/media/svg/icons/Navigation/Up-2.svg*/}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" />
              <rect
                fill="#000000"
                opacity="0.3"
                x={11}
                y={10}
                width={2}
                height={10}
                rx={1}
              />
              <path
                d="M6.70710678,12.7071068 C6.31658249,13.0976311 5.68341751,13.0976311 5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L11.2928932,5.29289322 C11.6714722,4.91431428 12.2810586,4.90106866 12.6757246,5.26284586 L18.6757246,10.7628459 C19.0828436,11.1360383 19.1103465,11.7686056 18.7371541,12.1757246 C18.3639617,12.5828436 17.7313944,12.6103465 17.3242754,12.2371541 L12.0300757,7.38413782 L6.70710678,12.7071068 Z"
                fill="#000000"
                fillRule="nonzero"
              />
            </g>
          </svg>
          {/*end::Svg Icon*/}
        </span>
      </div>
      {/*end::Scrolltop*/}
      {/*begin::Sticky Toolbar*/}
      {/*end::Sticky Toolbar*/}
      {/*begin::Demo Panel*/}
    </div>
  );
}
export default Header;
