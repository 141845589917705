/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { getDate } from "../../helpers/AtHelper";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
const adapter = new LocalStorage("db");
const db = low(adapter);

function ReportingDomain({ id }) {
  const [DomainBlackListAPIJsonResult, setDomainBlackListAPIJsonResult] =
    useState([]);
  const [EmailInfo, setEmailInfo] = useState(null);
  const [loadingget, setloadingget] = useState(false);
  const [isLoadingAuth, setisLoadingAuth] = useState(false);
  function getdataFromApi() {
    setloadingget(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/domains/getreports/${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var data = JSON.parse(result);

        setDomainBlackListAPIJsonResult(data?.Reports || []);
        setEmailInfo(data);
        setloadingget(false);
      })
      .catch((error) => {
        console.log("error", error);
        setloadingget(false);
      });
  }
  useEffect(() => {
    getdataFromApi();
  }, []);

  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      {/*begin::Modal*/}
      <div
        className="modal fade"
        id="subheader7Modal"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Location</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i aria-hidden="true" className="ki ki-close" />
              </button>
            </div>
            <div className="modal-body">
              <div
                id="kt_subheader_leaflet"
                style={{ height: "450px", width: "100%" }}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                id="submit"
                type="button"
                className="btn btn-primary font-weight-bold"
                data-dismiss="modal"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column-fluid pt-10">
        {/*begin::Container*/}
        <div className="container">
          {/*begin::Dashboard*/}
          {/*begin::Row*/}
          {/*end::Row*/}
          <div className="row">
            <div className="col-xl-12 h-100">
              {/*begin::Advance Table Widget 10*/}
              <div className="card card-custom gutter-b card-stretch">
                <div className="card-body py-0">
                  <div className="row my-5">
                    <div className="col-md-8">
                      <h4 className="mb-10 font-weight-bold text-dark">
                        <Link
                          to="/add-domain"
                          className="btn btn-icon btn-light btn-sm"
                        >
                          <span className="svg-icon svg-icon-success">
                            <span className="svg-icon svg-icon-md">
                              {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                              <i className="fas fa-angle-left font-size-h6 " />
                              {/*end::Svg Icon*/}
                            </span>
                          </span>
                        </Link>
                        Report list
                      </h4>
                    </div>
                    <div className="col-md-4">
                      <button
                        style={{ float: "right" }}
                        type="button"
                        disabled={isLoadingAuth}
                        className={`btn btn-${
                          EmailInfo?.SubscriptionPaused === true
                            ? "success"
                            : "danger"
                        } font-weight-bold`}
                        onClick={() => {
                          setisLoadingAuth(true);
                          var raw = JSON.stringify({
                            Id: id,
                            Pause: !EmailInfo?.SubscriptionPaused,
                          });
                          var myHeaders = new Headers();
                          myHeaders.append(
                            "Authorization",
                            `Bearer ${db.read().getState()?.Token}`
                          );
                          myHeaders.append("Content-Type", "application/json");

                          var requestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: raw,
                            redirect: "follow",
                          };

                          fetch(
                            `${window.$apiurl}/api/Domains/ChangeSubscription`,
                            requestOptions
                          )
                            .then(async (response) => {
                              const statusCode = await response.status;
                              const contentType =
                                response.headers.get("content-type");
                              if (
                                contentType &&
                                contentType.indexOf("application/json") !== -1
                              ) {
                                return response
                                  .json()
                                  .then((data) => [statusCode, data]);
                              } else {
                                return response
                                  .text()
                                  .then((text) => [
                                    statusCode,
                                    { Message: "" },
                                  ]);
                              }
                            })
                            .then(async (result) => {
                              if (result[0] === 200) {
                                Swal.fire(
                                  "Great?",
                                  "Subscription has been updated successfuly",
                                  "success"
                                );
                                getdataFromApi();
                                setisLoadingAuth(false);
                              } else {
                                if (
                                  result[1].ModelState !== null &&
                                  result[1].ModelState !== undefined &&
                                  result[1].ModelState !== ""
                                ) {
                                  let modelState = result[1].ModelState;
                                  if (modelState)
                                    Object.keys(modelState).forEach(function (
                                      k
                                    ) {
                                      modelState[k].forEach((element) => {
                                        Swal.fire("Ooops?", element, "error");
                                      });
                                    });
                                } else if (
                                  result[1].Message !== null &&
                                  result[1].Message !== undefined &&
                                  result[1].Message !== ""
                                ) {
                                  Swal.fire(
                                    "Ooops?",
                                    result[1].Message,
                                    "error"
                                  );
                                } else if (
                                  result[1].error_description !== null &&
                                  result[1].error_description !== undefined &&
                                  result[1].error_description !== ""
                                ) {
                                  Swal.fire(
                                    "Ooops?",
                                    result[1].error_description,
                                    "error"
                                  );
                                } else {
                                  Swal.fire("Ooops?", "Bad Request", "error");
                                }
                              }
                              setisLoadingAuth(false);
                            })
                            .catch((error) => {
                              console.log("error", error);
                              setisLoadingAuth(false);
                            });
                        }}
                      >
                        {EmailInfo?.SubscriptionPaused === true
                          ? "Start"
                          : "Stop"}{" "}
                        Domain Check-up
                        {isLoadingAuth && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                  {/*begin::Table*/}
                  <div className="table-responsive">
                    <table
                      className="table table-head-custom table-vertical-center"
                      id="kt_advance_table_widget_4"
                    >
                      <thead>
                        <tr className="text-left">
                          <th>Domain</th>
                          <th className=" pl-0">Created</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loadingget === true ? (
                          <tr>
                            <span
                              className="text-dark-75 font-weight-bolder d-block font-size-lg"
                              style={{ textAlign: "center" }}
                            >
                              Loading ....
                            </span>
                          </tr>
                        ) : DomainBlackListAPIJsonResult.length > 0 ? (
                          DomainBlackListAPIJsonResult?.map((a, i) => (
                            <tr>
                              <td>
                                <Link
                                  to={`/domain-report/${a.Id}`}
                                  className="text-dark-75 font-weight-bolder d-block font-size-lg"
                                >
                                  Report {i + 1}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to={`/domain-report/${a.Id}`}
                                  className="text-dark-75 font-weight-bolder d-block font-size-lg"
                                >
                                  {getDate(a.Created)}
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <span
                              className="text-dark-75 font-weight-bolder d-block font-size-lg"
                              style={{ textAlign: "center" }}
                            >
                              We're building your domain reports. Please check
                              again later.
                            </span>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/*end::Table*/}
                </div>
                {/*end::Body*/}
              </div>
              {/*end::Advance Table Widget 10*/}
            </div>
          </div>
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Modal*/}
      {/*begin::Entry*/}
      {/*end::Entry*/}
    </div>
  );
}
export default ReportingDomain;
