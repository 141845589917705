/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { Pagination } from "../Metronic/Control";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { currencyFormat, toAbsoluteUrl } from "../../helpers/AtHelper";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { sortCaret } from "../Metronic/Control/paginations";
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
const adapter = new LocalStorage("db");
const db = low(adapter);

var totalItems = 0;

function Emails({ isFooter }) {
  const history = useHistory();
  const [DomainListValue, setDomainListValue] = useState([]);
  const [paginationOptions, setpaginationOptions] = useState({
    custom: true,
    totalSize: totalItems,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "25", value: 25 },
    ],
    sizePerPage: 5,
    page: 1,
  });
  const GetHandlerTableChange = (type, newState) => {
    if (type === "sort") {
      let values = { ...paginationOptions };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      GetMyDomainsList2(values);
    }
    if (type === "pagination") {
      let values = { ...paginationOptions };
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
      GetMyDomainsList2(values);
    }
  };

  const columns = [
    {
      dataField: "Id",
      text: "#",
      sort: true,
      formatter: (cell, row) => {
        return <span>{row.Id}</span>;
      },
    },
    {
      dataField: "EmailAddress",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <Link
            to={`/email-details/${row.Id}`}
            className="text-dark-75 font-weight-bolder d-block font-size-lg"
          >
            {row.EmailAddress}
          </Link>
        );
      },
      style: {
        minWidth: "250px",
      },
    },

    {
      dataField: "Score",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <div className="d-flex flex-column w-100 mr-2">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span className="text-muted mr-2 font-size-sm font-weight-bold">
                {row?.Status}
              </span>
            </div>
            <div className="progress progress-xs w-100">
              <div
                className="progress-bar bg-primary"
                role="progressbar"
                style={{ width: row.Score + "%" }}
                aria-valuenow={50}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
          </div>
        );
      },
      style: {
        minWidth: "300px",
      },
    },
    {
      dataField: "Service",
      text: "Service",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <div className=" mt-1">
            <span className="symbol-label">
              {row.Service === "G-Suite"
                ? "G-Suite"
                : row.Service === "GoogleOAuth"
                ? "GoogleOAuth"
                : row.Service === "Google Workspace"
                ? "Google Workspace"
                : row.Service === "Outlook"
                ? "Outlook"
                : row.Service === "MicrosoftOAuth"
                ? "MicrosoftOAuth"
                : "Other"}
            </span>
          </div>
        );
      },
      style: {
        maxWidth: "300px",
      },
    },
    {
      dataField: "Score",
      text: "Score",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <a
            href={() => false}
            className="btn btn-circle btn-icon btn-light-primary pulse pulse-primary mr-5"
          >
            {Math.floor(row.Score)}%
            <span className="pulse-ring" />
          </a>
        );
      },
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "Problems",
      text: "Problems",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {row.Problems}
          </span>
        );
      },
      style: {
        minWidth: "100px",
      },
    },
  ];
  function GetMyDomainsList(params) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/api/Emails?pageNumber=${params.page}&pageSize=${params.sizePerPage}&search=&sortField=${params.sortField}&sortOrder=${params.sortOrder}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          console.log("data", data);
          setDomainListValue(data?.Emails || []);
          //InTransit
          var _paginationOptions = { ...paginationOptions };
          _paginationOptions.totalSize = data?.Pagination?.TotalItemCount;
          _paginationOptions.sizePerPageList = [
            { text: "5", value: 5 },
            { text: "10", value: 10 },
            { text: "25", value: 25 },
          ];
          _paginationOptions.page = 1;
          setpaginationOptions(_paginationOptions);
        } else if (result[0] === 401) {
          history.push("/sign-in");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function GetMyDomainsList2(params) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/api/Emails?pageNumber=${params.page}&pageSize=${params.sizePerPage}&search=&sortField=${params.sortField}&sortOrder=${params.sortOrder}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        var data = JSON.parse(result);
        setDomainListValue(data?.Emails || []);
        var _paginationOptions = { ...paginationOptions };
        _paginationOptions.totalSize = data?.Pagination?.TotalItemCount;
        _paginationOptions.sizePerPage = params?.sizePerPage;
        _paginationOptions.sortOrder = params?.sortOrder;
        _paginationOptions.sortField = params?.sortField;
        _paginationOptions.sizePerPageList = [
          { text: "5", value: 5 },
          { text: "10", value: 10 },
          { text: "25", value: 25 },
        ];
        _paginationOptions.page = params.page;
        setpaginationOptions(_paginationOptions);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    GetMyDomainsList(paginationOptions);
  }, []);
  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      {/*begin::Modal*/}
      <div
        className="modal fade"
        id="subheader7Modal"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Location</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i aria-hidden="true" className="ki ki-close" />
              </button>
            </div>
            <div className="modal-body">
              <div
                id="kt_subheader_leaflet"
                style={{ height: "450px", width: "100%" }}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                id="submit"
                type="button"
                className="btn btn-primary font-weight-bold"
                data-dismiss="modal"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`${isFooter ? "" : "d-flex flex-column-fluid pt-10"}`}>
        {/*begin::Container*/}
        <div className={`${isFooter ? "" : "container"}`}>
          {/*begin::Dashboard*/}
          {/*begin::Row*/}
          <div className="row mb-5">
            <div className="col-md-12">
              <div className="font-weight-bolder">
                <h3>My Emails</h3>
              </div>
              <Link
                to="/add-email"
                className="btn btn-info font-weight-bolder font-size-sm"
              >
                Add an E-mail
              </Link>
            </div>
          </div>
          {/*end::Row*/}
          <div className="row">
            <div className="col-xl-12 h-100">
              {/*begin::Advance Table Widget 10*/}
              <div className="card card-custom gutter-b card-stretch">
                <div className="card-body py-0">
                  {/*begin::Table*/}
                  <div className="table-responsive">
                    <PaginationProvider
                      pagination={paginationFactory(paginationOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => {
                        return (
                          <Pagination paginationProps={paginationProps}>
                            <BootstrapTable
                              wrapperClasses="table-responsive"
                              bordered={false}
                              classes="table table-head-custom table-vertical-center"
                              bootstrap4
                              remote
                              id="Id"
                              keyField="Id"
                              data={DomainListValue}
                              columns={columns}
                              defaultSorted={[
                                { dataField: "Id", order: "desc" },
                              ]}
                              onTableChange={GetHandlerTableChange}
                              {...paginationTableProps}
                            ></BootstrapTable>
                          </Pagination>
                        );
                      }}
                    </PaginationProvider>
                  </div>
                  {/*end::Table*/}
                </div>
                {/*end::Body*/}
              </div>
              {/*end::Advance Table Widget 10*/}
            </div>
          </div>
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Modal*/}
      {/*begin::Entry*/}
      {/*end::Entry*/}
    </div>
  );
}

export default Emails;
