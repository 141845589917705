import React, { useState } from "react";
import "./signIn.css";
import { Formik } from "formik";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
const adapter = new LocalStorage("db");
const db = low(adapter);
function SignIn({ setToken }) {
  const history = useHistory();

  const [isLoadingAuth, setisLoadingAuth] = useState(false);
  const [isLoginPage, setisLoginPage] = useState(true);
  const [emailForgettingPassword, setemailForgettingPassword] = useState("");

  function SignIn(values) {
    setisLoadingAuth(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("username", values.email);
    urlencoded.append("password", values.password);
    urlencoded.append("grant_type", "password");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/token`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          db.set("Token", result[1].access_token).write();
          db.set("userName", result[1].userName).write();
          setToken(result[1].access_token);
          history.push("/");
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function (k) {
                modelState[k].forEach((element) => {
                  Swal.fire("error", element, "error");
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Swal.fire("error", result[1].Message, "error");
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            Swal.fire("error", result[1].error_description, "error");
          } else {
            Swal.fire("error", "Bad Request", "error");
          }
        }
        setisLoadingAuth(false);
      })
      .catch((error) => {
        console.log("error", error);
        setisLoadingAuth(false);
      });
  }

  return (
    <div className="d-flex flex-column flex-root">
      {/*begin::Login*/}
      <div
        className={`login login-4 ${
          isLoginPage === true ? "login-signin-on" : "login-forgot-on"
        } d-flex flex-row-fluid`}
        id="kt_login"
      >
        <div
          className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
          style={{ backgroundImage: 'url("/assets/media/bg/bg-3.jpg")' }}
        >
          <div className="login-form text-center p-7 position-relative overflow-hidden">
            {/*begin::Login Header*/}
            <div className="d-flex flex-center mb-8">
              <Link
                to="/"
                className="login-logo pb-lg-4 pb-10 font-size-h1 font-weight-bold "
                style={{
                  color: "#8950fc",
                  display: "grid",
                  alignSelf: "center",
                  justifyItems: "center",
                }}
              >
                <img alt="logo" src="/assets/media/logos/logo.svg" />
                SpamDoctor
              </Link>
            </div>
            {/*end::Login Header*/}
            {/*begin::Login Sign in form*/}
            <div className="login-signin">
              <div className="mb-20">
                <h3>Sign in to your SpamDoctor Account</h3>
                <div className="text-muted font-weight-bold">
                  Enter your details to login to your account:
                </div>
              </div>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                onSubmit={(values) => {
                  SignIn(values);
                }}
              >
                {({
                  values,
                  status,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                    id="kt_login_signin_form"
                  >
                    <div className="form-group mb-5 fv-plugins-icon-container has-success">
                      <input
                        className="form-control h-auto form-control-solid py-4 px-8 is-valid"
                        type="text"
                        placeholder="Email"
                        value={values.email}
                        name="username"
                        //autoComplete="off"
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                        }}
                      />
                      <div className="fv-plugins-message-container" />
                    </div>
                    <div className="form-group mb-5 fv-plugins-icon-container has-success">
                      <input
                        className="form-control h-auto form-control-solid py-4 px-8 is-valid"
                        type="password"
                        value={values.password}
                        placeholder="Password"
                        name="password"
                        onChange={(e) => {
                          setFieldValue("password", e.target.value);
                        }}
                      />
                      <div className="fv-plugins-message-container" />
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <div className="checkbox-inline">
                        <label className="checkbox m-0 text-muted">
                          <input type="checkbox" name="remember" />
                          <span />
                          Remember me
                        </label>
                      </div>
                      <Link
                        to={() => false}
                        id="kt_login_forgot"
                        onClick={() => {
                          setisLoginPage(false);
                        }}
                        className="text-muted text-hover-primary"
                      >
                        Forget your password?
                      </Link>
                    </div>
                    <button
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={isLoadingAuth}
                      id="kt_login_signin_submit"
                      className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                      Sign In
                      {isLoadingAuth && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                    <input type="hidden" />
                    <div />
                  </form>
                )}
              </Formik>

              <div className="mt-10">
                <span className="opacity-70 mr-4">
                  Don't have an account yet?
                </span>
                <Link
                  to="/sign-up"
                  className={`text-muted text-hover-primary font-weight-bold`}
                >
                  Sign Up!
                </Link>
              </div>
            </div>
            {/*end::Login Sign in form*/}
            {/*begin::Login forgot password form*/}
            <div className="login-forgot">
              <div className="mb-20">
                <h3>Forgotten your Password?</h3>
                <div className="text-muted font-weight-bold">
                  Enter your email to reset your password
                </div>
              </div>
              <div
                className="form fv-plugins-bootstrap fv-plugins-framework"
                id="kt_login_forgot_form"
              >
                <div className="form-group mb-10 fv-plugins-icon-container">
                  <input
                    className="form-control form-control-solid h-auto py-4 px-8"
                    type="text"
                    placeholder="Email"
                    name="email"
                    onChange={(e) => {
                      setemailForgettingPassword(e.target.value);
                    }}
                    autoComplete="off"
                  />
                  <div className="fv-plugins-message-container" />
                </div>
                <div className="form-group d-flex flex-wrap flex-center mt-10">
                  <button
                    id="kt_login_forgot_submit"
                    onClick={() => {
                      var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/json");

                      var raw = JSON.stringify({
                        Email: emailForgettingPassword,
                      });

                      var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow",
                      };

                      fetch(
                        `${window.$apiurl}/api/Account/ForgetPassword`,
                        requestOptions
                      )
                        .then(async (response) => {
                          const statusCode = await response.status;
                          const contentType =
                            response.headers.get("content-type");
                          if (
                            contentType &&
                            contentType.indexOf("application/json") !== -1
                          ) {
                            return response
                              .json()
                              .then((data) => [statusCode, data]);
                          } else {
                            return response
                              .text()
                              .then((text) => [statusCode, { Message: "" }]);
                          }
                        })
                        .then((result) => {
                          if (result[0] === 200) {
                            history.push("/UserActivationEmail");
                          } else {
                            if (
                              result[1].ModelState !== null &&
                              result[1].ModelState !== undefined &&
                              result[1].ModelState !== ""
                            ) {
                              let modelState = result[1].ModelState;
                              if (modelState)
                                Object.keys(modelState).forEach(function (k) {
                                  modelState[k].forEach((element) => {
                                    Swal.fire("error", element, "error");
                                  });
                                });
                            } else if (
                              result[1].Message !== null &&
                              result[1].Message !== undefined &&
                              result[1].Message !== ""
                            ) {
                              Swal.fire("error", result[1].Message, "error");
                            } else if (
                              result[1].error_description !== null &&
                              result[1].error_description !== undefined &&
                              result[1].error_description !== ""
                            ) {
                              Swal.fire(
                                "error",
                                result[1].error_description,
                                "error"
                              );
                            } else {
                              Swal.fire("error", "Bad Request", "error");
                            }
                          }
                        })
                        .catch((error) => {
                          console.log("error", error);
                          setisLoadingAuth(false);
                        });
                    }}
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2"
                  >
                    Request
                  </button>
                  <button
                    id="kt_login_forgot_cancel"
                    onClick={() => {
                      setisLoginPage(true);
                    }}
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-2"
                  >
                    Cancel
                  </button>
                </div>
                <div />
              </div>
            </div>
            {/*end::Login forgot password form*/}
          </div>
        </div>
      </div>
      {/*end::Login*/}
    </div>
  );
}
export default SignIn;
