import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./signIn.css";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function SignUp() {
  const history = useHistory();
  const [isLoadingRegister, setisLoadingRegister] = useState(false);
  function Register(values) {
    setisLoadingRegister(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: values.emailAddress,
      password: values.password,
      FirstName: values.firstName,
      LastName: values.lastName,
      Confirmpassword: values.confirmPassword,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/account/register`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          history.push("/useractivationemail");
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function (k) {
                modelState[k].forEach((element) => {
                  Swal.fire("error", element, "error");
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Swal.fire("error", result[1].Message, "error");
          } else if (
            result[1].error_description !== null &&
            result[1].error_description !== undefined &&
            result[1].error_description !== ""
          ) {
            Swal.fire("error", result[1].error_description, "error");
          } else {
            Swal.fire("error", "Bad Request", "error");
          }
        }
        setisLoadingRegister(false);
      })
      .catch((error) => {
        console.log("error", error);
        setisLoadingRegister(false);
      });
  }

  return (
    <div className="col-12" style={{ height: "100%" }}>
      <div className="d-flex flex-column flex-root">
        <div className="row" style={{ height: "100%" }}>
          <div
            className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid wizard col-md-6 pr-0"
            id="kt_login"
            data-wizard-state="last"
          >
            {/*begin::Content*/}
            <div
              className="login-container d-flex flex-center flex-row flex-row-fluid order-2 order-lg-1 flex-row-fluid bg-white py-lg-0 pb-lg-0 pt-15 pb-12 bgi-size-cover bgi-position-top bgi-no-repeat"
              style={{ backgroundImage: 'url("/assets/media/bg/bg-3.jpg")' }}
            >
              {/*begin::Container*/}
              <div className="login-content login-content-signup d-flex flex-column col-md-8">
                {/*begin::Aside Top*/}
                <div className="d-flex flex-column-auto flex-column px-10 pb-10">
                  {/*begin::Aside header*/}
                  <Link
                    to="/"
                    className="login-logo pb-lg-4 pb-10 font-size-h1 font-weight-bold "
                    style={{
                      color: "#8950fc",
                      display: "grid",
                      alignSelf: "center",
                      justifyItems: "center",
                    }}
                  >
                    <img alt="logo" src="/assets/media/logos/logo.svg" />
                    SpamDoctor
                  </Link>
                  {/*end::Aside header*/}
                  {/*begin: Wizard Nav*/}
                  {/*end: Wizard Nav*/}
                </div>
                {/*end::Aside Top*/}
                {/*begin::Signin*/}
                <div className="login-form">
                  {/*begin::Form*/}
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      emailAddress: "",
                      password: "",
                      confirmPassword: "",
                    }}
                    onSubmit={(values) => {
                      console.log("values", values);
                      Register(values);
                    }}
                  >
                    {({
                      values,
                      status,
                      errors,
                      touched,
                      setFieldValue,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form
                        className="form px-10 fv-plugins-bootstrap fv-plugins-framework"
                        noValidate="novalidate"
                        id="kt_login_signup_form"
                      >
                        {/*begin: Wizard Step 1*/}
                        {/*begin::Title*/}
                        <div className="pb-10 pb-lg-12">
                          <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                            Create Account
                          </h3>
                          <div className="text-muted font-weight-bold font-size-h4">
                            Already have an Account?
                            <Link
                              to="/sign-in"
                              className="text-primary font-weight-bolder ml-2"
                            >
                              Sign In
                            </Link>
                          </div>
                        </div>
                        {/*begin::Title*/}
                        {/*begin::Form Group*/}

                        <div className="form-group fv-plugins-icon-container has-success">
                          <label className="font-size-h6 font-weight-bolder text-dark">
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                            name="fname"
                            placeholder="First Name"
                            value={values.firstName}
                            onChange={(e) => {
                              setFieldValue("firstName", e.target.value);
                            }}
                          />
                          <div className="fv-plugins-message-container" />
                        </div>
                        <div className="form-group fv-plugins-icon-container has-success">
                          <label className="font-size-h6 font-weight-bolder text-dark">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                            name="lname"
                            value={values.lastName}
                            onChange={(e) => {
                              setFieldValue("lastName", e.target.value);
                            }}
                            placeholder="Last Name"
                          />
                          <div className="fv-plugins-message-container" />
                        </div>
                        <div className="form-group fv-plugins-icon-container has-success">
                          <label className="font-size-h6 font-weight-bolder text-dark">
                            Email Address
                          </label>
                          <input
                            type="email"
                            className="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                            name="email"
                            placeholder="Email Address"
                            value={values.emailAddress}
                            onChange={(e) => {
                              setFieldValue("emailAddress", e.target.value);
                            }}
                            required
                          />
                          <div className="fv-plugins-message-container" />
                        </div>
                        {/*end::Form Group*/}
                        {/*begin::Form Group*/}
                        <div className="form-group fv-plugins-icon-container has-success">
                          <label className="font-size-h6 font-weight-bolder text-dark">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                            name="Pword"
                            placeholder="Password"
                            required
                            value={values.password}
                            onChange={(e) => {
                              setFieldValue("password", e.target.value);
                            }}
                          />
                          <div className="fv-plugins-message-container" />
                        </div>
                        <div className="form-group fv-plugins-icon-container has-success">
                          <label className="font-size-h6 font-weight-bolder text-dark">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                            name="Pword"
                            placeholder="Confirm Password"
                            required
                            value={values.confirmPassword}
                            onChange={(e) => {
                              setFieldValue("confirmPassword", e.target.value);
                            }}
                          />
                          <div className="fv-plugins-message-container" />
                        </div>
                        {/*end::Form Group*/}
                        {/*end: Wizard Step 1*/}
                        {/*begin: Wizard Step 2*/}
                        <div className="d-flex justify-content-between pt-7">
                          <button
                            href="/signin.html"
                            className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3 ml-auto"
                            onClick={() => {
                              handleSubmit();
                            }}
                            disabled={isLoadingRegister}
                            id="kt_login_signup_form_submit_button"
                          >
                            Submit
                            {isLoadingRegister === true ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              <span className="svg-icon svg-icon-md ml-2">
                                {/*begin::Svg Icon | path:./assets/media/svg/icons/Navigation/Right-2.svg*/}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <polygon points="0 0 24 0 24 24 0 24" />
                                    <rect
                                      fill="#000000"
                                      opacity="0.3"
                                      transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                      x="7.5"
                                      y="7.5"
                                      width={2}
                                      height={9}
                                      rx={1}
                                    />
                                    <path
                                      d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                      transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                                    ></path>
                                  </g>
                                </svg>
                                {/*end::Svg Icon*/}
                              </span>
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                  {/*end::Form*/}
                </div>
                {/*end::Signin*/}
              </div>
              {/*end::Container*/}
            </div>
            {/*begin::Content*/}
            {/*begin::Aside*/}
            {/*end::Aside*/}
          </div>
          <div className="bg-info col-md-6 testimonials pt-20">
            <div className="levitate col-md-10 pt-20">
              <div className="card feedback bg-light-info border border-white ml-10 mb-10">
                <div className="card-body">
                  <div className="row">
                    <div className="avatar col-md-3">
                      <img
                        alt="05"
                        src="/assets/media/users/150-2.jpg"
                        className="rounded-circle"
                        width={110}
                      />
                    </div>
                    <div className="cont col-md-8">
                      <div className="review ">
                        <div class="flex-grow-1">
                          <h5 className="text-dark fw-bolder text-hover-primary fs-6 mb-0">
                            Barry Moroney
                          </h5>
                          <span className="text-muted d-block fw-bold mb-3">
                            CEO @ leadable.io
                          </span>
                        </div>
                        <h6 className="fs-2 fw-bolder text-dark">
                          Excellent customer service!
                        </h6>
                        We are delighted with the service that we get from the
                        SpamDoctor team. They are very responsive and are
                        providing a valuable service for us which had been very
                        tricky to manage internally prior to working with them.
                        I would highly recommend
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 ml-20 mb-10">
                <div className="card feedback bg-light-info border border-white ml-auto">
                  <div className="card-body">
                    <div className="row">
                      <div className="avatar col-md-3">
                        <img
                          alt="05"
                          src="/assets/media/users/150-3.jpg"
                          className="rounded-circle"
                          width={110}
                        />
                      </div>
                      <div className="cont col-md-8">
                        <div className="review">
                          <div class="flex-grow-1">
                            <h5 className="text-dark fw-bolder text-hover-primary fs-6 mb-0">
                              Steven Lewis
                            </h5>
                            <span className="text-muted d-block fw-bold mb-3">
                              Strategist @ RevenueZen
                            </span>
                          </div>
                          <h6 className="fs-2 fw-bolder text-dark">
                            The best warm-up email service!
                          </h6>
                          This is easily the best and most efficient warm-up
                          company we've tried. Excellent dashboard to track
                          progress and very responsive customer service.
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="card feedback bg-light-info border border-white ml-10 mb-10">
                  <div className="card-body">
                    <div className="row">
                      <div className="avatar col-md-3">
                        <img
                          alt="05"
                          src="/assets/media/users/150-18.jpg"
                          className="rounded-circle"
                          width={110}
                        />
                      </div>
                      <div className="cont col-md-8">
                        <div className="review">
                          <div class="flex-grow-1">
                            <h5 className="text-dark fw-bolder text-hover-primary fs-6 mb-0">
                              Shawn Elledge
                            </h5>
                            <span className="text-muted d-block fw-bold mb-3">
                              CEO @ RelevanceFirst
                            </span>
                          </div>
                          <h6 className="fs-2 fw-bolder text-dark">
                            Successful email outreach campaigns!
                          </h6>
                          SpamDoctor has made a huge difference in our email
                          campaign ROI. Our open rate has shot up and we can
                          track our increased sales back to campaigns and their
                          higher open and click through rates.{" "}
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*begin::Login*/}
        {/*end::Login*/}
      </div>
    </div>
  );
}
export default SignUp;
