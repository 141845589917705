/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Emails from "./emails/emials";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { useHistory } from "react-router-dom";
import moment from "moment";
const adapter = new LocalStorage("db");
const db = low(adapter);
function Footer() {
  const history = useHistory();
  const [EmailInfo, setEmailInfo] = useState(null);

  function GetMyEmailInfo() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Dashboard/EmailDetails`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          setEmailInfo(data);
          console.log("datainfo", data);
        } else if (result[0] === 401) {
          history.push("/sign-in");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  useEffect(() => {
    GetMyEmailInfo();
  }, []);
  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      {/*begin::Modal*/}
      <div
        className="modal fade"
        id="subheader7Modal"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Location</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i aria-hidden="true" className="ki ki-close" />
              </button>
            </div>
            <div className="modal-body">
              <div
                id="kt_subheader_leaflet"
                style={{ height: "450px", width: "100%" }}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                id="submit"
                type="button"
                className="btn btn-primary font-weight-bold"
                data-dismiss="modal"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column-fluid pt-10">
        {/*begin::Container*/}
        <div className="container">
          {/*begin::Dashboard*/}
          {/*begin::Row*/}
          <div className="row">
            <div className="col-md-12">
              <div className="font-weight-bolder">
                <h3>Dashboard</h3>
              </div>
            </div>
            <div className="col-xl-8 h-100">
              <div className="row">
                <div className="col-xl-6 h-100">
                  {/*begin::Tiles Widget 8*/}
                  <div className="card card-custom gutter-b card-stretch">
                    <div
                      className="card-body d-flex flex-column p-0"
                      style={{ position: "relative" }}
                    >
                      {/*begin::Items*/}
                      <div className="flex-grow-1 card-spacer">
                        {/*begin::Item*/}
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center mr-2">
                            <div className="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                              <div className="symbol-label shadow bg-white">
                                <span className="svg-icon svg-icon-lg svg-icon-primary">
                                  {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Home/Library.svg*/}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x={0}
                                        y={0}
                                        width={24}
                                        height={24}
                                      />
                                      <path
                                        d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                      />
                                      <path
                                        d="M11.1750002,14.75 C10.9354169,14.75 10.6958335,14.6541667 10.5041669,14.4625 L8.58750019,12.5458333 C8.20416686,12.1625 8.20416686,11.5875 8.58750019,11.2041667 C8.97083352,10.8208333 9.59375019,10.8208333 9.92916686,11.2041667 L11.1750002,12.45 L14.3375002,9.2875 C14.7208335,8.90416667 15.2958335,8.90416667 15.6791669,9.2875 C16.0625002,9.67083333 16.0625002,10.2458333 15.6791669,10.6291667 L11.8458335,14.4625 C11.6541669,14.6541667 11.4145835,14.75 11.1750002,14.75 Z"
                                        fill="#000000"
                                      />
                                    </g>
                                  </svg>
                                  {/*end::Svg Icon*/}
                                </span>
                              </div>
                            </div>
                            <div>
                              <a
                                href={() => false}
                                className="font-size-sm text-muted text-hover-primary font-weight-bolder"
                              >
                                Emails
                              </a>
                              <div className="font-size-h3 text-dark-75 font-weight-bold mt-1">
                                {EmailInfo?.Total}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*end::Items*/}
                      {/*begin::Chart*/}
                      {/*end::Chart*/}
                      <div className="resize-triggers">
                        <div className="expand-trigger">
                          <div style={{ width: "411px", height: "431px" }} />
                        </div>
                        <div className="contract-trigger" />
                      </div>
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Tiles Widget 8*/}
                </div>
                <div className="col-xl-6 h-100">
                  {/*begin::Tiles Widget 8*/}
                  <div className="card card-custom gutter-b card-stretch">
                    <div
                      className="card-body d-flex flex-column p-0"
                      style={{ position: "relative" }}
                    >
                      {/*begin::Items*/}
                      <div className="flex-grow-1 card-spacer">
                        {/*begin::Item*/}
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center mr-2">
                            <div className="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                              <div className="symbol-label shadow bg-white">
                                <span className="svg-icon svg-icon-warning svg-icon-2x">
                                  {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-12-10-081610/theme/html/demo4/dist/../src/media/svg/icons/General/Shield-check.svg*/}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x={0}
                                        y={0}
                                        width={24}
                                        height={24}
                                      />
                                      <circle
                                        fill="#000000"
                                        opacity="0.3"
                                        cx={12}
                                        cy={12}
                                        r={10}
                                      />
                                      <rect
                                        fill="#000000"
                                        x={11}
                                        y={7}
                                        width={2}
                                        height={8}
                                        rx={1}
                                      />
                                      <rect
                                        fill="#000000"
                                        x={11}
                                        y={16}
                                        width={2}
                                        height={2}
                                        rx={1}
                                      />
                                    </g>
                                  </svg>
                                  {/*end::Svg Icon*/}
                                </span>
                              </div>
                            </div>
                            <div>
                              <a
                                href={() => false}
                                className="font-size-sm text-muted  text-hover-primary font-weight-bolder"
                              >
                                Warning
                              </a>
                              <div className="font-size-h3 text-dark-75 font-weight-bold mt-1">
                                {EmailInfo?.Spam}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*end::Items*/}
                      {/*begin::Chart*/}
                      {/*end::Chart*/}
                      <div className="resize-triggers">
                        <div className="expand-trigger">
                          <div style={{ width: "411px", height: "431px" }} />
                        </div>
                        <div className="contract-trigger" />
                      </div>
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Tiles Widget 8*/}
                </div>
                <div className="col-md-12">
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div
                      className="card-body py-1"
                      style={{ position: "relative" }}
                    >
                      <ReactApexChart
                        options={{
                          chart: {
                            type: "bar",
                            height: 350,
                          },
                          plotOptions: {
                            bar: {
                              horizontal: false,
                              columnWidth: "55%",
                              endingShape: "rounded",
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            show: true,
                            width: 2,
                            colors: ["transparent"],
                          },
                          xaxis: {
                            type: "datetime",
                            categories:
                              EmailInfo?.Graph30Days?.map((a) =>
                                moment(a.Date).format("YYYY-MM-DD")
                              ) || [],
                            tooltip: {
                              x: {
                                format: "MMM",
                              },
                            },
                          },
                          colors: ["#0bb7af", "#F44336"],
                          yaxis: {
                            title: {
                              text: "Sent",
                            },
                          },
                          fill: {
                            opacity: 1,
                          },
                          tooltip: {
                            y: {
                              formatter: function (val) {
                                return val;
                              },
                            },
                          },
                        }}
                        series={[
                          {
                            name: "Inbox",
                            data:
                              EmailInfo?.Graph30Days?.map((a) => a.Inbox) || [],
                          },
                          {
                            name: "Spam",
                            data:
                              EmailInfo?.Graph30Days?.map((a) => a.Spam) || [],
                          },
                        ]}
                        type="bar"
                        height={350}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              {/*begin::Mixed Widget 18*/}
              <div className="card card-custom gutter-b card-stretch">
                {/*begin::Header*/}
                <div className="card-header border-0 pt-5">
                  <h3 className="card-title font-weight-bolder">
                    Emails Placement Results
                  </h3>
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div
                  className="card-body pt-0"
                  style={{ position: "relative" }}
                >
                  {/*begin::Chart*/}
                  <div className="flex-grow-1" style={{ position: "relative" }}>
                    <ReactApexChart
                      options={{
                        chart: {
                          height: 350,
                          type: "radialBar",
                          toolbar: {
                            show: true,
                          },
                        },
                        plotOptions: {
                          radialBar: {
                            startAngle: -135,
                            endAngle: 225,
                            hollow: {
                              margin: 0,
                              size: "70%",
                              background: "#fff",
                              image: undefined,
                              imageOffsetX: 0,
                              imageOffsetY: 0,
                              position: "front",
                              dropShadow: {
                                enabled: true,
                                top: 3,
                                left: 0,
                                blur: 4,
                                opacity: 0.24,
                              },
                            },
                            track: {
                              background: "#fff",
                              strokeWidth: "67%",
                              margin: 0, // margin is in pixels
                              dropShadow: {
                                enabled: true,
                                top: -3,
                                left: 0,
                                blur: 4,
                                opacity: 0.35,
                              },
                            },

                            dataLabels: {
                              show: true,
                              name: {
                                offsetY: -10,
                                show: true,
                                color: "#888",
                                fontSize: "17px",
                              },
                              value: {
                                formatter: function (val) {
                                  return parseInt(val) + "%";
                                },
                                color: "#111",
                                fontSize: "36px",
                                show: true,
                              },
                            },
                          },
                        },
                        fill: {
                          type: "gradient",
                          gradient: {
                            shade: "dark",
                            type: "horizontal",
                            shadeIntensity: 0.5,
                            gradientToColors: ["#ABE5A1"],
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100],
                          },
                        },
                        stroke: {
                          lineCap: "round",
                        },
                        labels: ["Inbox"],
                      }}
                      series={[EmailInfo?.Percentage || 0]}
                      type="radialBar"
                      height={250}
                    />
                    <div className="resize-triggers">
                      <div className="expand-trigger">
                        <div style={{ width: "352px", height: "215px" }} />
                      </div>
                      <div className="contract-trigger" />
                    </div>
                  </div>
                  {/*end::Chart*/}
                  {/*begin::Items*/}
                  <div className="mt-0 position-relative zindex-0">
                    {/*begin::Widget Item*/}
                    <div className="d-flex align-items-center flex-wrap mb-8">
                      {/*begin::Symbol*/}
                      <div className="symbol symbol-40 symbol-light mr-3 flex-shrink-0">
                        <div className="symbol-label">
                          <span className="svg-icon svg-icon-lg svg-icon-success">
                            {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Shopping/Chart-pie.svg*/}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path
                                  d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                  transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "
                                />
                              </g>
                            </svg>
                            {/*end::Svg Icon*/}
                          </span>
                        </div>
                      </div>
                      {/*end::Symbol*/}
                      {/*begin::Title*/}
                      <div className="d-flex flex-column flex-grow-1 mr-2 my-2">
                        <a
                          href={() => false}
                          className="font-weight-bold text-muted text-hover-primary font-size-lg mb-1"
                        >
                          Inbox
                        </a>
                      </div>
                      {/*end::Text*/}
                      <span className=" label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">
                        {EmailInfo?.Inbox}
                      </span>
                      {/*end::Title*/}
                    </div>
                    {/*end::Widget Item*/}
                    {/*begin::Widget Item*/}
                    <div className="d-flex align-items-center flex-wrap mb-3">
                      {/*begin::Symbol*/}
                      <div className="symbol symbol-40 symbol-light mr-3 flex-shrink-0">
                        <div className="symbol-label">
                          <span className="svg-icon svg-icon-lg svg-icon-gray-500">
                            {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Design/Layers.svg*/}
                            <i className="flaticon2-exclamation" />
                            {/*end::Svg Icon*/}
                          </span>
                        </div>
                      </div>
                      {/*end::Symbol*/}
                      {/*begin::Title*/}
                      <div className="d-flex flex-column flex-grow-1 mr-2 my-2">
                        <a
                          href={() => false}
                          className="font-weight-bold text-muted text-hover-primary font-size-lg mb-1"
                        >
                          Spam
                        </a>
                      </div>
                      {/*end::Text*/}
                      <span className=" label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">
                        {EmailInfo?.Spam}
                      </span>
                    </div>
                    {/*end::Widget Item*/}
                  </div>
                  {/*end::Items*/}
                  <div className="resize-triggers">
                    <div className="expand-trigger">
                      <div style={{ width: "411px", height: "505px" }} />
                    </div>
                    <div className="contract-trigger" />
                  </div>
                </div>
                {/*end::Body*/}
              </div>
              {/*end::Mixed Widget 18*/}
            </div>
          </div>
          {/*end::Row*/}
          <div className="row">
            <div className="col-xl-12 h-100">
              {/*begin::Advance Table Widget 10*/}
              <Emails isFooter={true} />
            </div>
          </div>
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Modal*/}
      {/*begin::Entry*/}
      {/*end::Entry*/}
    </div>
  );
}

export default Footer;
